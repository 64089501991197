import React from 'react';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import profileBackground from '../../../assets/images/profielBackgoundImage.jpg';
import {
  AdminGetProfile,
  AdminUpadatePassword,
  AdminUpdateProfile
} from '../../../apis/Admin';
import { useEffect } from 'react';
import './MyProfile.css';
import { TextField, FormControl, Button } from '@mui/material';
import { useState } from 'react';
import { ErrorToast, successToast } from '../../../components/Toasts';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setUserDetailsObj } from '../../../Redux/UserSlice';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';
import { InputAdornment, IconButton, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { base_logo_url } from '../../../apis/constants';

const UserProfile = props => {
  const UserLoggedInEmail = useSelector(state => state.userDetails.email);
  const CurrentLoggedInPass = useSelector(state => state.userDetails.password);
  const AgencyLoginID = useSelector(state => state.userDetails.agencyLoginID);
  const UserDetails = useSelector(state => state.user.UserDetailsObj);

  const dispatch = useDispatch();
  const [userDetailsArr, setUserDetailsArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confrimPass, setConfirmPass] = useState('');
  const [passNotMatched, setPassNotMatched] = useState(false);
  const [userNotes, setUserNotes] = useState('');
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfrimPass, setShowConfrimPass] = useState(false);
  const [validNewPassErr, setValidNewPassErr] = useState(false);
  const [validConfirmPassErr, setValidConfirmPassErr] = useState(false);

  console.log(userDetailsArr?.roleName, 'userDetailsArr');
  const handleOpenConfirmProfile = () => {
    setOpenConfrimProfile(true);
  };
  const handleCloseConfirmProfile = () => {
    setIsLoading(false);
    setOpenConfrimProfile(false);
  };
  const GetSuccessUserDetails = apiRes => {
    setUserDetailsArr(apiRes.data);
    dispatch(setUserDetailsObj(apiRes.data));
  };
  const handleUpdatedUserDetials = apiRes => {
    handleCloseConfirmProfile();
    setIsLoading(false);
    successToast(apiRes.reasonPhrase);
  };

  const handleUpdatedPassword = apiRes => {
    handleClose();
    setIsLoading(false);
    successToast(apiRes.reasonPhrase);
  };

  useEffect(() => {
    AdminGetProfile(UserLoggedInEmail)
      .then(
        res => res.data.statusCode === 200 && GetSuccessUserDetails(res.data)
      )
      .catch(err => handleCloseConfirmProfile());
  }, []);

  const handleUpdateUserProfile = e => {
    if (currentPass !== CurrentLoggedInPass) {
      ErrorToast('Password did not matched with logged in password!');
    } else {
      setIsLoading(true);
      let UpdatedProfileObj = {
        id: AgencyLoginID,
        role: 1,
        firstName: userDetailsArr.firstName,
        lastName: userDetailsArr.lastName,
        loginId: UserLoggedInEmail,
        password: currentPass,
        email: userDetailsArr.email,
        notes: userNotes
      };
      AdminUpdateProfile(UpdatedProfileObj)
        .then(
          res =>
            res.data.statusCode === 200 && handleUpdatedUserDetials(res.data)
        )
        .catch(err => ErrorToast(`Profile not updated due to ${err.message}!`));
      handleCloseConfirmProfile();
    }
  };

  const handleChangePassword = e => {
    console.log(newPass, confrimPass);
    if (currentPass !== CurrentLoggedInPass) {
      ErrorToast('Current password did not matched with logged in password!');
    } else if (newPass !== confrimPass) {
      setPassNotMatched(true);
      toast.error('Confirm Password did not matches!');
    } else {
      setPassNotMatched(false);
      AdminUpadatePassword(newPass, currentPass, UserLoggedInEmail)
        .then(
          res => res.data.statusCode === 200 && handleUpdatedPassword(res.data)
        )
        .catch(err =>
          ErrorToast(`Password not updated due to ${err.message}!`)
        );
      handleClose();
    }
  };

  const [open, setOpen] = React.useState(false);
  const [openConfrimProfile, setOpenConfrimProfile] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setIsLoading(false);
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };

  const firstLetter = userDetailsArr?.firstName
    ? userDetailsArr?.firstName.charAt(0).toUpperCase()
    : '';

  const lastLetter = userDetailsArr?.lastName
    ? userDetailsArr?.lastName.charAt(0).toUpperCase()
    : '';

  const imageStyle = {
    objectFit: 'cover',
    marginTop: 80,
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
    cursor: 'pointer'
  };
  const handleAdminImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const imageType = /image.*/;
    if (!file.type.match(imageType)) {
      ErrorToast('Only image files are allowed');
    }
    if (file.type.match(imageType)) {
      reader.onloadend = () => {
        // setAdminSimpleImage(reader.result);
      };

      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append('file', file);
      var formdata = new FormData();
      formdata.append('file', file);

      // UploadUserProfileImage(UserLoggedInEmail, formdata)
      //   .then(res => {
      //     res.status === 200 && console.log(res.data.data, 'userProfileImg');
      //     // setUserProfileImg(res.data.data);
      //     setUserDetailsArr({
      //       ...userDetailsArr,
      //       avatar: res.data.data
      //     });
      //   })
      //   .catch(err => ErrorToast(err.message));
    }
  };
  const handleClickShowPassword = () => setShowCurrentPass(show => !show);
  const handleClickShowNewPassword = () => setShowNewPass(show => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfrimPass(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handlePassChange = (e, pass) => {
    const strongRegex = new RegExp(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#^()_+?&`~/-])[A-Za-z\d@$!%*#^()_+?&`~/-]{8,}$/
    );

    // console.log(value, 'valuevalue');

    if (pass === 'New Password') {
      setNewPass(e);
      if (strongRegex.test(e)) {
        setValidNewPassErr(false);
      } else setValidNewPassErr(true);
      // setNewPassword('');
    }
    if (pass === 'Confirm Password') {
      setConfirmPass(e);
      if (strongRegex.test(e)) {
        setValidConfirmPassErr(false);
      } else setValidConfirmPassErr(true);
    }
    // setConfrimPassword('');
  };
  return (
    <div style={{ padding: 5 }}>
      <ToastContainer
        position='top-center'
        autoClose={5800}
        style={{ width: 'auto' }}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Row>
        <Col
          lg={12}
          style={{
            margin: '10 0 10 0'
          }}
        >
          <Card className='box_shadow'>
            <div
              className='bg_img'
              style={{
                backgroundImage: `url(${profileBackground})`
              }}
            >
              <input
                // type='file'
                // accept='image/*'
                // onChange={handleAdminImageUpload}
                style={{ display: 'none' }}
                id='image-upload'
              />
              <label htmlFor='image-upload'>
                {userDetailsArr.avatar ? (
                  <img
                    src={base_logo_url + userDetailsArr.avatar}
                    alt='adminBase64Image'
                    width='200'
                    style={imageStyle}
                  />
                ) : (
                  <img
                    src={`data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">${encodeURIComponent(
                      firstLetter + lastLetter
                    )}</text></svg>`}
                    // src={imageUrl}
                    alt={userDetailsArr?.firstName}
                    style={imageStyle}
                  />
                )}
              </label>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card className='box_shadow'>
            <CardBody>
              <h5>Profile Settings</h5>
              <hr />
              <Row>
                <Col lg={6}>
                  <Label className='profile_label'>First Name</Label>
                  <br />

                  <TextField
                    fullWidth
                    value={userDetailsArr?.firstName}
                    onChange={e => {
                      const isValid = /^[A-Za-z\s\-]+$/.test(e.target.value);
                      (isValid || e.target.value === '') &&
                        setUserDetailsArr({
                          ...userDetailsArr,
                          firstName: e.target.value
                        });
                    }}
                    variant='outlined'
                    size='small'
                    type='Text'
                    autoComplete='first name'
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    inputProps={{
                      style: {
                        height: 15,
                        resize: {
                          fontSize: 1
                        }
                      }
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <Label className='profile_label'>Last Name</Label>
                  <br />
                  <TextField
                    fullWidth
                    value={userDetailsArr?.lastName}
                    onChange={e => {
                      const isValid = /^[A-Za-z\s\-]+$/.test(e.target.value);
                      (isValid || e.target.value === '') &&
                        setUserDetailsArr({
                          ...userDetailsArr,
                          lastName: e.target.value
                        });
                    }}
                    variant='outlined'
                    size='small'
                    // sx={{ width: 330, marginRight: 1, fontSize: 12 }}
                    // id='outlined-password-input'
                    // label='Last Name'
                    type='Text'
                    autoComplete='last name'
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    inputProps={{
                      style: {
                        height: 15,
                        resize: {
                          fontSize: 1
                        }
                      }
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Label className='profile_label'>Role </Label>
                  <br />
                  <TextField
                    disabled
                    value={userDetailsArr?.roleName}
                    variant='outlined'
                    size='small'
                    fullWidth
                    type='Text'
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    inputProps={{
                      style: {
                        height: 15,
                        resize: {
                          fontSize: 1
                        }
                      }
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <Label className='profile_label'>Email</Label>
                  <br />
                  <TextField
                    fullWidth
                    disabled
                    value={userDetailsArr?.email}
                    onChange={e =>
                      setUserDetailsArr({
                        ...userDetailsArr,
                        email: e.target.value
                      })
                    }
                    variant='outlined'
                    size='small'
                    // sx={{ width: 330, marginRight: 1, fontSize: 12 }}
                    // id='outlined-password-input'
                    // label='Email'
                    type='email'
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    inputProps={{
                      style: {
                        height: 15,
                        resize: {
                          fontSize: 1
                        }
                      }
                    }}
                  />
                </Col>
                <Col lg={12}>
                  <Label className='profile_label'>My Notes</Label>
                  <br />
                  {/* <FormControl sx={{ mt: 1, minWidth: 200 }} size='large'> */}
                  <TextField
                    value={userNotes}
                    onChange={e => setUserNotes(e.target.value)}
                    fullWidth
                    id='outlined-multiline-flexible'
                    // label='Multiline'
                    multiline
                    maxRows={8}
                  />
                  {/* </FormControl> */}
                </Col>
              </Row>

              <div
                style={{
                  display: 'flex',
                  paddingTop: 15,
                  justifyContent: 'end'
                }}
              >
                <Button
                  style={{
                    textTransform: 'capitalize',
                    margin: '0 10 0 10'
                  }}
                  color='error'
                  variant='contained'
                  onClick={e => handleOpen(e)}
                  // type='submit'
                >
                  Change Password
                </Button>

                <Button
                  disabled={
                    (UserDetails.firstName === userDetailsArr?.firstName &&
                      UserDetails.lastName === userDetailsArr?.lastName &&
                      UserDetails.avatar === userDetailsArr?.avatar &&
                      !userNotes) ||
                    userDetailsArr?.lastName === '' ||
                    userDetailsArr?.firstName === ''
                      ? // ||
                        // userDetailsArr?.avatar === ''
                        true
                      : false
                  }
                  style={{
                    textTransform: 'capitalize'
                  }}
                  color='primary'
                  variant='contained'
                  onClick={e => handleOpenConfirmProfile(e)}
                  // type='submit'
                >
                  Update Profile
                </Button>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box sx={style}>
                  <Typography variant='h6'>Update Password</Typography>
                  <Col lg={4}>
                    <Label className='profile_label'>Current Password</Label>
                    <FormControl sx={{ mt: 1, minWidth: 200 }} size='large'>
                      <OutlinedInput
                        value={currentPass}
                        onChange={e => setCurrentPass(e.target.value)}
                        sx={{
                          width: 330,
                          marginRight: 1,
                          fontSize: 12,
                          height: 35
                        }}
                        variant='outlined'
                        id='outlined-adornment-password'
                        type={showCurrentPass ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {showCurrentPass ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Col>
                  <Col lg={4}>
                    <Label className='profile_label'>New Password</Label>
                    <FormControl sx={{ mt: 1, minWidth: 200 }} size='large'>
                      <OutlinedInput
                        value={newPass}
                        onChange={e =>
                          handlePassChange(e.target.value, 'New Password')
                        }
                        sx={{
                          width: 330,
                          marginRight: 1,
                          fontSize: 12,
                          height: 35
                        }}
                        variant='outlined'
                        id='outlined-adornment-password'
                        type={showNewPass ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {showNewPass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {validNewPassErr && (
                        <span style={{ color: 'red', fontSize: 12 }}>
                          Password should be more than 7 characters, at least 1
                          alphabet, 1 number and 1 symbol
                        </span>
                      )}
                      {/* <TextField
                        variant='outlined'
                        size='small'
                        sx={{ width: 330, marginRight: 1, fontSize: 12 }}
                        type='password'
                        value={newPass}
                        onChange={e =>
                          handlePassChange(e.target.value, 'New Password')
                        }
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        inputProps={{
                          style: {
                            height: 15,
                            resize: {
                              fontSize: 1
                            }
                          }
                        }}
                      /> */}
                      {/* {validNewPassErr && (
                        <span style={{ color: 'red', fontSize: 12 }}>
                          Password should be more than 7 characters, at least 1
                          alphabet, 1 number and 1 symbol
                        </span>
                      )} */}
                    </FormControl>
                  </Col>
                  <Col lg={4}>
                    <Label className='profile_label'>Confirm Password</Label>
                    <FormControl sx={{ mt: 1, minWidth: 200 }} size='large'>
                      {/* <TextField
                        variant='outlined'
                        size='small'
                        sx={{ width: 330, marginRight: 1, fontSize: 12 }}
                        type='password'
                        value={confrimPass}
                        onChange={e =>
                          handlePassChange(e.target.value, 'Confirm Password')
                        }
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        inputProps={{
                          style: {
                            height: 15,
                            resize: {
                              fontSize: 1
                            }
                          }
                        }}
                      />
                      {validConfirmPassErr && (
                        <span style={{ color: 'red', fontSize: 12 }}>
                          Password should be more than 7 characters, at least 1
                          alphabet, 1 number and 1 symbol
                        </span>
                      )} */}
                      <OutlinedInput
                        value={confrimPass}
                        onChange={e =>
                          handlePassChange(e.target.value, 'Confirm Password')
                        }
                        sx={{
                          width: 330,
                          marginRight: 1,
                          fontSize: 12,
                          height: 35
                        }}
                        variant='outlined'
                        id='outlined-adornment-password'
                        type={showConfrimPass ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {showConfrimPass ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {validConfirmPassErr && (
                        <span style={{ color: 'red', fontSize: 12 }}>
                          Password should be more than 7 characters, at least 1
                          alphabet, 1 number and 1 symbol
                        </span>
                      )}
                    </FormControl>
                  </Col>

                  <Col lg={12}>
                    <Row>
                      <Col lg={6}>
                        <FormControl sx={{ mt: 2, minWidth: 150 }} size='large'>
                          <Button
                            variant='contained'
                            size='small'
                            // sx={{ width: 330, marginRight: 1, fontSize: 12 }}
                            color='error'
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </FormControl>
                      </Col>
                      <Col lg={6}>
                        <FormControl sx={{ mt: 2, minWidth: 150 }} size='large'>
                          <Button
                            disabled={
                              !currentPass || !newPass || !confrimPass
                                ? true
                                : false
                            }
                            variant='contained'
                            size='small'
                            // sx={{ width: 330, marginRight: 1, fontSize: 12 }}
                            color='success'
                            onClick={e => handleChangePassword(e)}
                          >
                            Update
                          </Button>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                </Box>
              </Modal>
              <Modal
                open={openConfrimProfile}
                onClose={handleCloseConfirmProfile}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box sx={style}>
                  <Typography variant='h6'>
                    Update Profile Confirmation{' '}
                  </Typography>
                  <Col lg={4}>
                    <Label className='profile_label'>Current Password</Label>

                    <FormControl>
                      {' '}
                      <OutlinedInput
                        value={currentPass}
                        onChange={e => setCurrentPass(e.target.value)}
                        sx={{
                          width: 330,
                          marginRight: 1,
                          fontSize: 12,
                          height: 35
                        }}
                        variant='outlined'
                        id='outlined-adornment-password'
                        type={showCurrentPass ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {showCurrentPass ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Col>

                  <Col lg={12}>
                    <Row>
                      <Col lg={6}>
                        <FormControl sx={{ mt: 2, minWidth: 150 }} size='large'>
                          <Button
                            variant='contained'
                            size='small'
                            // sx={{ width: 330, marginRight: 1, fontSize: 12 }}
                            color='error'
                            onClick={handleCloseConfirmProfile}
                          >
                            Cancel
                          </Button>
                        </FormControl>
                      </Col>
                      <Col lg={6}>
                        <FormControl sx={{ mt: 2, minWidth: 150 }} size='large'>
                          <Button
                            disabled={currentPass ? false : true}
                            variant='contained'
                            size='small'
                            // sx={{ width: 330, marginRight: 1, fontSize: 12 }}
                            color='success'
                            onClick={e => handleUpdateUserProfile(e)}
                          >
                            Update
                          </Button>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                </Box>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserProfile;
