import { useState, React, useRef, useEffect } from 'react';
import {
  Card,
  Chip,
  CircularProgress,
  Grid,
  StepButton,
  Switch
} from '@mui/material';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './CreateUsers.css';
import Paper from '@mui/material/Paper';
import { Autocomplete, Button, TextField } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import GetShipmentRates from '../GetRate/GetRates';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AdminProfileImage from '../../assets/images/super admin.jpg';
import AgencyProfileImage from '../../assets/images/agency.jpg';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import {
  AdminGetCarriers,
  AdminGetProfile,
  AgencyAdminGetProfile,
  CreateAdminRole,
  CreateOrUpdateAgency,
  UploadAgencyLogo
} from '../../apis/Admin';
import { ErrorToast, successToast } from '../../components/Toasts';
import UploadIcon from '@mui/icons-material/Upload';
import { ToastContainer, useToast } from 'react-toastify';

export default function CreateUsers(props) {
  const EditedData = props?.location?.state;
  const history = useHistory();

  const [shipmentFailed, setShipmentFailed] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [activeTabLabel, setActiveTabLabel] = useState('Detail');
  // agency states

  const [emailAddress, setEmailAddress] = useState('');

  const options = ['Active', 'In-Active'];

  const [agencyActiveValue, setAgencyActiveValue] = useState(options[0]);

  const userRoles = [
    'Owner',
    'Admin',
    'Shipping Manager',
    'Customer Service Manager'
  ];

  const [userRoleValue, setUserRoleValue] = useState(userRoles[0]);
  const [agencyStatus, setAgencyStatus] = useState(true);

  const [createAgencyLoader, setCreateAgencyLoader] = useState(false);
  const [agencyImage, setAgencyImage] = useState(
    EditedData?.logoBase64
      ? `data:image/png;base64, ${EditedData?.logoBase64}`
      : null
  );
  const [agencyApiUploadedImage, setAgencyApiUploadedImage] = useState(
    EditedData?.logo ? EditedData?.logo : ''
  );
  console.log(
    `data:image/png;base64, ${EditedData?.logoBase64}`,
    'data:image/png;base64, ${EditedData?.logoBase64}'
  );
  // validations
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  // Carriers state
  const [carriersLoader, setCarriersLoader] = useState(true);

  const [carriers, setCarriers] = useState([]);
  const [allCarriersChecked, setAllCarriersChecked] = useState(false);

  const [selectedCarriersIdArray, setSelectedCarriersIdArray] = useState([]);
  // admin States
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberValidError, setPhoneNumberValidError] = useState(false);

  const [isValid, setIsValid] = useState(true);
  const [adminActiveValue, setAdminActiveValue] = useState(options[0]);
  const [adminStatus, setAdminStatus] = useState(true);
  const [adminImage, setAdminImage] = useState(null);

  const handleAllActiveCarriers = event => {
    setAllCarriersChecked(event.target.checked);
    for (let i = 0; i < carriers.length; i++) {
      // const element = array[i];
      const tempIDs = [...selectedCarriersIdArray];
      tempIDs[i].assigned = event.target.checked;
      setSelectedCarriersIdArray(tempIDs);
      setCarriers(prevArray => {
        const newArray = [...prevArray];
        newArray[i].active = event.target.checked;
        return newArray;
      });
    }
  };

  const handleRadioChange = event => {
    setSelectedValue(event.target.value);
  };
  const handleActiveAllCarriersSwitch = TempCarrierIDs => {
    console.log(TempCarrierIDs, 'TempCarrierIDs');
    const StatusArray = [];
    for (let i = 0; i < TempCarrierIDs.length; i++) {
      StatusArray.push(
        TempCarrierIDs[i].assigned !== undefined
          ? TempCarrierIDs[i].assigned
          : TempCarrierIDs[i].active
      );
    }
    const allTrue = StatusArray.every(value => value === true);
    console.log(StatusArray, 'StatusArray');
    if (allTrue) {
      setAllCarriersChecked(true);
    } else {
      setAllCarriersChecked(false);
    }
  };
  const CheckSelectedCarriers = (CarriersArray, TempCarrierIDs) => {
    console.log(TempCarrierIDs, EditedData, 'TempCarrierIDs');
    if (EditedData?.carriers) {
      const PropsCarrierArray = EditedData?.carriers;
      const temp = [...CarriersArray];
      const tempIDs = [...TempCarrierIDs];

      for (let i = 0; i < CarriersArray.length; i++) {
        const staticId = CarriersArray[i].id;
        if (
          PropsCarrierArray.some(
            element => element.id === staticId && element.active
          )
        ) {
          temp[i].active = true;
          tempIDs[i].assigned = !tempIDs[i].assigned;
        }
      }

      handleActiveAllCarriersSwitch(TempCarrierIDs);
      setSelectedCarriersIdArray(tempIDs);
      setCarriers(temp);
    }
  };

  const handleSuccessCarriers = ApiRes => {
    setCarriersLoader(false);
    const CarriersArray = ApiRes?.data?.data;
    const TempCarrierIDs = [];
    for (let i = 0; i < CarriersArray.length; i++) {
      CarriersArray[i].active = false;
      TempCarrierIDs.push({ carrierId: CarriersArray[i].id, assigned: false });
    }
    setCarriers(CarriersArray);
    setSelectedCarriersIdArray(TempCarrierIDs);
    EditedData && CheckSelectedCarriers(CarriersArray, TempCarrierIDs);
  };

  const handleSuccessCreateAgency = ApiRes => {
    setCreateAgencyLoader(false);
    successToast(ApiRes?.data?.reasonPhrase);
    setTimeout(() => {
      history.push('/manage-agencies');
    }, 4000);
  };
  const handdleExistedUserValidation = () => {
    const errors = {};
    errors['emailError'] = 'User exist with this email try another one';
    setValidationErrors(errors);
  };

  const handleCreateUpdateAgencyReq = () => {
    setCreateAgencyLoader(true);

    // const CreateOrUpdateAgencyObj = {
    //   id: EditedData?.id ? EditedData?.id : 0,
    //   name: companyName,
    //   logo: agencyApiUploadedImage,
    //   url: '',
    //   active: agencyStatus,
    //   address: address1,
    //   address2: address2,
    //   zipCode: zip,
    //   city: city,
    //   state: state,
    //   country: country,
    //   subscriptioPlanId: 1,
    //   carriers: selectedCarriersIdArray,
    //   firstName: firstName,
    //   lastName: lastName,
    //   email: email,
    //   phone: phoneNumber
    // };
    // console.log(CreateOrUpdateAgencyObj, 'CreateOrUpdateAgencyObj');
    // CreateOrUpdateAgency(CreateOrUpdateAgencyObj)
    //   .then(res => res.status === 200 && handleSuccessCreateAgency(res))
    //   .catch(err => {
    //     ErrorToast(err?.message);
    //     setCreateAgencyLoader(false);
    //   });
  };

  const ValidatUserEmail = () => {
    AgencyAdminGetProfile(email)
      .then(res => res.status === 200 && handdleExistedUserValidation())
      .catch(err => handleCreateUpdateAgencyReq());
  };

  const handleSuccessGetAgencyDetails = ApiRes => {
    const AgencyAdminDetails = ApiRes?.data?.data;
    setFirstName(AgencyAdminDetails.firstName);
    setLastName(AgencyAdminDetails.lastName);
    setEmail(AgencyAdminDetails.email);
    setPhoneNumber(AgencyAdminDetails.phone);
  };

  const handleCreateAdminRoleRequest = () => {
    const obj = {
      id: 0,
      name: firstName + ' ' + lastName,
      status: agencyActiveValue === 'Active' ? true : false,
      agencyId: 0,
      permissions: [
        {
          permissionName: userRoleValue,
          status: true,
          level1: true,
          level2: true,
          level3: true,
          level4: true,
          level5: true
        }
      ]
    };
    CreateAdminRole(obj)
      .then(
        res => res.status === 200 && successToast('Role Created Successfully')
      )
      .catch(err => ErrorToast('Network Error'));
  };

  const handleSaveAdminRole = index => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let errors = {};
    if (!firstName) {
      errors['firstNameError'] = 'Enter First Name';
    } else if (!lastName) {
      errors['lastNameError'] = 'Enter Last Name';
    } else if (!emailAddress) {
      errors['emailAddressError'] = 'Enter email Address';
    } else if (!emailRegex.test(emailAddress)) {
      errors['emailAddressError'] = 'Enter valid email address ';
    } else if (phoneNumberValidError) {
      errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
    } else if (!phoneNumber) {
      errors['phoneNumberError'] = 'Enter phone Number';
    } else {
      handleCreateAdminRoleRequest();
    }
    //   else if (!country) {
    //     errors['countryError'] = 'Enter Country';
    //   } else setActiveStep(prevActiveStep => prevActiveStep + 1);
    setValidationErrors(errors);
  };

  const handleAgencyImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const imageType = /image.*/;
    if (!file.type.match(imageType)) {
      ErrorToast('Only image files are allowed');
    }
    if (file.type.match(imageType)) {
      reader.onloadend = () => {
        setAgencyImage(reader.result);
      };
      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append('file', file);
      var formdata = new FormData();
      formdata.append('imageFile', file);
      UploadAgencyLogo(formData)
        .then(
          res =>
            res.status === 200 && setAgencyApiUploadedImage(res?.data?.data)
        )
        .catch(err => ErrorToast(err?.message));
    }
  };

  const handleAdminImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAdminImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handlePhoneNumberPaste = event => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text from clipboard
    const pastedText = event.clipboardData.getData('text/plain');

    // Remove non-numeric characters from pasted text
    const cleanedText = pastedText.replace(/\D/g, '');

    // Apply formatting logic
    const formattedValue = cleanedText.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      '($1) $2-$3'
    );
    setPhoneNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, phoneNumberError: null });
    } else {
      setPhoneNumberValidError(true);
      errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };
  const handlePhoneNumberChange = event => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and retain formatting
    const formattedValue = inputValue
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    setPhoneNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, phoneNumberError: null });
    } else {
      setPhoneNumberValidError(true);
      errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };
  const handleEmailValidation = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const errors = {};
    setEmailAddress(email);

    if (emailRegex.test(email)) {
      setIsValidEmail(true);
      setValidationErrors({
        ...validationErrors,
        emailError: null
      });
    } else {
      setIsValidEmail(false);
      errors['emailError'] = 'Enter Valid Email';
    }
    setValidationErrors(errors);
  };
  return (
    <Box
      sx={{
        p: 2,
        minHeight: '60vh',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <ToastContainer
        position='top-center'
        autoClose={3800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Paper className='Grid_Item'>
        <Box sx={{ width: '42vw' }}>
          <Grid>
            <Grid sx={{ backgroundColor: '#e9e9e9', borderRadius: 2, mt: 1 }}>
              <Grid sx={{ height: 120 }}>
                <div>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={handleAgencyImageUpload}
                    style={{ display: 'none' }}
                    id='image-upload'
                  />
                  <label htmlFor='image-upload'>
                    {agencyImage ? (
                      <img
                        className='Admin_Img'
                        src={agencyImage}
                        alt=''
                        width='200'
                        style={{
                          borderRadius: '50%',
                          objectFit: 'cover',
                          cursor: 'pointer'
                        }}
                      />
                    ) : (
                      <span
                        role='img'
                        aria-label='upload icon'
                        className='Admin_Img'
                        style={{
                          display: 'inline-block',
                          borderRadius: '50%',
                          backgroundColor: '#e5e5e5',
                          textAlign: 'center',
                          lineHeight: '120px',
                          cursor: 'pointer'
                        }}
                      >
                        <UploadIcon />
                        Upload
                      </span>
                    )}
                  </label>
                </div>
              </Grid>
            </Grid>

            <Grid sx={{ mt: 7 }}>
              <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextField
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['firstNameError'])}
                  helperText={validationErrors['firstNameError']}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z]*$/.test(inputValue)) {
                      setFirstName(inputValue);
                      setValidationErrors({
                        ...validationErrors,
                        firstNameError: null
                      });
                    }
                  }}
                  value={firstName}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='First Name'
                  type='text'
                />

                <TextField
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['lastNameError'])}
                  helperText={validationErrors['lastNameError']}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z]*$/.test(inputValue)) {
                      setLastName(inputValue);
                      setValidationErrors({
                        ...validationErrors,
                        lastNameError: null
                      });
                    }
                  }}
                  value={lastName}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='Last Name'
                  type='text'
                />
              </Grid>

              <Grid
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <TextField
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['emailAddressError'])}
                  helperText={validationErrors['emailAddressError']}
                  onChange={e => handleEmailValidation(e.target.value)}
                  value={emailAddress}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='Email Address'
                  type='email'
                />
                <TextField
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['phoneNumberError'])}
                  helperText={validationErrors['phoneNumberError']}
                  onChange={handlePhoneNumberChange}
                  onPaste={handlePhoneNumberPaste}
                  value={phoneNumber}
                  required
                  inputProps={{
                    maxLength: 10,
                    style: { fontSize: 13 } // Set the maximum length to 10 characters
                  }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='Phone Number'
                  type='text'
                />
              </Grid>
              <Grid
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <Autocomplete
                  sx={{ width: '20vw', mt: 0 }}
                  size='small'
                  value={userRoleValue}
                  onChange={(event, newValue) => setUserRoleValue(newValue)}
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      {option}
                    </Box>
                  )}
                  options={userRoles}
                  renderInput={params => (
                    <TextField
                      label='Role'
                      variant='standard'
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 13 }
                      }}
                    />
                  )}
                />
                <Autocomplete
                  sx={{ width: '20vw', mt: 0 }}
                  size='small'
                  value={agencyActiveValue}
                  onChange={(event, newValue) => setAgencyActiveValue(newValue)}
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      {option}
                    </Box>
                  )}
                  options={options}
                  renderInput={params => (
                    <TextField
                      label='Status'
                      variant='standard'
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 13 }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid sx={{ mt: 10, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleSaveAdminRole} variant='contained'>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
