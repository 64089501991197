import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import { Button, Col, Label, Row } from 'reactstrap';
import {
  Autocomplete,
  CardContent,
  Card,
  FormControlLabel,
  InputAdornment,
  Paper,
  TextField,
  ToggleButtonGroup,
  Box,
  CircularProgress,
  createFilterOptions
} from '@mui/material';
import Switch from '@mui/material/Switch';
import MergeTypeIcon from '@material-ui/icons/LocalShippingSharp';
import LocationIcon from '@material-ui/icons/PersonPinSharp';
import ToggleButton from '@mui/material/ToggleButton';
import PaymentIcon from '@material-ui/icons/PaymentSharp';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useState } from 'react';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useSelector } from 'react-redux';
import {
  // GetCarriers,
  GetCTQuoteRate,
  GetEstesQuoteRate,
  GetFedExQuoteRate,
  // GetLocations,
  GetSAIAQuoteRate,
  GetXPOQuoteRate
} from '../../apis/Admin';
import { Spinner } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setCTQuoteRate,
  setEstesQuoteRate,
  setFedExRates,
  setRateValues,
  setSAIAQuoteRate,
  setXPOQuoteRate
} from '../../Redux/GetRatesSlice';
import { setClassDimensionsButton } from '../../Redux/ClassDimensionsBtnSlice';
import './getQuote.css';
import {
  setDeliveryCheckedRedux,
  setPickUpCheckedRedux
} from '../../Redux/GetServicesSwitchSlice';
import USALocations from '../../apis/USALocations.json';
import { Country, State, City } from 'country-state-city';
import { setQuickQuoteRateValues } from '../../Redux/QuickQuoteRateSlice';
import {
  setQuickQuoteDeliveryCheckedRedux,
  setQuickQuotePickUpCheckedRedux
} from '../../Redux/QuickQuoteSwevicesSlice';
import {
  setClassRows
  // setDimensionRows
} from '../../Redux/QuickQuoteAddedRows';
import { setDimensionRows } from '../../Redux/QuickQuoteDimenionsAddedRows';
import {
  setClassWeight,
  setDimensionsWeight
} from '../../Redux/QuickQuoteTotalWeightSlice';

function QuickQuote(props) {
  const [shipCity, setShipCity] = useState([]);
  const [shipState, setShipState] = useState();
  const [shipZip, setShipZip] = useState();
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);

  const handleUSAddresses = () => {
    let tempCity = [];
    let tempState = [];
    let tempZipCode = [];
    for (let index = 0; index < USALocations.length; index++) {
      const City = USALocations[index].city;
      const State = USALocations[index].state;
      const ZipCode = USALocations[index].zip_code;

      tempCity.push({ title: City + ' ' + State + ' ' + ZipCode });
      // tempState.push(State);
      // tempZipCode.push(ZipCode);
    }
    setShipCity(tempCity);
    // setShipState(tempState);
    // setShipZip(tempZipCode);
  };
  useEffect(() => {
    handleUSAddresses();
  }, []);

  var layoutMode = document.body.getAttribute('data-layout-mode');

  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const UserDetails = useSelector(state => state.userDetails);
  const viewShipmentsData = useSelector(state => state.getShipments);
  const SelectedRates = useSelector(state => state.quickQuoteRate);
  const AddedClassRows = useSelector(state => state.newAddedPersistedRows);
  const AddedDimensionsRows = useSelector(
    state => state.newAddedPersistedDimensionsRows
  );

  const ServicesSwitchChecks = useSelector(
    state => state.quickQuoteServicesSlice
  );
  const ClassDimBtn = useSelector(state => state.classDimensionsBtn);
  const TotalClassPersistedWeight = useSelector(
    state => state.totalPersistedWeightLBS.ClassWeight
  );
  const TotalDimensionsPersistedWeight = useSelector(
    state => state.totalPersistedWeightLBS.DimensionsWeight
  );
  const selectedTableRowID = props.location.state;
  const dispatch = useDispatch();
  const classes = useStyles();

  const PaymentTypeArr = ['Outbound Prepaid', 'Third Party'];
  const [shipTypeOptions, setShipTypeOptions] = useState([]);
  const [hazmatSwitch, setHazmatSwitch] = useState(
    SelectedRates?.isHazmat ? SelectedRates?.isHazmat : false
  );
  const [stackableSwitch, setStackableSwitch] = useState(false);
  const [pickUpChecked, setPickUpChecked] = useState(
    ServicesSwitchChecks.QuickQuotePickUpCheckedRedux
      ? ServicesSwitchChecks.QuickQuotePickUpCheckedRedux
      : false
  );
  const [overLengthValue, setOverLengthValue] = useState(
    SelectedRates.pickupOptions?.overLengthValue
      ? SelectedRates?.pickupOptions?.overLengthValue
      : ''
  );
  const [deliveryChecked, setDeliveryChecked] = useState(
    ServicesSwitchChecks.QuickQuoteDeliveryCheckedRedux
      ? ServicesSwitchChecks.QuickQuoteDeliveryCheckedRedux
      : false
  );
  //pickup switch states
  const [insidePickupSwitch, setInsidePickupSwitch] = useState(
    SelectedRates?.pickupOptions?.inside
      ? SelectedRates?.pickupOptions?.inside
      : false
  );
  const [liftgatePickupSwitch, setLiftgatePickupSwitch] = useState(
    SelectedRates?.pickupOptions?.liftGateServices
      ? SelectedRates?.pickupOptions?.liftGateServices
      : false
  );
  const [residentialPickupSwitch, setResidentialPickupSwitch] = useState(
    SelectedRates?.pickupOptions?.residential
      ? SelectedRates?.pickupOptions?.residential
      : false
  );
  const [secureAccesPickupSwitch, setSecureAccesPickupSwitch] = useState(
    SelectedRates?.pickupOptions?.secureAccess
      ? SelectedRates?.pickupOptions?.secureAccess
      : false
  );
  const [limitedPickupAccessSwitch, setLimitedPickupAccessSwitch] = useState(
    SelectedRates?.pickupOptions?.limitedAccess
      ? SelectedRates?.pickupOptions?.limitedAccess
      : false
  );
  const [freezeProtectionPickupSwitch, setFreezeProtectionPickupSwitch] =
    useState(
      SelectedRates?.pickupOptions?.freezeProtection
        ? SelectedRates?.pickupOptions?.freezeProtection
        : false
    );
  const [overlengthPickupSwitch, setOverlengthPickupSwitch] = useState(
    SelectedRates?.pickupOptions?.overLength
      ? SelectedRates?.pickupOptions?.overLength
      : false
  );
  //delivery switch states
  const [insideDeliverySwitch, setInsideDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.inside
      ? SelectedRates?.deliveryOptions?.inside
      : false
  );
  const [liftgateDeliverySwitch, setLiftgateDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.liftGateServices
      ? SelectedRates?.deliveryOptions?.liftGateServices
      : false
  );
  const [residentialDeliverySwitch, setResidentialDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.residential
      ? SelectedRates?.deliveryOptions?.residential
      : false
  );
  const [limitedDeliverySwitch, setLimitedDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.limitedAccess
      ? SelectedRates?.deliveryOptions?.limitedAccess
      : false
  );
  const [secureAccesDeliverySwitch, setSecureAccesDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.secureAccess
      ? SelectedRates?.deliveryOptions?.secureAccess
      : false
  );
  const [
    callBeforeDeliveryServicesSwitch,
    setCallBeforeDeliveryServicesSwitch
  ] = useState(
    SelectedRates?.deliveryOptions?.callBeforeDelivery
      ? SelectedRates?.deliveryOptions?.callBeforeDelivery
      : false
  );

  // const [viewShipmentsData, setViewShipmentsData] = useState([]);
  const [shipmentDate, setShipmentDate] = useState(materialDateInput);
  const DataToShow = viewShipmentsData.ShipmentDetails[selectedTableRowID];
  // const [loading, setLoading] = useState(false);
  const ShipmentType = DataToShow?.shipmentType;
  const ShipTypeArr = ['LTL', 'Parcel', 'TL', 'Tanker', 'Others'];
  const UnitTypeArr = ['Pallet', 'Tote', 'Box', 'Drum'];
  const [shipTypeValue, setShipTypeValue] = useState(
    SelectedRates?.shipmentType ? SelectedRates?.shipmentType : ShipTypeArr[0]
  );
  const [unitTypeValue, setUnitTypeValue] = useState(UnitTypeArr[0]);

  const [validationErrors, setValidationErrors] = useState({});

  const [shipLocation, setShipLocation] = useState([]);
  const [shipLocationValue, setShipLocationValue] = useState(
    SelectedRates?.location ? SelectedRates?.location : 'Garland TX 75041'
  );
  const [paymentTypeValue, setPaymentTypeValue] = useState(
    SelectedRates?.paymentType ? SelectedRates?.paymentType : PaymentTypeArr[0]
  );
  const [backBtnClicked, setBackBtnClicked] = useState(false);
  const [quickWeightLBS, setQuickWeightLBS] = useState('');
  const [finalCommodityInfoApi, setFinalCommodityInfoApi] = useState(
    SelectedRates?.commodityInfo ? SelectedRates?.commodityInfo : []
  );
  const ClassArray = [
    '50',
    '55',
    '60',
    '65',
    '70',
    '77.5',
    '85',
    '92.5',
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
    '250',
    '300',
    '400',
    '500'
  ];
  const [classArrayValue, setClassArrayValue] = useState(ClassArray[0]);
  const CommodityInfoArray = [
    {
      WeightLBS: '',
      Class: classArrayValue,
      NMFCnumber: ''
    }
  ];
  const [NMFCnumber, setNMFCnumber] = useState('');
  const [dimensionsWeightLBS, setDimensionsWeightLBS] = useState('');
  const [dimensionsWeightLBSErr, setDimensionsWeightLBSErr] = useState(false);
  const [nmbOfunitsErr, setNmbOfunitsErr] = useState(false);
  const [nmbOfunits, setNmbOfunits] = useState('');
  const [dimensions, setDimensions] = useState({
    Lenght: '',
    Width: '',
    Height: ''
  });
  const [dimensionsValueErr, setDimensionsValueErr] = useState({
    Lenght: false,
    Width: false,
    Height: false
  });
  const [CODAmount, setCODAmount] = useState(
    SelectedRates?.codAmount ? SelectedRates.codAmount : ''
  );
  const [DeclaredValue, setDeclaredValue] = useState(
    SelectedRates?.declaredValue ? SelectedRates.declaredValue : ''
  );
  const [getRateLoading, setGetRateLoading] = useState(false);
  const [selectClassErr, setSelectClassErr] = useState(false);
  const [dimensionsErr, setDimensionsErr] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [shipFromValue, setShipFromValue] = useState('');
  const [shipToValue, setShipToValue] = useState('');
  const [newAddedDimensionsRow, setNewAddedDimensionsRow] = useState([
    {
      WeightLBS: SelectedRates?.commodityInfo?.[0]
        ? SelectedRates?.commodityInfo?.[0].weight.value
        : '',
      UnitTypeValue: SelectedRates?.commodityInfo?.[0].packaging
        ? SelectedRates?.commodityInfo?.[0].packaging
        : UnitTypeArr[0],
      NmbOfUnits: SelectedRates?.commodityInfo?.[0].pieces
        ? SelectedRates?.commodityInfo?.[0].pieces
        : '',
      StackAble: SelectedRates?.commodityInfo?.[0].dimensions.isStackable
        ? SelectedRates?.commodityInfo?.[0].dimensions.isStackable
        : false,
      Length: SelectedRates?.commodityInfo?.[0].dimensions.length
        ? SelectedRates?.commodityInfo?.[0].dimensions.length
        : '',
      Width: SelectedRates?.commodityInfo?.[0].dimensions.width
        ? SelectedRates?.commodityInfo?.[0].dimensions.width
        : '',
      Height: SelectedRates?.commodityInfo?.[0].dimensions.height
        ? SelectedRates?.commodityInfo?.[0].dimensions.height
        : ''
    }
  ]);

  const [newAddedClassRow, setNewAddedClassRow] = useState([
    {
      WeightLBS: '',
      Class: ClassArray[0],
      NMFCnumber: ''
    }
  ]);
  const [totalLBSWeightClass, setTotalLBSWeightClass] = useState('');

  const [totalLBSWeightDimensions, setTotalLBSWeightDimensions] = useState('');
  const [totalDimensionsWeightSum, setTotalDimensionsWeightSum] = useState(
    TotalDimensionsPersistedWeight
  );
  const [totalClassWeightSum, setTotalClassWeightSum] = useState(
    TotalClassPersistedWeight
  );

  const [alignment, setAlignment] = useState(
    ClassDimBtn ? ClassDimBtn : 'Class'
  );
  const [isOverLength, setIsOverLength] = useState(
    SelectedRates.pickupOptions?.overLength
      ? SelectedRates?.pickupOptions?.overLength
      : false
  );
  const DetailsArr = [];
  const ShipFrom =
    SelectedRates?.shipFrom?.city +
    ' ' +
    SelectedRates?.shipFrom?.stateOrProvinceCode +
    ' ' +
    SelectedRates?.shipFrom?.postalCode;

  const ShipTo =
    SelectedRates?.shipTo?.city +
    ' ' +
    SelectedRates?.shipTo?.stateOrProvinceCode +
    ' ' +
    SelectedRates?.shipTo?.postalCode;
  const WeightLBS = DataToShow?.details[0].grossWeight;
  const WeightPieces = DataToShow?.details[0].pieces;

  useEffect(() => {
    let ToalWeightLBS = 0;
    if (AddedClassRows) {
      for (let i = 0; i < AddedClassRows.length; i++) {
        ToalWeightLBS += parseInt(AddedClassRows[i].WeightLBS);
      }
      setTotalLBSWeightClass(ToalWeightLBS);
    }
  }, []);

  useEffect(() => {
    let ToalWeightLBS = 0;
    if (AddedDimensionsRows) {
      for (let i = 0; i < AddedDimensionsRows.length; i++) {
        ToalWeightLBS += parseInt(AddedDimensionsRows[i].WeightLBS);
      }
      setTotalLBSWeightDimensions(ToalWeightLBS);
    }
  }, []);

  useEffect(() => {
    let totalWeight;
    if (SelectedRates?.commodityInfo?.[0]?.weight?.value) {
      const copy1 = JSON.parse(JSON.stringify(AddedClassRows));
      setNewAddedClassRow(copy1);
      const copy2 = JSON.parse(JSON.stringify(AddedDimensionsRows));

      setNewAddedDimensionsRow(copy2);

      if (alignment === 'Dimensions') {
        setTotalDimensionsWeightSum(
          parseInt(SelectedRates.commodityInfo[0]?.weight?.value)
        );
      }

      if (alignment === 'Class') {
        setTotalClassWeightSum(
          parseInt(SelectedRates.commodityInfo[0]?.weight?.value)
        );
      }
    }
    let FinalObj = {};
    if (!ShipFrom.includes('undefined') && paymentTypeValue === 'Third Party') {
      const tempData = ShipFrom.split(' ');
      const City = tempData[0];
      const State = tempData[1];
      const ZipCode = tempData[2];
      FinalObj = {
        City,
        State,
        ZipCode
      };
      setShipFromFinalAddress(FinalObj);
    }
    if (!ShipTo.includes('undefined')) {
      const tempData = ShipTo.split(' ');
      const City = tempData[0];
      const State = tempData[1];
      const ZipCode = tempData[2];
      FinalObj = {
        City,
        State,
        ZipCode
      };
      setShipToFinalAddress(FinalObj);
    }
  }, []);

  const handleDimensionChange = (name, dimensionsVal) => {
    if (name === 'length') {
      setDimensions({ ...dimensions, Lenght: dimensionsVal });
      setDimensionsValueErr({ ...dimensionsValueErr, Lenght: false });
    } else if (name === 'width') {
      setDimensions({ ...dimensions, Width: dimensionsVal });
      setDimensionsValueErr({ ...dimensionsValueErr, Width: false });
    } else setDimensions({ ...dimensions, Height: dimensionsVal });
    setDimensionsValueErr({ ...dimensionsValueErr, Height: false });
  };

  const handleGetRates = e => {
    e.preventDefault();

    if (dimensionsErr) {
      if (!dimensionsWeightLBS) {
        setDimensionsWeightLBSErr(true);
      }
      if (!nmbOfunits) {
        setNmbOfunitsErr(true);
      }
      if (!dimensions.Lenght) {
        setDimensionsValueErr({ ...dimensionsValueErr, Lenght: true });
      }
      if (!dimensions.Width) {
        setDimensionsValueErr({ ...dimensionsValueErr, Width: true });
      }
      if (!dimensions.Height) {
        setDimensionsValueErr({ ...dimensionsValueErr, Height: true });
      }
    }
    if (!classArrayValue) {
      setSelectClassErr(true);
    } else if (classArrayValue) {
      setSelectClassErr(false);
      // setGetRateLoading(true);
      let OutbondShipper;
      let PersistedShipFrom;
      let PersistedShipTo;
      if (paymentTypeValue === 'Outbound Prepaid') {
        OutbondShipper = shipFromFinalAddress.split(' ');
      }

      let obj = {
        shipmentType: shipTypeValue,
        location: shipLocationValue,
        paymentType: paymentTypeValue,
        shipFrom: {
          city:
            paymentTypeValue === 'Outbound Prepaid'
              ? OutbondShipper[0]
              : shipFromFinalAddress.City,
          stateOrProvinceCode:
            paymentTypeValue === 'Outbound Prepaid'
              ? OutbondShipper[1]
              : shipFromFinalAddress.State,
          postalCode:
            paymentTypeValue === 'Outbound Prepaid'
              ? OutbondShipper[2]
              : shipFromFinalAddress.ZipCode,
          countryCode: 'USA',
          residential: true,
          streetLines: 'string'
        },
        shipTo: {
          city: shipToFinalAddress.City,
          stateOrProvinceCode: shipToFinalAddress.State,
          postalCode: shipToFinalAddress.ZipCode,
          countryCode: 'USA',
          residential: true,
          streetLines: 'string'
        },
        shipmentDate: shipmentDate,
        isHazmat: hazmatSwitch,
        commodityInfo: finalCommodityInfoApi,

        pickupOptions: {
          inside: insidePickupSwitch,
          liftGateServices: liftgatePickupSwitch,
          residential: residentialPickupSwitch,
          secureAccess: secureAccesPickupSwitch,
          limitedAccess: limitedPickupAccessSwitch,
          freezeProtection: freezeProtectionPickupSwitch,
          overLength: overlengthPickupSwitch,
          overLengthValue: overLengthValue ? parseFloat(overLengthValue) : 0,
          callBeforeDelivery: false
        },
        deliveryOptions: {
          inside: insideDeliverySwitch,
          liftGateServices: liftgateDeliverySwitch,
          residential: residentialDeliverySwitch,
          secureAccess: secureAccesDeliverySwitch,
          limitedAccess: limitedDeliverySwitch,
          freezeProtection: false,
          overLength: false,
          overLengthValue: 0,
          callBeforeDelivery: callBeforeDeliveryServicesSwitch
        },
        codAmount: CODAmount ? parseInt(CODAmount) : 0,
        declaredValue: DeclaredValue ? parseInt(DeclaredValue) : 0,
        agencyId: AgencyID ? AgencyID : 0
      };

      //   dispatch(setPickUpCheckedRedux(pickUpChecked));
      //   dispatch(setDeliveryCheckedRedux(deliveryChecked));
      //   dispatch(setRateValues(obj));

      let deepClone = JSON.parse(JSON.stringify(obj));
      // let newObj = { ...obj, };
      deepClone.shipFrom.countryCode = 'US';
      deepClone.shipTo.countryCode = 'US';
      validateAutocompletes(obj, deepClone);
    }
  };

  const handlePickUpServicesChange = switchName => {
    if (switchName === 'hazmat') {
      setHazmatSwitch(c => !c);
    } else if (switchName === 'Stackable') {
      setStackableSwitch(c => !c);
    } else if (switchName === 'Inside Pickup') {
      setInsidePickupSwitch(c => !c);
    } else if (switchName === 'Inside Lift Gate Services') {
      setLiftgatePickupSwitch(c => !c);
    } else if (switchName === 'Residential Pickup') {
      setResidentialPickupSwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesPickupSwitch(c => !c);
    } else if (switchName === 'Freeze Protection') {
      setFreezeProtectionPickupSwitch(c => !c);
    } else if (switchName === 'Overlength') {
      setOverlengthPickupSwitch(c => !c);
      setIsOverLength(!isOverLength);
    } else setLimitedPickupAccessSwitch(c => !c);
  };

  const handleDeliveryServicesChange = switchName => {
    if (switchName === 'Inside Delivery') {
      setInsideDeliverySwitch(c => !c);
    } else if (switchName === 'Liftgate Delivery') {
      setLiftgateDeliverySwitch(c => !c);
    } else if (switchName === 'Residential Delivery') {
      setResidentialDeliverySwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesDeliverySwitch(c => !c);
    } else if (switchName === 'Call Before Delivery') {
      setCallBeforeDeliveryServicesSwitch(c => !c);
    } else setLimitedDeliverySwitch(c => !c);
  };

  const checkPickupStatus = () => {
    if (pickUpChecked) {
      // alert('asdas');
      setInsidePickupSwitch(false);
      setLiftgatePickupSwitch(false);
      setResidentialPickupSwitch(false);
      setSecureAccesPickupSwitch(false);
      setFreezeProtectionPickupSwitch(false);
      setOverlengthPickupSwitch(false);
      setLimitedPickupAccessSwitch(false);
      let obj = {
        shipmentType: ShipmentType,
        location: shipLocationValue,
        paymentType: paymentTypeValue,
        shipFrom: {
          city: DataToShow?.originCity.trim(),
          stateOrProvinceCode: DataToShow?.originState.trim(),
          postalCode: DataToShow?.originZipCode,
          countryCode: 'US',
          residential: true,
          streetLines: DataToShow?.originAddress.trim()
        },
        shipTo: {
          city: DataToShow?.destinationCity.trim(),
          stateOrProvinceCode: DataToShow?.destinationState.trim(),
          postalCode: DataToShow?.destinationZipCode,
          countryCode: 'US',
          residential: true,
          streetLines: DataToShow?.destinationAddress.trim()
        },
        shipmentDate: shipmentDate,
        isHazmat: hazmatSwitch,
        commodityInfo: [
          {
            freightClass: classArrayValue,
            packaging: DataToShow?.packagingType,
            pieces: WeightPieces,
            weight: {
              units: 'lbs',
              value: WeightLBS
            },
            nmfc: NMFCnumber ? NMFCnumber : '',
            dimensions: {
              length: dimensions.Lenght ? dimensions.Lenght : 0,
              width: dimensions.Width ? dimensions.Width : 0,
              height: dimensions.Height ? dimensions.Height : 0,
              dimensionsUom: 'cm',
              isStackable: stackableSwitch
            }
          }
        ],

        pickupOptions: {
          inside: false,
          liftGateServices: false,
          residential: false,
          secureAccess: false,
          limitedAccess: false,
          freezeProtection: false,
          overLength: false,
          callBeforeDelivery: false
        },
        deliveryOptions: {
          inside: insideDeliverySwitch,
          liftGateServices: liftgateDeliverySwitch,
          residential: residentialDeliverySwitch,
          secureAccess: secureAccesDeliverySwitch,
          limitedAccess: limitedDeliverySwitch,
          freezeProtection: false,
          overLength: false,
          callBeforeDelivery: callBeforeDeliveryServicesSwitch
        },
        codAmount: CODAmount ? CODAmount : 0,
        declaredValue: DeclaredValue ? DeclaredValue : 0
      };

      //   dispatch(setRateValues(obj));
    }
  };
  const checkDeliveryStatus = () => {
    if (deliveryChecked) {
      // alert('asdas');
      setInsideDeliverySwitch(false);
      setLiftgateDeliverySwitch(false);
      setResidentialDeliverySwitch(false);
      setSecureAccesDeliverySwitch(false);
      setCallBeforeDeliveryServicesSwitch(false);
      setLimitedDeliverySwitch(false);
      let obj = {
        shipmentType: ShipmentType,
        location: shipLocationValue,
        paymentType: paymentTypeValue,
        shipFrom: {
          city: DataToShow?.originCity.trim(),
          stateOrProvinceCode: DataToShow?.originState.trim(),
          postalCode: DataToShow?.originZipCode,
          countryCode: 'US',
          residential: true,
          streetLines: DataToShow?.originAddress.trim()
        },
        shipTo: {
          city: DataToShow?.destinationCity.trim(),
          stateOrProvinceCode: DataToShow?.destinationState.trim(),
          postalCode: DataToShow?.destinationZipCode,
          countryCode: 'US',
          residential: true,
          streetLines: DataToShow?.destinationAddress.trim()
        },
        shipmentDate: shipmentDate,
        isHazmat: hazmatSwitch,
        commodityInfo: [
          {
            freightClass: classArrayValue,
            packaging: DataToShow?.packagingType,
            pieces: WeightPieces,
            weight: {
              units: 'lbs',
              value: WeightLBS
            },
            nmfc: NMFCnumber ? NMFCnumber : '',
            dimensions: {
              length: dimensions.Lenght ? dimensions.Lenght : 0,
              width: dimensions.Width ? dimensions.Width : 0,
              height: dimensions.Height ? dimensions.Height : 0,
              dimensionsUom: 'cm',
              isStackable: stackableSwitch
            }
          }
        ],

        pickupOptions: {
          inside: insidePickupSwitch,
          liftGateServices: liftgatePickupSwitch,
          residential: residentialPickupSwitch,
          secureAccess: secureAccesPickupSwitch,
          limitedAccess: limitedPickupAccessSwitch,
          freezeProtection: freezeProtectionPickupSwitch,
          overLength: overlengthPickupSwitch,
          callBeforeDelivery: false
        },
        deliveryOptions: {
          inside: false,
          liftGateServices: false,
          residential: false,
          secureAccess: false,
          limitedAccess: false,
          freezeProtection: false,
          overLength: false,
          callBeforeDelivery: false
        },
        codAmount: CODAmount ? CODAmount : 0,
        declaredValue: DeclaredValue ? DeclaredValue : 0
      };

      //   dispatch(setRateValues(obj));
    }
  };
  const handlePickupServices = v => {
    setPickUpChecked(c => !c);
    checkPickupStatus();
    // setDeliveryChecked(false);
  };

  const handleDeliveryServices = () => {
    setDeliveryChecked(c => !c);
    checkDeliveryStatus();
    // setPickUpChecked(false);
  };
  const handleChangeToggle = (event, newAlignment) => {
    setAlignment(newAlignment);
    if (newAlignment === 'Dimensions') {
      handlePushAddedCommodityInfo(totalDimensionsWeightSum);
    }
    // setDimensionsErr(false);
    else handlePushAddedCommodityInfo(totalClassWeightSum);
  };

  const handleSetShipLocation = arr => {
    const temp = [];
    for (let i = 0; i < arr.length; i++) {
      const Element = arr[i].smValue;
      temp.push(Element);
    }
    setShipLocation(temp);
    const DefaultLocation = temp[0];
  };
  useEffect(() => {
    // GetLocations()
    //   .then(res => res.status === 200 && handleSetShipLocation(res.data.data))
    //   .catch(err => console.log(err));
  }, []);

  const handleCheckAddedRow = () => {};

  const handlePushAddedCommodityInfo = TotalWeightSum => {
    // alert(`alignment${alignment}`);
    let CommodityInfoApi = [
      {
        freightClass: newAddedClassRow[0].Class,
        packaging: 'Pallet',
        pieces: 0,
        weight: {
          units: 'lbs',
          value: TotalWeightSum
        },
        nmfc: newAddedClassRow[0].NMFCnumber,
        dimensions: {
          length: 0,
          width: 0,
          height: 0,
          dimensionsUom: 'cm',
          isStackable: false
        }
      }
    ];

    setFinalCommodityInfoApi(CommodityInfoApi);
  };
  const handleClassInputChange = (inputName, index, event) => {
    let totalWeight = 0;
    let TotalWeightSum;
    const values = [...newAddedClassRow];
    // console.log(values, inputName, index, event.target.value, 'what is heress');
    if (inputName === 'WeightLBS') {
      const updatedErrors = { ...validationErrors, weightLBS: null };
      setValidationErrors(updatedErrors);

      values[index].WeightLBS = event.target.value;

      // alert('karam');
      for (let index = 0; index < newAddedClassRow.length; index++) {
        if (newAddedClassRow[index].WeightLBS !== '') {
          totalWeight += parseInt(newAddedClassRow[index].WeightLBS);
        }
      }
      setTotalLBSWeightClass(totalWeight);
      TotalWeightSum = totalLBSWeightDimensions
        ? parseInt(totalLBSWeightDimensions) + totalWeight
        : totalWeight;
      setTotalClassWeightSum(totalWeight);
      handlePushAddedCommodityInfo(totalWeight);
    } else if (inputName === 'NMFCnumber') {
      values[index].NMFCnumber = event.target.value;
    } else if (inputName === 'Class') {
      values[index].Class = event === null ? ClassArray[0] : event;
    }

    setNewAddedClassRow(values);
  };

  const handleDimensionsInputChange = (inputName, index, event) => {
    let totalWeight = 0;
    let TotalWeightSum;
    const values = [...newAddedDimensionsRow];
    if (inputName === 'WeightLBS') {
      const updatedErrors = {
        ...validationErrors,
        newAddedDimensionsRow: null
      };
      setValidationErrors(updatedErrors);
      values[index].WeightLBS = event.target.value;

      for (let index = 0; index < newAddedDimensionsRow.length; index++) {
        if (newAddedDimensionsRow[index].WeightLBS !== '') {
          totalWeight += parseInt(newAddedDimensionsRow[index].WeightLBS);
        }
      }
      setTotalLBSWeightDimensions(totalWeight);
      TotalWeightSum = totalLBSWeightClass
        ? parseInt(totalLBSWeightClass) + totalWeight
        : totalWeight;
      setTotalDimensionsWeightSum(totalWeight);
      handlePushAddedCommodityInfo(totalWeight);
    } else if (inputName === 'NmbOfUnits') {
      values[index].NmbOfUnits = event.target.value;
    } else if (inputName === 'UnitType') {
      values[index].UnitTypeValue = event;
    } else if (inputName === 'Stackable') {
      values[index].StackAble = event;
    } else if (inputName === 'Length') {
      values[index].Length = event.target.value;
    } else if (inputName === 'Width') {
      values[index].Width = event.target.value;
    } else {
      values[index].Height = event.target.value;
    }
    setNewAddedDimensionsRow(values);
  };
  const handleAddNewRow = e => {
    const temp = [];
    if (e === 'class') {
      const values = [...newAddedClassRow];
      values.push({ WeightLBS: '', Class: ClassArray[0], NMFCnumber: '' });
      setNewAddedClassRow(values);
    } else {
      const values = [...newAddedDimensionsRow];
      values.push({
        WeightLBS: '',
        UnitTypeValue: UnitTypeArr[0],
        NmbOfUnits: '',
        StackAble: false,
        Length: '',
        Width: '',
        Height: ''
      });
      setNewAddedDimensionsRow(values);
    }
  };

  const handleSubtractNewRow = (e, removedIndex) => {
    if (e === 'class') {
      const values = [...newAddedClassRow];
      values.splice(removedIndex, 1);
      // finalCommodityInfoApi.splice(removedIndex, 1);
      setNewAddedClassRow(values);
      let totalWeightLBS = parseInt(newAddedClassRow[removedIndex].WeightLBS);
      let minus;
      if (isNaN(totalWeightLBS)) {
        minus = parseInt(totalClassWeightSum) - 0;
      } else {
        minus = parseInt(totalClassWeightSum) - totalWeightLBS;
      }
      setTotalClassWeightSum(minus);
      handlePushAddedCommodityInfo(minus);

      // setTotalDimensionsWeightSum(totalDimensionsWeightSum - totalWeightLBS);
      let TotalWeightSum = totalDimensionsWeightSum - totalWeightLBS;
    } else {
      const values = [...newAddedDimensionsRow];
      values.splice(removedIndex, 1);
      // finalCommodityInfoApi.splice(removedIndex, 1);
      setNewAddedDimensionsRow(values);
      let totalWeightLBS = parseInt(
        newAddedDimensionsRow[removedIndex].WeightLBS
      );
      let minus;
      if (isNaN(totalWeightLBS)) {
        minus = parseInt(totalDimensionsWeightSum) - 0;
      } else {
        minus = parseInt(totalDimensionsWeightSum) - totalWeightLBS;
      }
      // totalWeightLBS = totalLBSWeightDimensions - totalLBSWeightDimensions;
      // minus = totalDimensionsWeightSum - totalWeightLBS;
      setTotalDimensionsWeightSum(minus);
      handlePushAddedCommodityInfo(minus);
      setTotalLBSWeightDimensions(totalWeightLBS);
    }
  };
  useEffect(() => {
    handleCheckAddedRow();
  }, [newAddedClassRow]);

  const switchStyle = {
    borderRadius: 2,
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#d81b60'
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#d81b60'
    },
    '&:hover .MuiSwitch-switchBase': {
      // color: 'brown'
    }
  };

  function sleep(delay = 0) {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  }
  const [shipFromOpen, setShipFromOpen] = useState(false);
  // const [open, setOpen] = useState(false);
  const [shipToOpen, setShipToOpen] = useState(false);

  const [options, setOptions] = useState([]);
  const [shipFromFinalAddress, setShipFromFinalAddress] = useState(
    paymentTypeValue === 'Outbound Prepaid' ? shipLocationValue : ''
  );
  const [shipFromFinalValue, setShipFromFinalValue] = useState(
    !ShipFrom.includes('undefined') ? ShipFrom : ''
  );
  const [shipFromErr, setShipFromErr] = useState(false);
  const [shipToFinalAddress, setShipToFinalAddress] = useState('');
  const [shipToErr, setShipToErr] = useState(false);

  const ShipFromloading = shipFromOpen && options.length === 0;
  const ShipToLoading = shipToOpen && options.length === 0;
  const [locationKeys, setLocationKeys] = useState([]);
  // const history = useHistory()

  useEffect(() => {
    let active = true;

    if (!ShipFromloading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...shipCity]);
      }
    })();

    return () => {
      active = false;
    };
  }, [ShipFromloading]);

  useEffect(() => {
    if (!shipFromOpen) {
      setOptions([]);
    }
  }, [shipFromOpen]);

  useEffect(() => {
    let active = true;

    if (!ShipToLoading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...shipCity]);
      }
    })();

    return () => {
      active = false;
    };
  }, [ShipToLoading]);

  useEffect(() => {
    if (!shipToOpen) {
      setOptions([]);
    }
  }, [shipToOpen]);

  const onShipFromChange = (event, values) => {
    let FinalObj = {};
    if (values !== null) {
      setShipFromFinalValue(values.title);
      const tempData = values.title.split(' ');
      if (tempData.length === 3) {
        const updatedErrors = { ...validationErrors, shipFromErr: null };
        setValidationErrors(updatedErrors);
        const City = tempData[0];
        const State = tempData[1];
        const ZipCode = tempData[2];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      } else if (tempData.length === 5) {
        const City = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State = tempData[3];
        const ZipCode = tempData[4];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      } else {
        const City = tempData[0] + ' ' + tempData[1];
        const State = tempData[2];
        const ZipCode = tempData[3];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      }

      setShipFromFinalAddress(FinalObj);
    } else {
      setShipFromFinalAddress('');
      setShipFromFinalValue('');
    }
  };
  const onShipToChange = (event, values) => {
    let FinalObj2 = {};
    if (values !== null) {
      setShipFromErr(false);
      const tempData = values.title.split(' ');
      if (tempData.length === 3) {
        const updatedErrors = { ...validationErrors, shipToErr: null };
        setValidationErrors(updatedErrors);
        const City = tempData[0];
        const State = tempData[1];
        const ZipCode = tempData[2];
        FinalObj2 = {
          City,
          State,
          ZipCode
        };
        setShipToFinalAddress(FinalObj2);
      } else if (tempData.length === 5) {
        const City2 = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State2 = tempData[3];
        const ZipCode2 = tempData[4];
        FinalObj2 = {
          City2,
          State2,
          ZipCode2
        };
      } else {
        const City = tempData[0] + ' ' + tempData[1];
        const State = tempData[2];
        const ZipCode = tempData[3];
        FinalObj2 = {
          City,
          State,
          ZipCode
        };
        setShipToFinalAddress(FinalObj2);
      }
      // setShipToFinalAddress(FinalObj2);
    } else {
      setShipToFinalAddress('');
      setShipFromErr(true);
    }
  };
  const filterOptions = createFilterOptions({
    limit: 300
  });

  const validateAutocompletes = (obj, deepClone) => {
    const errors = {};
    if (!shipTypeValue) {
      errors['shipTypeErr'] = 'Please select ship type';
    } else if (!paymentTypeValue) {
      errors['paymentTypeErr'] = 'Please select payment type';
    } else if (!shipFromFinalAddress) {
      errors['shipFromErr'] = 'Please select ship from';
    } else if (!shipToFinalAddress) {
      errors['shipToErr'] = 'Please select ship to';
    }
    if (!newAddedClassRow[0].WeightLBS) {
      errors['weightLBS'] = 'Please enter weight';
    }
    if (!newAddedDimensionsRow[0].WeightLBS) {
      errors['newAddedDimensionsRow'] = 'Please enter weight';
    }
    if (!shipLocationValue) {
      errors['shipTypeLocation'] = 'Please select ship location';
    }
    // if (ShipFrom) {
    //   const updatedErrors = { ...validationErrors, shipToErr: null };
    //   setValidationErrors(updatedErrors);
    // }
    // add more validations for other Autocomplete components
    setValidationErrors(errors);

    if (
      shipTypeValue &&
      paymentTypeValue &&
      shipFromFinalAddress &&
      shipToFinalAddress &&
      (newAddedClassRow[0].WeightLBS || newAddedDimensionsRow[0].WeightLBS)
    ) {
      const CopyNewAddedClassRow = [...newAddedClassRow];

      dispatch(setQuickQuoteRateValues(obj));
      dispatch(setQuickQuotePickUpCheckedRedux(pickUpChecked));
      dispatch(setQuickQuoteDeliveryCheckedRedux(deliveryChecked));
      dispatch(setClassDimensionsButton(alignment));
      dispatch(setClassRows(newAddedClassRow));
      dispatch(setDimensionRows(newAddedDimensionsRow));
      dispatch(setClassWeight(totalClassWeightSum));
      dispatch(setDimensionsWeight(totalDimensionsWeightSum));
      history.push('/get-rates', {
        state: obj,
        deepClone,
        totalLBSWeight:
          alignment === 'Dimensions'
            ? totalDimensionsWeightSum
            : totalClassWeightSum,
        quote: 'quick-quote'
      });
    }
  };

  const handleChangePaymentType = (event, newValue) => {
    if (newValue === null) {
      setPaymentTypeValue(PaymentTypeArr[0]);
    }
    if (newValue === 'Third Party') {
      setShipFromFinalAddress('');
      setPaymentTypeValue(newValue);
    } else {
      setShipFromFinalAddress(shipLocationValue);
      setPaymentTypeValue(newValue);
    }
  };
  return (
    <div
      style={
        layoutMode === 'dark'
          ? {
              backgroundColor: '#303841',
              display: 'flex',
              justifyContent: 'center',
              // margin: '10 0 0 0',
              padding: '10 0 10 0',
              height: '100%'
            }
          : {
              display: 'flex',
              justifyContent: 'center',
              // margin: '10 0 0 0',
              padding: '10 0 10 0',
              height: '100%'
              // backgroundColor: '#EDF2F9'
            }
      }
    >
      <div>
        <Paper style={{ borderRadius: 20 }}>
          <Card sx={{ minWidth: 275 }}>
            <form>
              <CardContent>
                <div style={{ display: 'flex' }}>
                  <div lg={3} sm={3}>
                    <Autocomplete
                      renderOption={(props, option) => (
                        <Box style={{ fontSize: 13 }} {...props}>
                          {option}
                        </Box>
                      )}
                      size='small'
                      options={ShipTypeArr}
                      value={shipTypeValue}
                      onChange={(event, newValue) => {
                        newValue === null
                          ? setShipTypeValue(ShipTypeArr[0])
                          : setShipTypeValue(newValue);
                        setValidationErrors({
                          ...validationErrors,
                          shipTypeValue: null
                        });
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(validationErrors['shipTypeErr'])}
                          helperText={validationErrors['shipTypeErr']}
                          sx={{ width: 260 }}
                          placeholder='Ship type'
                          variant='standard'
                          InputProps={{
                            style: { fontSize: 12 },
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position='start'>
                                <MergeTypeIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </div>
                  <div lg={3} sm={3}>
                    <Autocomplete
                      renderOption={(props, option) => (
                        <Box style={{ fontSize: 13 }} {...props}>
                          {option}
                        </Box>
                      )}
                      size='small'
                      value={shipLocationValue}
                      options={shipLocation}
                      onChange={(event, newValue) => {
                        newValue === null
                          ? setShipLocationValue(shipLocationValue)
                          : setShipLocationValue(newValue);
                        paymentTypeValue === 'Outbound Prepaid' &&
                          setShipFromFinalAddress(newValue);
                        setValidationErrors({
                          ...validationErrors,
                          shipTypeLocation: null
                        });
                      }}
                      sx={{ marginLeft: 1 }}
                      renderInput={params => (
                        <TextField
                          // required={true}
                          error={Boolean(validationErrors['shipTypeLocation'])}
                          helperText={validationErrors['shipTypeLocation']}
                          sx={{ width: 260 }}
                          placeholder='Locations'
                          variant='standard'
                          {...params}
                          InputProps={{
                            style: { fontSize: 12 },
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position='start'>
                                <LocationIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Autocomplete
                      renderOption={(props, option) => (
                        <Box style={{ fontSize: 13 }} {...props}>
                          {option}
                        </Box>
                      )}
                      size='small'
                      options={PaymentTypeArr}
                      value={
                        // SelectedRates?.paymentType
                        //   ? SelectedRates?.paymentType
                        // :
                        paymentTypeValue
                      }
                      onChange={(event, newValue) =>
                        handleChangePaymentType(event, newValue)
                      }
                      sx={{ marginLeft: 1 }}
                      renderInput={params => (
                        <TextField
                          error={Boolean(validationErrors['paymentTypeErr'])}
                          helperText={validationErrors['paymentTypeErr']}
                          sx={{ width: 250 }}
                          placeholder='Payment Type'
                          variant='standard'
                          {...params}
                          InputProps={{
                            style: { fontSize: 12 },
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position='start'>
                                <PaymentIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20
                  }}
                >
                  <Label
                    style={{
                      margin: '1px 0px 1px 0px',
                      fontWeight: 700,
                      fontSize: 12
                    }}
                  >
                    Shipment Info
                  </Label>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <FormControl
                        sx={{ mr: 1, mt: 1, minWidth: 200 }}
                        size='large'
                      >
                        <Autocomplete
                          renderOption={(props, option) => (
                            <Box style={{ fontSize: 13 }} {...props}>
                              {option.title}
                            </Box>
                          )}
                          disabled={
                            paymentTypeValue === 'Outbound Prepaid'
                              ? true
                              : false
                          }
                          filterOptions={filterOptions}
                          size='small'
                          id='asynchronous-demo'
                          sx={{ width: 270 }}
                          open={shipFromOpen}
                          onOpen={() => {
                            setShipFromOpen(true);
                          }}
                          onClose={() => {
                            setShipFromOpen(false);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.title === value.title || option.State
                          }
                          getOptionLabel={option =>
                            option ? option.title : ''
                          }
                          options={shipCity}
                          // value={shipFromFinalAddress}
                          loading={ShipFromloading}
                          value={
                            paymentTypeValue === 'Outbound Prepaid'
                              ? { title: shipLocationValue }
                              : { title: shipFromFinalValue }
                          }
                          onChange={onShipFromChange}
                          renderInput={params => (
                            <TextField
                              // required={true}
                              {...params}
                              error={Boolean(validationErrors['shipFromErr'])}
                              helperText={validationErrors['shipFromErr']}
                              label='Ship From'
                              InputLabelProps={{ style: { fontSize: 12 } }}
                              InputProps={{
                                style: {
                                  fontSize: 12
                                },
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {ShipFromloading ? (
                                      <CircularProgress
                                        color='inherit'
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                    <div lg={3}>
                      <FormControl
                        sx={{ mr: 1, mt: 1, minWidth: 200 }}
                        size='large'
                      >
                        <Autocomplete
                          renderOption={(props, option) => (
                            <Box style={{ fontSize: 13 }} {...props}>
                              {option.title}
                            </Box>
                          )}
                          filterOptions={filterOptions}
                          defaultValue={{
                            title: ShipTo.includes('undefined') ? '' : ShipTo
                          }}
                          disableListWrap
                          size='small'
                          id='asynchronous-demo'
                          sx={{ width: 270 }}
                          open={shipToOpen}
                          onOpen={() => {
                            setShipToOpen(true);
                          }}
                          onClose={() => {
                            setShipToOpen(false);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.title === value.title || option.State
                          }
                          getOptionLabel={option => option.title}
                          options={shipCity}
                          loading={ShipToLoading}
                          onChange={onShipToChange}
                          renderInput={params => (
                            <TextField
                              // required={true}
                              {...params}
                              error={Boolean(validationErrors['shipToErr'])}
                              helperText={validationErrors['shipToErr']}
                              label='Ship To'
                              InputLabelProps={{ style: { fontSize: 12 } }}
                              InputProps={{
                                style: {
                                  fontSize: 12
                                },
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {ShipToLoading ? (
                                      <CircularProgress
                                        color='inherit'
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl sx={{ mt: 1, minWidth: 100 }} size='large'>
                        <TextField
                          onChange={e => setShipmentDate(e.target.value)}
                          size='small'
                          sx={{ width: 120, marginRight: 1 }}
                          id='outlined-password-input'
                          // label="Ship Date"
                          defaultValue={shipmentDate}
                          type='date'
                          // autoComplete='current-password'
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          inputProps={{
                            style: {
                              height: 15,
                              fontSize: 12
                            }
                          }}
                        />
                      </FormControl>
                    </div>
                    <div>
                      {/* <FormControl sx={{ m: 0, minWidth: 30 }} size='large'> */}
                      <div
                        style={{ margin: '10 10 0 0' }}
                        className={classes.switchRoot}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={hazmatSwitch}
                              value={hazmatSwitch}
                              sx={switchStyle}
                              onChange={() =>
                                handlePickUpServicesChange('hazmat')
                              }
                              name='hazmat'
                            />
                          }
                          label={
                            <Typography
                              style={{
                                // margin: '0 0 0 -20',
                                fontSize: 12,
                                // whiteSpace: 'nowrap',
                                fontWeight: 700
                              }}
                            >
                              Hazmat
                            </Typography>
                          }
                        />
                      </div>
                      {/* </FormControl> */}
                    </div>
                  </div>
                </div>
                <div>
                  <Label
                    style={{
                      margin: '20px 0px 0px 0px',
                      fontWeight: 700,
                      fontSize: 12
                    }}
                  >
                    Commodity Info
                  </Label>
                  <Col lg={12}>
                    <ToggleButtonGroup
                      style={{
                        outlineColor: '#1f86ef'
                      }}
                      sx={{ mt: 1, minWidth: 200 }}
                      color='primary'
                      value={alignment}
                      exclusive
                      onChange={handleChangeToggle}
                      aria-label='Platform'
                    >
                      <ToggleButton
                        style={{
                          textTransform: 'capitalize',
                          backgroundColor:
                            alignment === 'Class' ? '#1f86ef' : 'white',
                          color: alignment === 'Class' ? 'white' : 'black',
                          fontWeight: 700,
                          fontSize: 12,
                          width: 150,
                          height: 30
                        }}
                        value='Class'
                      >
                        Class
                      </ToggleButton>
                      <ToggleButton
                        style={{
                          textTransform: 'capitalize',
                          backgroundColor:
                            alignment === 'Dimensions' ? '#1f86ef' : 'white',
                          color: alignment === 'Dimensions' ? 'white' : 'black',
                          fontWeight: 700,
                          fontSize: 12,
                          width: 150,
                          height: 30
                        }}
                        value='Dimensions'
                      >
                        Dimensions
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Col>
                </div>
                {alignment === 'Class' ? (
                  <>
                    {newAddedClassRow.map((item, index, arr) => (
                      <Row lg={12}>
                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 180 }} size='large'>
                            <Label
                              style={{
                                margin: '1px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Weight (lbs)
                            </Label>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              {index + 1 === arr.length && arr.length > 1 ? (
                                <RemoveCircleIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={e =>
                                    handleSubtractNewRow('class', index)
                                  }
                                />
                              ) : (
                                <AddCircleSharpIcon
                                  style={{
                                    cursor: 'pointer'
                                    // position: ""
                                  }}
                                  onClick={e => handleAddNewRow('class')}
                                />
                              )}

                              <TextField
                                error={Boolean(validationErrors['weightLBS'])}
                                helperText={validationErrors['weightLBS']}
                                name='WeightLBS'
                                size='small'
                                sx={{ width: 220 }}
                                id='outlined-password-input'
                                type='number'
                                autoComplete='current-password'
                                value={item.WeightLBS}
                                onChange={event => {
                                  handleClassInputChange(
                                    'WeightLBS',
                                    index,
                                    event
                                  );
                                  // setValidationErrors({
                                  //   ...validationErrors,
                                  //   weightLBS: event.target.value
                                  // });
                                }}
                                inputProps={
                                  ({ min: 0 },
                                  {
                                    style: {
                                      height: 15,
                                      fontSize: 12
                                    }
                                  })
                                }
                              />
                            </div>
                          </FormControl>
                        </Col>
                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 180 }} size='large'>
                            <Label
                              style={{
                                margin: '1px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Class
                            </Label>
                            <Autocomplete
                              renderOption={(props, option) => (
                                <Box style={{ fontSize: 13 }} {...props}>
                                  {option}
                                </Box>
                              )}
                              name='Class'
                              size='small'
                              options={ClassArray}
                              value={item.Class}
                              onChange={(event, newValue) =>
                                handleClassInputChange('Class', index, newValue)
                              }
                              // sx={{ marginLeft: 1 }}
                              renderInput={params => (
                                <TextField
                                  sx={{ width: 250 }}
                                  // placeholder='Payment Type'
                                  variant='outlined'
                                  {...params}
                                  InputProps={{
                                    style: { fontSize: 12, height: 32 },
                                    ...params.InputProps
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Col>
                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 180 }} size='large'>
                            <Label
                              style={{
                                margin: '1px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              NMFC
                            </Label>
                            <TextField
                              name='NMFCnumber'
                              value={item.NMFCnumber}
                              onChange={event =>
                                handleClassInputChange(
                                  'NMFCnumber',
                                  index,
                                  event
                                )
                              }
                              size='small'
                              id='outlined-password-input'
                              type='number'
                              autoComplete='current-password'
                              // value={'149980-02'}
                              sx={{ width: 250 }}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={
                                ({ min: 0 },
                                {
                                  style: {
                                    height: 15,
                                    fontSize: 12
                                  }
                                })
                              }
                            />
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: '#1976d2',
                                margin: '8 0 0 40'
                              }}
                            >
                              <u>
                                {index + 1 === arr.length && (
                                  <span>
                                    Total Shipment Weight{' '}
                                    {totalClassWeightSum > 0 &&
                                      totalClassWeightSum}{' '}
                                    (lbs)
                                  </span>
                                )}
                              </u>
                            </Typography>
                          </FormControl>
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : (
                  <>
                    {newAddedDimensionsRow.map((items, index, arr) => (
                      <Row lg={12}>
                        <Col lg={4}>
                          <FormControl
                            sx={{ minWidth: 100, width: 120 }}
                            size='large'
                          >
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Weight (lbs)
                            </Label>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 10
                              }}
                            >
                              {index + 1 === arr.length && arr.length > 1 ? (
                                <RemoveCircleIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={e =>
                                    handleSubtractNewRow('dimensions', index)
                                  }
                                />
                              ) : (
                                <AddCircleSharpIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={e => handleAddNewRow('dimensions')}
                                />
                              )}
                              <TextField
                                error={Boolean(
                                  validationErrors['newAddedDimensionsRow']
                                )}
                                helperText={
                                  validationErrors['newAddedDimensionsRow']
                                }
                                FormHelperTextProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    margin: '0 0 0 0'
                                  }
                                }}
                                value={
                                  items.WeightLBS === 0 ? '' : items.WeightLBS
                                }
                                onChange={e =>
                                  handleDimensionsInputChange(
                                    'WeightLBS',
                                    index,
                                    e
                                  )
                                }
                                size='small'
                                sx={{ width: 100 }}
                                id='outlined-password-input'
                                type='number'
                                autoComplete='current-password'
                                InputLabelProps={{ style: { fontSize: 12 } }}
                                inputProps={
                                  ({ min: 0 },
                                  {
                                    style: {
                                      height: 15,
                                      fontSize: 12
                                    }
                                  })
                                }
                              />
                            </div>
                          </FormControl>
                          <FormControl sx={{ minWidth: 110 }} size='large'>
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Unit Type
                            </Label>
                            <Autocomplete
                              renderOption={(props, option) => (
                                <Box style={{ fontSize: 13 }} {...props}>
                                  {option}
                                </Box>
                              )}
                              // sx={{ m: 1 }}
                              size='small'
                              options={UnitTypeArr}
                              value={items.UnitTypeValue}
                              onChange={(event, newValue) => {
                                handleDimensionsInputChange(
                                  'UnitType',
                                  index,
                                  newValue
                                );
                              }}
                              // sx={{ marginLeft: 1 }}
                              renderInput={params => (
                                <TextField
                                  // type='number'
                                  sx={{ width: 120 }}
                                  variant='outlined'
                                  {...params}
                                  InputProps={{
                                    style: { fontSize: 12 },
                                    ...params.InputProps
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Col>

                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 100 }} size='large'>
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              # of Units
                            </Label>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 10
                              }}
                            >
                              <TextField
                                value={items.NmbOfUnits}
                                onChange={e =>
                                  handleDimensionsInputChange(
                                    'NmbOfUnits',
                                    index,
                                    e
                                  )
                                }
                                size='small'
                                sx={{ width: 120 }}
                                id='outlined-password-input'
                                type='number'
                                autoComplete='current-password'
                                InputLabelProps={{ style: { fontSize: 12 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12
                                  }
                                }}
                              />
                              <div
                                style={{ margin: '0 0 0 5' }}
                                className={classes.switchRoot}
                              >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={items.StackAble}
                                      value={items.StackAble}
                                      onChange={(e, c) =>
                                        // handlePickUpServicesChange('Stackable')
                                        handleDimensionsInputChange(
                                          'Stackable',
                                          index,
                                          c
                                        )
                                      }
                                      color='primary'
                                      name='hazmat'
                                    />
                                  }
                                  //   label='Stackable'
                                />
                                <Typography
                                  style={{
                                    margin: '0 0 0 -20',
                                    fontSize: 12,
                                    // whiteSpace: 'nowrap',
                                    fontWeight: 700
                                  }}
                                >
                                  Stackable
                                </Typography>
                              </div>
                            </div>
                          </FormControl>
                        </Col>
                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 100 }} size='large'>
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Dimensions
                            </Label>
                            <div style={{ display: 'flex' }}>
                              <TextField
                                value={items.Length}
                                onChange={e =>
                                  handleDimensionsInputChange(
                                    'Length',
                                    index,
                                    e
                                  )
                                }
                                type='number'
                                className='inputRounded_Length'
                                size='small'
                                sx={{ width: 90 }}
                                id='outlined-password-input'
                                label='Length (cm)'
                                // autoComplete='current-password'
                                // value={'L'}
                                InputLabelProps={{ style: { fontSize: 11 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12,
                                    textAlign: 'center'
                                  }
                                }}
                              />
                              <TextField
                                value={items.Width}
                                onChange={e =>
                                  handleDimensionsInputChange('Width', index, e)
                                }
                                type='number'
                                className='inputRounded_Width'
                                size='small'
                                sx={{ width: 80 }}
                                id='outlined-password-input'
                                label='Width (cm)'
                                autoComplete='current-password'
                                // value={'W'}
                                InputLabelProps={{ style: { fontSize: 11 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12,
                                    textAlign: 'center'
                                  }
                                }}
                              />
                              <TextField
                                value={items.Height}
                                onChange={e =>
                                  handleDimensionsInputChange(
                                    'Height',
                                    index,
                                    e
                                  )
                                }
                                type='number'
                                className='inputRounded_Height'
                                size='small'
                                sx={{ width: 80 }}
                                id='outlined-password-input'
                                label='Height (cm)'
                                autoComplete='current-password'
                                // value={'H'}
                                InputLabelProps={{ style: { fontSize: 11 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12,
                                    textAlign: 'center'
                                  }
                                }}
                              />
                            </div>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: '#1976d2',
                                margin: '8 0 0 40'
                              }}
                            >
                              <u>
                                {index + 1 === arr.length && (
                                  <span>
                                    Total Shipment Weight{' '}
                                    {totalDimensionsWeightSum > 0 &&
                                      totalDimensionsWeightSum}{' '}
                                    (lbs)
                                  </span>
                                )}
                              </u>
                            </Typography>
                          </FormControl>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
                <Row>
                  <Col lg={3}>
                    <div
                      style={{ margin: '10 90 0 0' }}
                      className={classes.switchRoot}
                    >
                      <Typography
                        onClick={e => handlePickupServices(e)}
                        style={{
                          margin: '0 10 0 4',
                          fontSize: 12,
                          whiteSpace: 'nowrap',
                          fontWeight: 700
                        }}
                      >
                        Pickup Services
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={e => handlePickupServices(e)}
                            checked={pickUpChecked}
                            color='primary'
                            name='Pickup Services'
                          />
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={3}>
                    <div
                      style={{ margin: '10 90 0 0' }}
                      className={classes.switchRoot}
                    >
                      <Typography
                        onClick={() => handleDeliveryServices()}
                        style={{
                          margin: '0 10 0 4',
                          fontSize: 12,
                          whiteSpace: 'nowrap',
                          fontWeight: 700
                        }}
                      >
                        Delivery Services
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={() => handleDeliveryServices()}
                            checked={deliveryChecked}
                            color='primary'
                            name='Delivery Services'
                          />
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {(pickUpChecked || deliveryChecked) && (
                  <>
                    <Row>
                      {
                        <>
                          {pickUpChecked && (
                            <Col lg={3}>
                              <FormControl
                                sx={{ m: 0, minWidth: 230 }}
                                size='large'
                              >
                                <div
                                  style={{ margin: '10 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Inside Pickup'
                                          )
                                        }
                                        checked={insidePickupSwitch}
                                        color='primary'
                                        name='Pickup Services'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Inside Pickup'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Inside Pickup
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Inside Lift Gate Services'
                                          )
                                        }
                                        checked={liftgatePickupSwitch}
                                        color='primary'
                                        name='Inside Lift Gate Services'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Inside Lift Gate Services'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Lift Gate Services
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Residential Pickup'
                                          )
                                        }
                                        checked={residentialPickupSwitch}
                                        color='primary'
                                        name='Residential Pickup'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Residential Pickup'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Residential Pickup
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Secure Access'
                                          )
                                        }
                                        checked={secureAccesPickupSwitch}
                                        color='primary'
                                        name='Secure Access'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Secure Access'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Secure Access
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Limited Access'
                                          )
                                        }
                                        checked={limitedPickupAccessSwitch}
                                        color='primary'
                                        name='Limited Access'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Limited Access'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Limited Access
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Freeze Protection'
                                          )
                                        }
                                        checked={freezeProtectionPickupSwitch}
                                        color='primary'
                                        name='Freeze Protection'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Freeze Protection'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Freeze Protection
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Overlength'
                                          )
                                        }
                                        checked={overlengthPickupSwitch}
                                        color='primary'
                                        name='Overlength'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange('Overlength')
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Overlength
                                  </Typography>
                                </div>
                                {isOverLength && (
                                  <FormControl
                                    sx={{ minWidth: 120, mt: 1 }}
                                    size='large'
                                  >
                                    <TextField
                                      value={overLengthValue}
                                      onChange={e =>
                                        setOverLengthValue(e.target.value)
                                      }
                                      size='small'
                                      label='Overlength Value (Inch)'
                                      placeholder='Overlength Value (Inch)'
                                      type='number'
                                      sx={{ width: 170 }}
                                      InputLabelProps={{
                                        style: { fontSize: 14 }
                                      }}
                                      inputProps={
                                        ({ min: 0 },
                                        {
                                          style: {
                                            height: 15,
                                            fontSize: 12
                                          }
                                        })
                                      }
                                    />
                                  </FormControl>
                                )}
                              </FormControl>
                            </Col>
                          )}
                          {deliveryChecked && (
                            <Col lg={3}>
                              <FormControl
                                sx={{
                                  m: !pickUpChecked ? '0 202' : '0 0 0 0',
                                  minWidth: 230
                                }}
                                size='large'
                              >
                                <div
                                  style={{ margin: '10 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Inside Delivery'
                                          )
                                        }
                                        checked={insideDeliverySwitch}
                                        color='primary'
                                        name='Delivery Services'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Inside Delivery'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Inside Delivery
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Liftgate Delivery'
                                          )
                                        }
                                        checked={liftgateDeliverySwitch}
                                        color='primary'
                                        name='Liftgate Delivery'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Liftgate Delivery'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Lift Gate Services
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Residential Delivery'
                                          )
                                        }
                                        checked={residentialDeliverySwitch}
                                        color='primary'
                                        name='Residential Delivery'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Residential Delivery'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Residential Delivery
                                  </Typography>
                                </div>

                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Secure Access'
                                          )
                                        }
                                        checked={secureAccesDeliverySwitch}
                                        color='primary'
                                        name='Secure Access'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Secure Access'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Secure Access
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Limited Access'
                                          )
                                        }
                                        checked={limitedDeliverySwitch}
                                        color='primary'
                                        name='Limited Access'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Limited Access'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Limited Access
                                  </Typography>
                                </div>

                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Call Before Delivery'
                                          )
                                        }
                                        checked={
                                          callBeforeDeliveryServicesSwitch
                                        }
                                        color='primary'
                                        name='Call Before Delivery'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Call Before Delivery'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Call Before Delivery
                                  </Typography>
                                </div>
                              </FormControl>
                            </Col>
                          )}
                        </>
                      }
                    </Row>
                    <Row>
                      <Label
                        style={{
                          margin: '15px 0px 10px 0px',
                          fontWeight: 700,
                          fontSize: 12
                        }}
                      >
                        Additional Fee May Apply
                      </Label>

                      <Col lg={5}>
                        <FormControl sx={{ m: 0, minWidth: 200 }} size='small'>
                          <Label
                            style={{
                              margin: '10px 0px 0px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            C.O.D Amounts($)
                          </Label>
                          <TextField
                            value={CODAmount === 0 ? '' : CODAmount}
                            onChange={e => setCODAmount(e.target.value)}
                            type='number'
                            size='small'
                            sx={{ width: 270 }}
                            id='outlined-password-input'
                            autoComplete='current-password'
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{
                              style: {
                                height: 15,
                                fontSize: 12
                              }
                            }}
                          />
                        </FormControl>
                      </Col>
                      <Col lg={5}>
                        <FormControl sx={{ m: 0, minWidth: 200 }} size='small'>
                          <Label
                            style={{
                              margin: '10px 0px 0px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            Declared Value($)
                          </Label>
                          <TextField
                            value={DeclaredValue}
                            onChange={e => setDeclaredValue(e.target.value)}
                            type='number'
                            size='small'
                            sx={{ width: 270 }}
                            id='outlined-password-input'
                            // label='Ship To'
                            autoComplete='current-password'
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{
                              style: {
                                height: 15,
                                fontSize: 12
                              }
                            }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // alignContent: 'center',
                    marginTop: 20
                  }}
                >
                  <Button
                    type='submit'
                    // disabled={
                    //   !shipFromFinalAddress ||
                    //   !shipToFinalAddress ||
                    //   !shipTypeValue ||
                    //   !shipLocationValue ||
                    //   !paymentTypeValue
                    //     ? true
                    //     : false
                    // }
                    onClick={e => handleGetRates(e)}
                    color='Blue'
                    size='small'
                  >
                    {getRateLoading ? 'Loading ...' : 'Get Rates'}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </Paper>
      </div>
      {/* )} */}
    </div>
  );
}

export default QuickQuote;
