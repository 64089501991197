import { createSlice } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

export const getCarrierRatesSlice = createSlice({
  name: 'getCarrierRates',
  initialState: {
    RateValues: {}
  },
  reducers: {
    setRateValues: (state, action) => {
      console.log(state, action, 'state, action');
      return action.payload;
    }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const { setRateValues } = getCarrierRatesSlice.actions;
