import { createSlice } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

export const classDimensionsBtn = createSlice({
  name: 'classDimensionsBtn',
  initialState: {
    ClassDimensionsButton: ''
  },
  reducers: {
    setClassDimensionsButton: (state, action) => {
      console.log(state, action, 'state, action');
      return action.payload;
    }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const { setClassDimensionsButton } = classDimensionsBtn.actions;
