import { createSlice } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

export const newAddedPersistedDimensionsRows = createSlice({
  name: 'DimensionsRows',
  initialState: {
    DimensionRows: []
  },
  reducers: {
    setDimensionRows: (state, action) => {
      return JSON.parse(JSON.stringify(action.payload));
    }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const { setDimensionRows } = newAddedPersistedDimensionsRows.actions;
