import React, { useEffect, useRef } from 'react';
import {
  FormControl,
  TextField,
  Button,
  Skeleton,
  Snackbar,
  Alert,
  AlertTitle,
  ButtonBase,
  Autocomplete,
  createFilterOptions,
  CircularProgress
} from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import ReplayIcon from '@mui/icons-material/Replay';
import './GetRates.css';
import { Badge } from 'reactstrap';

import GuaranteeIcon from '../../assets/gurantee.gif';
import PinDropIcon from '@mui/icons-material/PinDrop';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { userLoginDetailsSlice } from '../../Redux/UserSlice';
import ReactStars from 'react-rating-stars-component';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setEstesQuoteRate,
  setFedExRates,
  setSAIAQuoteRate,
  setXPOQuoteRate
} from '../../Redux/GetRatesSlice';
import MuiAlert from '@mui/material/Alert';
import FedExLogo from '../../assets/carrier logos/fedex.png';
import FedExPriLogo from '../../assets/carrier logos/fedex-priority.png';
import ABFLogo from '../../assets/carrier logos/ABFS.png';
import EXLALogo from '../../assets/carrier logos/EXLA.png';
import PITOHIO from '../../assets/carrier logos/PITD.png';
import CTLogo from '../../assets/carrier logos/central-transport.png';
import PYLELogo from '../../assets/carrier logos/PYLE.png';
import YRCLogo from '../../assets/carrier logos/RDWY.png';
import RLLogo from '../../assets/carrier logos/RLcarriers.png';
import WardLogo from '../../assets/carrier logos/WARD.png';
import AACTLogo from '../../assets/carrier logos/AACT.png';
import UPSLogo from '../../assets/carrier logos/Tfroce freight.png';
import ODFLlogo from '../../assets/carrier logos/ODFL.png';
import SEFLlogo from '../../assets/carrier logos/Southeastern freight lines.png';
// import ODFLlogo from '../../assets/carrier logos/ODFL.png';
// import ODFLlogo from '../../assets/carrier logos/ODFL.png';
// import ODFLlogo from '../../assets/carrier logos/ODFL.png';
import NEWPENLogo from '../../assets/carrier logos/New Penn.png';
import SAIALogo from '../../assets/carrier logos/SAIA.png';
import CNWYLogo from '../../assets/carrier logos/CNWY.png';
import ReddawayLogo from '../../assets/carrier logos/Reddaway.png';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { setShipmentDetails } from '../../Redux/ViewShipmentsSlice';
import { Box } from '@mui/system';
import { Typography } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';
import USALocations from '../../apis/USALocations.json';
import PickupShipmentDialog from '../CreateAgency/CreateAgency.js';
import { ToastContainer } from 'react-toastify';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

function ShipmentTabPanel({
  tabName,
  GetRatesArray,
  EstesApiLoading,
  OriginLocation,
  DestinationLocation,
  OrderWeight,
  value,
  errorMessageApi,
  quote,
  fetchData,
  obj,
  SelectedRowData,
  handleListItemClick,
  selectedIndex
}) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');
  const [selectedCarrier, setSelectedCarrier] = useState({});

  const handleClickOpen = (e, Carrier) => {
    console.log(SelectedRowData, Carrier, ':Carrier');
    setSelectedCarrier(Carrier);
    setOpen(true);
    setScroll('paper');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const options = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  // const [EstesApiLoading, setEstesApiLoading] = useState(false);
  const [defaultOriginLoc, setDefaultOriginLoc] = useState(OriginLocation);
  const [defaultDestinationLoc, setDefaultDestinationLoc] =
    useState(DestinationLocation);

  const FedExApiRes = useSelector(state => state.getCarrierRates);
  const LoadingApiRates = useSelector(state => state.carrierRatesLoading);
  const [shipFromOpen, setShipFromOpen] = useState(false);
  const [shipToOpen, setShipToOpen] = useState(false);
  const [shipCity, setShipCity] = useState([]);
  const dispatch = useDispatch();
  const [LoadingItem, setLoadingItem] = useState();
  const [ErrMsgArray, setErrMsgArray] = useState();

  // useEffect(() => () => handleUnmount(), []);
  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const [carrierRating, setCarrierRating] = useState(3);

  // const EstesApiLoading = EstesApiLoading;

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  let TempOrigin = OriginLocation.split(' ');
  let FinalOrigin = {};

  const City = TempOrigin[1];
  const State = TempOrigin[2];
  const ZipCode = TempOrigin[0];
  FinalOrigin = {
    City,
    State,
    ZipCode
  };

  let TempDestination = DestinationLocation.split(' ');
  let FinalDestination = {};

  const City2 = TempDestination[1];
  const State2 = TempDestination[2];
  const ZipCode2 = TempDestination[0];
  FinalDestination = {
    City2,
    State2,
    ZipCode2
  };
  const [shipFromFinalAddress, setShipFromFinalAddress] = useState(FinalOrigin);
  const ShipFromloading = shipFromOpen && options.length === 0;
  const ShipToLoading = shipToOpen && options.length === 0;
  const [validationErrors, setValidationErrors] = useState({});
  const [shipFromErr, setShipFromErr] = useState(false);
  const [shipToFinalAddress, setShipToFinalAddress] =
    useState(FinalDestination);
  const [shipToErr, setShipToErr] = useState(false);
  const [requoteWeight, setRequoteWeight] = useState(OrderWeight);

  const handleUSAddresses = () => {
    let tempCity = [];
    let tempState = [];
    let tempZipCode = [];
    for (let index = 0; index < USALocations.length; index++) {
      const City = USALocations[index].city;
      const State = USALocations[index].state;
      const ZipCode = USALocations[index].zip_code;

      tempCity.push({ title: City + ' ' + State + ' ' + ZipCode });
      // tempState.push(State);
      // tempZipCode.push(ZipCode);
    }
    setShipCity(tempCity);
    // setShipState(tempState);
    // setShipZip(tempZipCode);
  };
  useEffect(() => {
    handleUSAddresses();
  }, []);
  // useEffect(() => {
  //   if (GetRatesArray?.length > 1) {
  //     setEstesApiLoading(false);
  //   } else {
  //     setEstesApiLoading(true);
  //   }
  // }, []);

  // const handleClose = () => {
  //   setState({ ...state, open: false });
  // };
  const LoadingArray = [
    { item: 1 },
    { item: 1 },
    { item: 1 },
    { item: 1 },
    { item: 1 }
  ];
  const ErrorMessageApiHanlder = () => {
    var ErrMsgArray = [
      { title: 'CTQuoteRate', msg: '', logo: CTLogo },
      { title: 'FedEx', msg: '', logo: FedExLogo },
      { title: 'XPO', msg: '', logo: CNWYLogo },
      { title: 'Estes', msg: '', logo: EXLALogo },
      { title: 'SAIA', msg: '', logo: SAIALogo },
      { title: 'ABF', msg: '', logo: ABFLogo },
      { title: 'PittOhio', msg: '', logo: PITOHIO },
      { title: 'Sefl', msg: '', logo: SEFLlogo },
      { title: 'NewPenn', msg: '', logo: NEWPENLogo },
      { title: 'ODFL', msg: '', logo: ODFLlogo },
      { title: 'UPS', msg: '', logo: UPSLogo },
      {
        title: 'YRC',
        msg: '',
        logo: YRCLogo
      },
      { title: 'Reddaway', msg: '', logo: ReddawayLogo },
      { title: 'Ward', msg: '', logo: WardLogo },
      { title: 'RL', msg: '', logo: RLLogo },
      { title: 'AduiePyle', msg: '', logo: PYLELogo },
      { title: 'AAACooper', msg: '', logo: AACTLogo }
    ];
    const FinalErrArray = [];
    for (let i = 0; i < errorMessageApi.length; i++) {
      const errorApi = errorMessageApi[i];
      const ErrName = errorApi?.URL?.split('/');
      const LastName = ErrName[ErrName?.length - 1];
      FinalErrArray.push({
        // title: LastName,
        msg:
          errorApi.message.length > 2 ? errorApi.message : 'Server Side Error!',
        logo: ErrMsgArray.find(items =>
          LastName.includes(items.title) ? items.logo : null
        )
      });
    }
    setErrMsgArray(FinalErrArray);
  };
  useEffect(() => {
    ErrorMessageApiHanlder();
  }, []);
  useEffect(() => () => setState({ open: false }), []);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={8} ref={ref} variant='filled' {...props} />;
  });

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 1100,
      // width: 1900,
      // margin: '0 10 0 10 ',
      fontSize: theme.typography.pxToRem(12)
      // border: '1px solid #dadde9'
    }
  }));

  const RateErrorTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ce7a7a',
      color: 'white',
      maxWidth: 800,
      // width: 1900,
      // margin: '0 10 0 10 ',
      fontSize: theme.typography.pxToRem(12)
      // border: '1px solid #dadde9'
    },
    [`& .${tooltipClasses.arrow}`]: {
      // backgroundColor: '#f5f5f9',
      color: '#ce7a7a',
      maxWidth: 1100,
      // width: 1900,
      // margin: '0 10 0 10 ',
      fontSize: theme.typography.pxToRem(12)
      // border: '1px solid #dadde9'
    }
  }));
  const handleBookShipment = () => {
    history.push('/bookshipment-review');
  };
  const onShipFromChange = (event, values) => {
    let FinalObj = {};
    if (values !== null) {
      setDefaultOriginLoc(values.title);
      const tempData = values.title.split(' ');
      if (tempData.length === 3) {
        const updatedErrors = { ...validationErrors, shipFromErr: null };
        setValidationErrors(updatedErrors);
        const City = tempData[0];
        const State = tempData[1];
        const ZipCode = tempData[2];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      } else if (tempData.length === 5) {
        const City = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State = tempData[3];
        const ZipCode = tempData[4];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      } else {
        const City = tempData[0] + ' ' + tempData[1];
        const State = tempData[2];
        const ZipCode = tempData[3];
        FinalObj = {
          City,
          State,
          ZipCode
        };
      }

      setShipFromFinalAddress(FinalObj);
    } else {
      setShipFromFinalAddress('');
    }
  };

  const onShipToChange = (event, values) => {
    let FinalObj2 = {};
    if (values !== null) {
      setShipFromErr(false);
      const tempData = values.title.split(' ');
      if (tempData.length === 3) {
        const updatedErrors = { ...validationErrors, shipToErr: null };
        setValidationErrors(updatedErrors);
        const City2 = tempData[0];
        const State2 = tempData[1];
        const ZipCode2 = tempData[2];
        FinalObj2 = {
          City2,
          State2,
          ZipCode2
        };
        setShipToFinalAddress(FinalObj2);
      } else if (tempData.length === 5) {
        const City2 = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State2 = tempData[3];
        const ZipCode2 = tempData[4];
        FinalObj2 = {
          City2,
          State2,
          ZipCode2
        };
      } else {
        const City2 = tempData[0] + ' ' + tempData[1];
        const State2 = tempData[2];
        const ZipCode2 = tempData[3];
        FinalObj2 = {
          City2,
          State2,
          ZipCode2
        };
        setShipToFinalAddress(FinalObj2);
      }
      // setShipToFinalAddress(FinalObj2);
    } else {
      setShipToFinalAddress('');
      setShipFromErr(true);
    }
  };
  const filterOptions = createFilterOptions({
    limit: 300
  });

  const handleRequote = () => {
    const regex = /^[a-zA-Z]*$/;
    const reg = /^-?\d+\.?\d*$/;
    let RequoteObj = JSON.parse(JSON.stringify(obj));
    let DeepCloneRequoteObj = JSON.parse(JSON.stringify(obj));
    // let newObj = { ...obj, };

    if (
      regex.test(shipFromFinalAddress.City) &&
      shipFromFinalAddress.City.length > 3
    ) {
      RequoteObj.shipFrom.city = shipFromFinalAddress.City;
      DeepCloneRequoteObj.shipFrom.city = shipFromFinalAddress.City;
    }
    if (shipFromFinalAddress.State.length === 2) {
      RequoteObj.shipFrom.stateOrProvinceCode = shipFromFinalAddress.State;
      DeepCloneRequoteObj.shipFrom.stateOrProvinceCode =
        shipFromFinalAddress.State;
    }
    if (
      reg.test(shipFromFinalAddress.ZipCode) &&
      shipFromFinalAddress.ZipCode.length >= 3
    ) {
      RequoteObj.shipFrom.postalCode = shipFromFinalAddress.ZipCode;
      DeepCloneRequoteObj.shipFrom.postalCode = shipFromFinalAddress.ZipCode;
    }
    if (
      regex.test(shipToFinalAddress.City2) &&
      shipToFinalAddress.City2.length > 3
    ) {
      RequoteObj.shipTo.city = shipToFinalAddress.City2;
      DeepCloneRequoteObj.shipTo.city = shipToFinalAddress.City2;
    }
    if (shipToFinalAddress.State2.length === 2) {
      RequoteObj.shipTo.stateOrProvinceCode = shipToFinalAddress.State2;
      DeepCloneRequoteObj.shipTo.stateOrProvinceCode =
        shipToFinalAddress.State2;
    }
    if (
      reg.test(shipToFinalAddress.ZipCode2) &&
      shipToFinalAddress.ZipCode2.length >= 3
    ) {
      RequoteObj.shipTo.postalCode = shipToFinalAddress.ZipCode2;
      DeepCloneRequoteObj.shipTo.postalCode = shipToFinalAddress.ZipCode2;
    }

    RequoteObj.commodityInfo[0].weight.value = requoteWeight;
    DeepCloneRequoteObj.commodityInfo[0].weight.value = requoteWeight;
    DeepCloneRequoteObj.shipFrom.countryCode = 'US';
    DeepCloneRequoteObj.shipTo.countryCode = 'US';

    fetchData(RequoteObj, DeepCloneRequoteObj);

    // window.location.reload();
  };

  // const handleListItemClick = (event, index) => {
  //   setSelectedIndex(index);
  // };
  const handleClick = e => {
    e.preventDefault();
    // Perform your desired action on item click
    // console.log('Item clicked:', item);
  };
  return (
    <div>
      <ToastContainer
        position='top-center'
        autoClose={5800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className='scroll' style={{ height: '50vh' }}>
        {EstesApiLoading
          ? LoadingArray.map(items => (
              <div className='Carrier_div'>
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={
                      EstesApiLoading
                        ? {
                            width: 60,
                            fontSize: 12,
                            fontWeight: 700,
                            position: 'absolute',
                            margin: '-90 0 0 20',
                            backgroundColor: 'white',
                            whiteSpace: 'nowrap'
                          }
                        : {
                            width: 'auto',
                            fontSize: 12,
                            fontWeight: 700,
                            position: 'absolute',
                            margin: '-90 0 0 20',
                            backgroundColor: 'white',
                            whiteSpace: 'nowrap'
                          }
                    }
                  >
                    <Box>
                      <Skeleton />
                    </Box>
                  </div>
                  <Col lg={3}>
                    <Box
                      sx={{
                        height: 60,
                        margin: '20 0 0 17'
                      }}
                    >
                      <Skeleton variant='circular' width={40} height={40} />
                    </Box>
                  </Col>
                  <Col lg={2}>
                    <div style={{ cursor: 'pointer' }}>
                      <HtmlTooltip>
                        <h2>
                          <Box sx={{ width: 120 }}>
                            <Skeleton />
                          </Box>
                        </h2>
                      </HtmlTooltip>

                      <span
                        style={{
                          width: 'auto',
                          fontSize: 10,
                          fontWeight: 700,
                          position: 'absolute',
                          margin: '-14 0 0 0',
                          cursor: 'auto'
                        }}
                      >
                        <Box sx={{ width: 100, marginTop: 0.5 }}>
                          <Skeleton />
                        </Box>

                        <HtmlTooltip placement='top'>
                          <span>
                            {/* <PinDropIcon
                          style={{
                            width: 18,
                            cursor: 'pointer'
                          }}
                        /> */}
                          </span>
                        </HtmlTooltip>
                      </span>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div
                      style={{
                        display: 'flex',
                        marginTop: 15
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 700
                          }}
                        >
                          {value === 1 ? (
                            'DELIVERY DATE'
                          ) : (
                            <Box sx={{ width: 110 }}>
                              <Skeleton />
                            </Box>
                          )}
                          <br />
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: 'normal'
                            }}
                          >
                            <Box sx={{ width: 80, marginTop: -2 }}>
                              <Skeleton />
                            </Box>
                          </span>
                        </span>
                      </div>{' '}
                      <div>
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 700,
                            margin: '0 0 0 18'
                          }}
                        >
                          {value === 1 ? (
                            'DELIVERY TIME'
                          ) : EstesApiLoading ? (
                            <Box sx={{ width: 110, marginLeft: 3 }}>
                              <Skeleton />
                            </Box>
                          ) : (
                            'EST DELIVERY TIME'
                          )}
                          <br />
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: 'normal',
                              margin: '0 0 0 18'
                            }}
                          >
                            <Box
                              sx={{ width: 80, marginTop: -2, marginLeft: 3 }}
                            >
                              <Skeleton />
                            </Box>
                          </span>
                        </span>
                      </div>{' '}
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <span
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          margin: '0 0 0 18'
                        }}
                      >
                        {value === 1 ? (
                          'DELIVERY TIME'
                        ) : EstesApiLoading ? (
                          <Box sx={{ width: 110, marginLeft: 3 }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          'EST DELIVERY TIME'
                        )}
                        <br />
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 'normal',
                            margin: '0 0 0 18'
                          }}
                        >
                          <Box sx={{ width: 80, marginTop: -2, marginLeft: 3 }}>
                            <Skeleton />
                          </Box>
                        </span>
                      </span>
                    </div>{' '}
                  </Col>
                </Row>
              </div>
            ))
          : GetRatesArray.map((items, index) => (
              <List>
                <ListItemButton
                  onMouseDown={handleClick}
                  selected={selectedIndex === index}
                  onClick={event => handleListItemClick(event, index, items)}
                >
                  <Row
                    key={index}
                    className='Carrier_div'
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={
                        EstesApiLoading
                          ? {
                              width: 60,
                              fontSize: 12,
                              fontWeight: 700,
                              position: 'absolute',
                              margin: '-90 0 0 20',
                              backgroundColor: 'white',
                              whiteSpace: 'nowrap'
                            }
                          : {
                              width: 'auto',
                              fontSize: 12,
                              fontWeight: 700,
                              position: 'absolute',
                              margin: '-90 0 0 20',
                              backgroundColor: 'white',
                              whiteSpace: 'nowrap'
                            }
                      }
                    >
                      {EstesApiLoading ? (
                        <Box>
                          <Skeleton />
                        </Box>
                      ) : (
                        items.carrierName + ' '
                      )}
                      {/* {items.serviceType !== null && !EstesApiLoading && (
                    <span>
                      (
                      {items.serviceType == null || items.serviceType === ' '
                        ? null
                        : items.serviceType}
                      )
                    </span>
                  )} */}
                    </div>
                    <Col lg={3}>
                      {EstesApiLoading ? (
                        <Box
                          sx={{
                            height: 60,
                            margin: '20 0 0 17'
                          }}
                        >
                          <Skeleton variant='circular' width={40} height={40} />
                        </Box>
                      ) : (
                        <img
                          style={
                            items.carrierName === 'FedEx'
                              ? {
                                  height: 80,
                                  margin: '0 0 0 10',
                                  cursor: 'pointer'
                                }
                              : {
                                  height: 80,
                                  margin: '0 0 0 0',
                                  cursor: 'pointer'
                                }
                          }
                          src={items.logo}
                          alt='logo'
                        />
                      )}
                      {/* <div style={{ margin: '-20 10 0 10' }}>
                    {EstesApiLoading ? (
                      <Box sx={{ width: 100 }}>
                        <Skeleton />
                      </Box>
                    ) : (
                      <ReactStars
                        value={carrierRating}
                        onChange={e => setCarrierRating(e)}
                        size={20}
                        edit
                      />
                    )}
                  </div> */}
                    </Col>
                    <Col lg={2}>
                      <div style={{ cursor: 'pointer' }}>
                        <HtmlTooltip
                          placement='top'
                          title={
                            <table className='Table' style={{ fontSize: 13 }}>
                              <tr className='Table_Head'>
                                <th>
                                  <b>Description</b>
                                </th>

                                <th>
                                  <b>Amount</b>
                                </th>
                                {/* <hr /> */}
                              </tr>

                              {items.surcharges &&
                                items.surcharges.map(surcharges => (
                                  <tr>
                                    <td>
                                      <span>{surcharges.description}</span>
                                    </td>

                                    <td align='right'>
                                      <span>
                                        {surcharges?.amount?.amount?.toFixed(2)}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              {
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>

                                  <td align='right'>
                                    <b>${items?.Rate?.toFixed(2)}</b>
                                  </td>
                                </tr>
                              }
                            </table>
                          }
                        >
                          <h2>
                            {EstesApiLoading ? (
                              <Box sx={{ width: 120 }}>
                                <Skeleton />
                              </Box>
                            ) : (
                              `$${items?.Rate?.toFixed(2)}`
                            )}
                          </h2>
                        </HtmlTooltip>

                        {/* </Tooltip> */}

                        <span
                          style={{
                            width: 1000,
                            fontSize: 10,
                            fontWeight: 700,
                            position: 'absolute',
                            margin: '1 0 0 0',
                            cursor: 'auto',
                            display: 'inline-flex'
                          }}
                        >
                          {!EstesApiLoading && (
                            <HtmlTooltip
                              placement='top'
                              title={
                                <table
                                  className='Table'
                                  style={{ fontSize: 13 }}
                                >
                                  <tr
                                    style={{
                                      backgroundColor: '#1976d2',
                                      color: 'white'
                                    }}
                                  >
                                    <th
                                      colspan='2'
                                      style={{ textAlign: 'center' }}
                                    >
                                      <b>Origin Terminal Information</b>
                                    </th>

                                    <th
                                      colspan='2'
                                      style={{ textAlign: 'center' }}
                                    >
                                      <b>Destination Terminal Information</b>
                                    </th>
                                  </tr>
                                  {(items.originContactCompanyName ||
                                    items.destinationContactCompanyName) && (
                                    <tr>
                                      <td>
                                        <b>Name</b>{' '}
                                      </td>
                                      <td>{items.originContactCompanyName}</td>
                                      <td>
                                        <b>Name</b>
                                      </td>
                                      <td>
                                        {items.destinationContactCompanyName}
                                      </td>
                                    </tr>
                                  )}

                                  {(items.originContactPersonName ||
                                    items.destinationContactPersonName) && (
                                    <>
                                      <tr>
                                        <td>
                                          <b>Manager</b>{' '}
                                        </td>
                                        <td>{items.originContactPersonName}</td>
                                        <td>
                                          <b>Manager</b>{' '}
                                        </td>
                                        <td>
                                          {items.destinationContactPersonName}
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  {(items.originStreetLines ||
                                    items.destinationStreetLines) && (
                                    <>
                                      <tr>
                                        <td>
                                          <b>Address</b>
                                        </td>
                                        <td>{items.originStreetLines}</td>
                                        <td>
                                          <b>Address</b>{' '}
                                        </td>
                                        <td>{items.destinationStreetLines}</td>
                                      </tr>
                                    </>
                                  )}
                                  {(items.originCity ||
                                    items.destinationCity) && (
                                    <>
                                      <tr>
                                        <td>
                                          <b>City / State / Zip</b>{' '}
                                        </td>
                                        <td>
                                          {items.originCity},
                                          {items.originStateOrProvinceCode},
                                          {items.originPostalCode}
                                        </td>

                                        <td>
                                          <b>City / State / Zip</b>{' '}
                                        </td>
                                        <td>
                                          {items.destinationCity},
                                          {items.destinationStateOrProvinceCode}
                                          ,{items.destinationPostalCode}
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  {((items.originContactPersonPhnNmb !== ' ' &&
                                    items.originContactPersonPhnNmb !== null) ||
                                    (items.destinationContactPersonPhnNmb !==
                                      ' ' &&
                                      items.destinationContactPersonPhnNmb !==
                                        null)) && (
                                    <>
                                      <tr>
                                        <td>
                                          <b>Phone</b>{' '}
                                        </td>
                                        <td>
                                          {' '}
                                          {items.originContactPersonPhnNmb}
                                        </td>

                                        <td>
                                          <b>Phone</b>{' '}
                                        </td>
                                        <td>
                                          {items.destinationContactPersonPhnNmb}
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  {/* <tr>
                                <td>
                                  <b>Customer Service Phone:</b>
                                </td>
                                <td></td>
                                <td>
                                  <b>Customer Service Phone:</b>
                                </td>
                              </tr> */}
                                  {((items.originContactPersonExtension !==
                                    ' ' &&
                                    items.originContactPersonExtension !==
                                      null) ||
                                    (items.destinationContactPersonExtension !==
                                      ' ' &&
                                      items.destinationContactPersonExtension !==
                                        null)) && (
                                    <>
                                      <tr>
                                        <td>
                                          <b>Toll Free Phone</b>{' '}
                                        </td>

                                        <td>
                                          {items.originContactPersonExtension}
                                        </td>
                                        <td>
                                          <b>Toll Free Phone</b>{' '}
                                        </td>
                                        <td>
                                          {
                                            items.destinationContactPersonExtension
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  {((items.originContactFaxNmb !== ' ' &&
                                    items.originContactFaxNmb !== null) ||
                                    (items.destinationContactFaxNmb !== ' ' &&
                                      items.destinationContactFaxNmb !==
                                        null)) && (
                                    <tr>
                                      <td>
                                        <b>Fax</b>
                                      </td>
                                      <td>{items.originContactFaxNmb}</td>
                                      <td>
                                        <b>Fax</b>
                                      </td>
                                      <td>{items.destinationContactFaxNmb}</td>
                                    </tr>
                                  )}
                                </table>
                              }
                            >
                              <span
                                style={
                                  !items.quotationNumber
                                    ? {
                                        // width: 'auto',
                                        // margin: '0 0 0 100'
                                      }
                                    : {}
                                }
                              >
                                <PinDropIcon
                                  style={{
                                    width: 20,
                                    cursor: 'pointer',
                                    margin: '-5 0 0 0'
                                  }}
                                />
                              </span>
                            </HtmlTooltip>
                          )}
                          {EstesApiLoading ? (
                            <Box sx={{ width: 100, marginTop: 0.5 }}>
                              <Skeleton />
                            </Box>
                          ) : (
                            items.quotationNumber &&
                            `Quote ID:${items.quotationNumber}`
                          )}
                        </span>
                      </div>
                    </Col>
                    <Col lg={3}>
                      {items.isGuaranteedDelviery && (
                        <div style={{ margin: '0 0 -15 0' }}>
                          {/* <ThumbUpOutlinedIcon style={{ height: 30, width: 30 }} /> */}
                          <img
                            src={GuaranteeIcon}
                            alt='GuaranteeIcon'
                            style={{ height: 30, width: 30 }}
                          />
                          <span
                            style={{
                              fontSize: 12,
                              fontWeight: 600,
                              margin: '0 0 0 5'
                            }}
                          >
                            <i>{items.serviceType}</i>
                          </span>
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          margin: '15 0 0 10'
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: 700
                            }}
                          >
                            {value === 1 ? (
                              'DELIVERY DATE'
                            ) : EstesApiLoading ? (
                              <Box sx={{ width: 110 }}>
                                <Skeleton />
                              </Box>
                            ) : (
                              'EST DELIVERY DATE'
                            )}
                            <br />
                            <span
                              style={{
                                fontSize: 11,
                                fontWeight: 'normal'
                              }}
                            >
                              {EstesApiLoading ? (
                                <Box sx={{ width: 80, marginTop: -2 }}>
                                  <Skeleton />
                                </Box>
                              ) : (
                                items.deliveryTime
                              )}
                            </span>
                          </span>
                        </div>{' '}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div>
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 700,
                            margin: '0 0 0 18'
                          }}
                        >
                          {value === 1 ? (
                            'DELIVERY TIME'
                          ) : EstesApiLoading ? (
                            <Box sx={{ width: 110, marginLeft: 3 }}>
                              <Skeleton />
                            </Box>
                          ) : (
                            'EST DELIVERY TIME'
                          )}
                          <br />
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: 'normal',
                              margin: '0 0 0 18'
                            }}
                          >
                            {value === 1 ? (
                              `${items?.days?.substring(0, 3)} business day(s)`
                            ) : EstesApiLoading ? (
                              <Box
                                sx={{
                                  width: 80,
                                  marginTop: -2,
                                  marginLeft: 3
                                }}
                              >
                                <Skeleton />
                              </Box>
                            ) : (
                              `*Est ${items?.days} business day(s)`
                            )}
                          </span>
                        </span>
                      </div>{' '}
                    </Col>
                  </Row>
                </ListItemButton>
              </List>
            ))}
        {ErrMsgArray &&
          ErrMsgArray.map(
            (Erritems, index, ErrArr) =>
              Erritems.msg && (
                <div className='Carrier_div' key={index}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        width: 'auto',
                        fontSize: 12,
                        fontWeight: 700,
                        position: 'absolute',
                        margin: '-80 0 0 20',
                        backgroundColor: 'white',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {EstesApiLoading ? (
                        <Box>
                          <Skeleton width={40} />
                        </Box>
                      ) : (
                        Erritems?.logo?.title + ' '
                      )}
                    </div>
                    <Col lg={3}>
                      {EstesApiLoading ? (
                        <Box
                          sx={{
                            height: 60,
                            margin: '20 0 0 17'
                          }}
                        >
                          <Skeleton variant='circular' width={40} height={40} />
                        </Box>
                      ) : (
                        <img
                          style={{
                            height: 80,
                            margin: '0 0 0 0',
                            cursor: 'pointer'
                          }}
                          src={Erritems?.logo?.logo}
                          alt='logo'
                        />
                      )}
                    </Col>
                    <Col lg={7} style={{ width: 'auto' }}>
                      {' '}
                      <RateErrorTooltip
                        title={
                          Erritems?.msg.length > 70 && (
                            <span>{Erritems?.msg}</span>
                          )
                        }
                        placement='top'
                        // arrow
                      >
                        {EstesApiLoading ? (
                          <Box
                            sx={{
                              height: 60,
                              margin: '20 0 0 17'
                            }}
                          >
                            <Skeleton
                              // variant='circular'
                              width={500}
                              height={40}
                            />
                          </Box>
                        ) : (
                          <div
                            className='Err_Msg_Div'
                            style={
                              Erritems?.msg.length > 70
                                ? { cursor: 'pointer', borderRadius: 10 }
                                : {
                                    borderRadius: 10
                                  }
                            }
                          >
                            <ErrorOutlineOutlinedIcon
                              style={{ margin: '0 10 0 20' }}
                            />
                            <small
                              style={{
                                whiteSpace: 'nowrap',
                                width: 'auto',
                                margin: '0 20 0 0'
                              }}
                            >
                              {Erritems?.msg?.substring(0, 70)}
                            </small>
                          </div>
                        )}
                      </RateErrorTooltip>
                    </Col>
                  </Row>
                </div>
              )
          )}
      </div>
    </div>
  );
}

export default ShipmentTabPanel;
