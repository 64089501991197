import { createSlice } from '@reduxjs/toolkit';

export const totalPersistedWeightLBS = createSlice({
  name: 'totalPersistedWeightLBS',
  initialState: { ClassWeight: '', DimensionsWeight: '' },
  reducers: {
    setClassWeight: (state, action) => {
      state.ClassWeight = action.payload;
    },
    setDimensionsWeight: (state, action) => {
      state.DimensionsWeight = action.payload;
    }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const { setClassWeight, setDimensionsWeight } =
  totalPersistedWeightLBS.actions;
