import { createSlice } from '@reduxjs/toolkit';

export const getShipmentDetails = createSlice({
  name: 'getShipmentDetails',
  initialState: { ShipmentDetails: [] },
  reducers: {
    setShipmentDetails: (state, action) => {
      state.ShipmentDetails = action.payload;
    }
    // setMailRedux: (state, action) => {
    //   state.name = action.payload;
    // },
    // setisLoggedRedux: (state, action) => {
    //   state.name = action.payload;
    // }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const { setShipmentDetails } = getShipmentDetails.actions;
