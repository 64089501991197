import React, { useState } from 'react';
import {
  Container,
  NavbarToggler,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TMSLogo from '../../assets/images/TMS-Logo.png';
import BusinessIcon from '@mui/icons-material/Business';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import PersonIcon from '@mui/icons-material/Person';
import './navbar.css';
import { AdminLogout } from '../../apis/Admin';
import { setRateValues } from '../../Redux/GetRatesSlice';
import {
  setEmailRedux,
  setLoginAgencyID,
  setPasswordRedux,
  setUserDetailsObj,
  setUserToken
} from '../../Redux/UserSlice';
import {
  setQuickQuoteDeliveryCheckedRedux,
  setQuickQuotePickUpCheckedRedux
} from '../../Redux/QuickQuoteSwevicesSlice';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ArticleIcon from '@mui/icons-material/Article';

const NavBar = props => {
  const UserDetails = useSelector(state => state.user.UserDetailsObj);
  const AgencyName = UserDetails.firstName + ' ' + UserDetails.lastName;
  var screenWidth = document.documentElement.clientWidth || window.innerWidth;
  var layoutMode = document.body.getAttribute('data-layout-mode');
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const rememberMe = useSelector(state => state.userDetails.rememberMe);
  const [userProfile, setUserProfile] = useState(false);
  const dropDownuserprofile = () => setUserProfile(prevState => !prevState);

  const dispatch = useDispatch();

  const handleLogout = () => {
    AdminLogout()
      .then(res => console.log(res, 'AagencyLogout'))
      .catch(err => console.log(err, 'AagencyLogoutErr'));
    localStorage.clear();
    !rememberMe && dispatch(setPasswordRedux(''));
    !rememberMe && dispatch(setEmailRedux(''));
    !rememberMe && dispatch(setUserDetailsObj({}));
    dispatch(setUserToken(''));
    dispatch(setLoginAgencyID(''));
    dispatch(setRateValues(''));
  };

  return (
    <React.Fragment>
      <nav
        className={'navbar navbar-expand-lg sticky-top  mt-0 pb-2 '}
        id='navigation'
        style={
          layoutMode === 'dark'
            ? { backgroundColor: '#303841' }
            : { backgroundColor: '#EDF2F9' }
        }
      >
        <Container
          fluid
          className=''
          style={{
            margin: '7px 20px 0px 20px'
          }}
        >
          <Link
            className='navbar-brand text-dark fw-bold me-auto'
            to={localStorage.getItem('Home')}
          ></Link>
          <div>
            <NavbarToggler
              className='me-3'
              type='button'
              onClick={() => toggle()}
            >
              <i className='mdi mdi-menu'></i>
            </NavbarToggler>
          </div>
          <div className='navbar-collapse'>
            <div>
              <Link
                // onClick={e => {
                //   setShipmentColor(false);
                //   setQuickColor(false);
                // }}
                to={'/dashboard'}
                className='Navbar-TMS-Parent'
              >
                <img className='Navbar-TMSLogo' alt='TMSLogo' src={TMSLogo} />
                <span className='Navbar-TMS'> TMS</span>
              </Link>
            </div>
            <div
              style={
                screenWidth <= 1631
                  ? { margin: '0 0 0 160' }
                  : { margin: '0 0 0 300' }
              }
            >
              <Link to={'/dashboard'}>
                <DashboardIcon className='Navbar-AgenciesIcon' />
                <span className='Navbar-Icon-Name'>Dashboard</span>
              </Link>
              <Link to={'/manage-agencies'}>
                <BusinessIcon className='Navbar-AgenciesIcon' />
                <span className='Navbar-Icon-Name'>Agencies</span>
              </Link>
              {/* <Dropdown
              isOpen={grid}
              toggle={() => gridDropDown()}
              className='list-inline-item'
            >
              <DropdownToggle
                to='#'
                id='userdropdown'
                type='button'
                tag='a'
                aria-expanded='false'
              >
                <InventoryIcon className='Navbar-ShipmentsIcon' />
                <span className='Navbar-Icon-Name'>Shipments</span>

                <ArrowDropDownIcon className='Navbar-ShipmentsDropDownIcon' />
              </DropdownToggle>
              <DropdownMenu
                className='dropdown-menu-end'
                aria-labelledby='userdropdown'
              >
                <li>
                  <Link
                    style={
                      shipmentColor
                        ? { backgroundColor: 'rgb(218 235 255' }
                        : {}
                    }
                    onClick={() => gridDropDown('manage-shipments')}
                    to='/dashboard'
                    className='dropdown-item'
                  >
                    Manage Shipments
                  </Link>
                  <Link
                    style={
                      quickColor ? { backgroundColor: 'rgb(218 235 255' } : {}
                    }
                    onClick={() => gridDropDown('quick')}
                    to='quick-quote'
                    className='dropdown-item'
                  >
                    Quick Quote
                  </Link>
                </li>
              </DropdownMenu>
            </Dropdown> */}

              {/* <Link to={'/carriers'}>
                <LocalShippingIcon className='Navbar-Carriers' />
                <span className='Navbar-Icon-Name'>Carriers</span>
              </Link> */}

              <Link to={'/manage-users'}>
                <GroupIcon className='Navbar-Carriers' />
                <span className='Navbar-Icon-Name'>Manage users</span>
              </Link>
              <Link to={'/subscriptions'}>
                <SubscriptionsIcon className='Navbar-Carriers' />
                <span className='Navbar-Icon-Name'>Subscription</span>
              </Link>
              <Link to={'/reports'}>
                <ArticleIcon className='Navbar-Carriers' />
                <span className='Navbar-Icon-Name'>Reports</span>
              </Link>
            </div>
          </div>

          <ul className='header-menu list-inline d-flex align-items-center mb-0'>
            <NightlightRoundIcon
              onClick={() => props.toggleMode()}
              className='Right-MoonIcon'
            />

            {/* <AppsIcon
              style={{ color: '#6BA3E9' }}
              className='list-inline-item'
            /> */}

            {/* <Dropdown
              isOpen={notification}
              toggle={dropDownnotification}
              className='list-inline-item  '
            >
              <DropdownToggle
                href='#'
                className='Grid-Noti-Icon header-item noti-icon position-relative'
                id='notification'
                type='button'
                tag='a'
              >
                <img
                  alt='NotificationIcon'
                  src={NotificationIcon}
                  style={{ height: 24, with: 24 }}
                />
                <NotificationsIcon style={{ color: '#6BA3E9' }} />

                <div style={{ top: -5 }} className='count position-absolute'>
                  3
                </div>
              </DropdownToggle>
              <DropdownMenu
                className='dropdown-menu-sm dropdown-menu-end p-0'
                aria-labelledby='notification'
                end
              >
                <div className='notification-header border-bottom bg-light'>
                  <h6 className='mb-1'> Notifications </h6>
                  <p className='text-muted fs-13 mb-0'>
                    You have 4 unread Notification
                  </p>
                </div>
                <div className='notification-wrapper dropdown-scroll'>
                  <Link
                    to='#'
                    className='text-dark notification-item d-block active'
                  >
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0 me-3'>
                        <div className='avatar-xs bg-primary text-white rounded-circle text-center'>
                          <i className='uil uil-user-check'></i>
                        </div>
                      </div>
                      <div className='flex-grow-1'>
                        <h6 className='mt-0 mb-1 fs-14'>
                          22 verified registrations
                        </h6>
                        <p className='mb-0 fs-12 text-muted'>
                          <i className='mdi mdi-clock-outline'></i>{' '}
                          <span>3 min ago</span>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link to='#' className='text-dark notification-item d-block'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0 me-3'>
                        <img
                          src={userImage2}
                          className='rounded-circle avatar-xs'
                          alt='user-pic'
                        />
                      </div>
                      <div className='flex-grow-1'>
                        <h6 className='mt-0 mb-1 fs-14'>James Lemire</h6>
                        <p className='text-muted fs-12 mb-0'>
                          <i className='mdi mdi-clock-outline'></i>{' '}
                          <span>15 min ago</span>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link to='#' className='text-dark notification-item d-block'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0 me-3'>
                        <img
                          src={userImage2}
                          className='rounded-circle avatar-xs'
                          alt='user-pic'
                        />
                      </div>
                      <div className='flex-grow-1'>
                        <h6 className='mt-0 mb-1 fs-14'>
                          Applications has been approved
                        </h6>
                        <p className='text-muted mb-0 fs-12'>
                          <i className='mdi mdi-clock-outline'></i>{' '}
                          <span>45 min ago</span>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link to='#' className='text-dark notification-item d-block'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0 me-3'>
                        <img
                          src={userImage2}
                          className='rounded-circle avatar-xs'
                          alt='user-pic'
                        />
                      </div>
                      <div className='flex-grow-1'>
                        <h6 className='mt-0 mb-1 fs-14'>Kevin Stewart</h6>
                        <p className='text-muted mb-0 fs-12'>
                          <i className='mdi mdi-clock-outline'></i>{' '}
                          <span>1 hour ago</span>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link to='#' className='text-dark notification-item d-block'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0 me-3'>
                        <img
                          src={userImage2}
                          className='rounded-circle avatar-xs'
                          alt='user-pic'
                        />
                      </div>
                      <div className='flex-grow-1'>
                        <h6 className='mt-0 mb-1 fs-15'>Creative Agency</h6>
                        <p className='text-muted mb-0 fs-12'>
                          <i className='mdi mdi-clock-outline'></i>{' '}
                          <span>2 hour ago</span>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className='notification-footer border-top text-center'>
                  <Link className='primary-link fs-13' to='#'>
                    <i className='mdi mdi-arrow-right-circle me-1'></i>{' '}
                    <span>View More..</span>
                  </Link>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            <Dropdown
              onClick={() => setUserProfile(!userProfile)}
              isOpen={userProfile}
              toggle={dropDownuserprofile}
              className='list-inline-item'
            >
              <DropdownToggle
                to='#'
                className='header-item'
                id='userdropdown'
                type='button'
                tag='a'
                aria-expanded='false'
                style={{ height: 29 }}
              >
                {/* <img
                  alt='UserIcon'
                  src={UserIcon}
                  style={{ height: 24, width: 24 }}
                /> */}
                <div style={{ display: 'flex' }}>
                  <div style={{ margin: '-18 10 0 0', color: '#6BA3E9' }}>
                    <b>{UserDetails?.firstName && AgencyName}</b>
                  </div>

                  <PersonIcon style={{ color: '#6BA3E9' }} />
                </div>
              </DropdownToggle>
              <DropdownMenu
                className='dropdown-menu-end'
                aria-labelledby='userdropdown'
                end
              >
                {/* <li>
                  <Link className='dropdown-item' to='/'>
                    get status
                  </Link>
                </li>
                <li>
                  <Link className='dropdown-item' to='/'>
                    settings
                  </Link>
                </li> */}
                <li>
                  <Link className='dropdown-item' to='/profile'>
                    My Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className='dropdown-item'
                    onClick={handleLogout}
                    to='/signin'
                  >
                    Logout
                  </Link>
                </li>
              </DropdownMenu>
            </Dropdown>
          </ul>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default withRouter(NavBar);
