// without lazy import
import SignOut from '../pages/ExtraPages/SignOut';
import { ManageUsers } from '../pages/ManageUsers/ManageUsers';
import MyProfile from '../pages/Profile/MyProfile/MyProfile';
import Layout2 from '../pages/Home/Layout2/Layout2';
import Agencies from '../pages/Agencies/Agencies';
import Carriers from '../pages/Carriers/Carriers';
import GetQuotes from '../pages/GetQoute/GetQuotes';
import QuickQuote from '../pages/GetQoute/QuickQuote';
import QuickQuoteRates from '../pages/GetRate/QuickQuoteRates';
import DispatchShipment from '../pages/DispatchShipment/DispatchShipment';
import CreateAgency from '../pages/CreateAgency/CreateAgency.js';
import SignIn from '../pages/ExtraPages/SignIn';
import ResetPassword from '../pages/ExtraPages/ResetPassword';
import UpdatePassword from '../pages/ExtraPages/UpdatePassword';
import LockScreenComponent from '../pages/LockScreen/LockScreen';
import SignUp from '../pages/ExtraPages/SignUp';
import ConfirmationCode from '../pages/ExtraPages/ConfirmationCode';
import Subscriptions from '../pages/Subsciptions/Subscriptions';
import Reports from '../pages/Reports/Reports';
import CreateUsers from '../pages/CreateUsers/CreateUsers';
import CreateCarrier from '../pages/Carriers/CreateCarrier';

const userRoutes = [
  { path: '/manage-users', component: ManageUsers },
  { path: '/profile', component: MyProfile },
  { path: '/dashboard', component: Layout2 },
  { path: '/manage-agencies', component: Agencies },
  // { path: '/carriers', component: Carriers },
  { path: '/subscriptions', component: Subscriptions },
  { path: '/reports', component: Reports },
  { path: '/get-rates', component: QuickQuoteRates },
  { path: '/dispatch', component: DispatchShipment },
  { path: '/create-agency', component: CreateAgency },
  { path: '/create-carrier', component: CreateCarrier },
  { path: '/create-users', component: CreateUsers },
  { path: '/', component: Layout2 }

  // { path: "*", component: Error404 },
];

const authRoutes = [
  // { path: "/", component: SignIn },
  { path: '/signin', component: SignIn },
  { path: '/resetpassword', component: ResetPassword },
  { path: '/update-password', component: UpdatePassword },
  { path: '/signout', component: SignOut },
  { path: '/Admin-locked-screen', component: LockScreenComponent },
  { path: '/signup', component: SignUp },
  { path: '/confirmation', component: ConfirmationCode }
  // { path: "*", component: Error404 },
];
export { userRoutes, authRoutes };
