import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Input } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { AdminGetProfile, AdminLogin } from '../../apis/Admin';
import { successToast, ErrorToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import {
  setPasswordRedux,
  setEmailRedux,
  setUserToken,
  setLoginAgencyID,
  setUserDetailsObj,
  setRememberMeRedux
} from '../../Redux/UserSlice';
import './Common.css';
import { Form, InputGroup } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

const SignIn = () => {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');

  const rememberMeRedux = useSelector(state => state.userDetails.rememberMe);
  const rememberMePassword = useSelector(state => state.userDetails.password);
  const rememberMeEmail = useSelector(state => state.userDetails.email);
  let history = useHistory();
  const dispatch = useDispatch();
  const handleLoggedInSuccess = () => {
    successToast('User authenticated successfully.');
    setTimeout(() => {
      history.push('/dashboard');
    }, 1000);
  };

  const GetSuccessUserDetails = apiRes => {
    // setUserDetailsArr(apiRes.data);
    dispatch(setUserDetailsObj(apiRes.data));
  };

  const successResponse = succRes => {
    AdminGetProfile(userName)
      .then(
        res => res.data.statusCode === 200 && GetSuccessUserDetails(res.data)
      )
      .catch(err => console.log(err));

    if (succRes.statusCode === 200) {
      dispatch(setPasswordRedux(password));
      dispatch(setEmailRedux(userName));
      dispatch(setUserToken(succRes.token));
      dispatch(setLoginAgencyID(succRes.agencyId));

      localStorage.setItem('AdminLoginId', succRes.expiry);
      localStorage.setItem('AdminAuthToken', succRes.token);

      setIsLoading(false);
      handleLoggedInSuccess();
    }
  };
  const handleSubmit = e => {
    e.preventDefault();

    // withou Api's Signin Bypass

    // localStorage.setItem('AdminLoginId', 'succRes.expiry');
    // dispatch(setUserToken('succRes.token'));
    // history.push('/dashboard');

    // with Server Side
    setIsLoading(true);
    dispatch(setPasswordRedux(password));
    AdminLogin(userName, password)
      .then(res => successResponse(res.data))
      .catch(err => {
        console.log('AdminLoginErr', err);
        setIsLoading(false);
        ErrorToast(err?.response?.data?.message);
      });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleRememberMe = e => {
    dispatch(setRememberMeRedux(e.target.checked));
  };
  useEffect(() => {
    if (rememberMeRedux && !userName && !password) {
      setUserName(rememberMeEmail);
      setPassword(rememberMePassword);
    }
  }, [rememberMeRedux]);
  return (
    <React.Fragment>
      <ToastContainer
        position='top-center'
        autoClose={4800}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <MetaTags>
          <title>Admin | Login</title>
        </MetaTags>
        <section className='bg-auth ' style={{ backgroundColor: '#EEF3F9' }}>
          <Container style={{ display: 'flex', justifyContent: 'center' }}>
            <Col lg={6}>
              <Card className='auth-box box_shadow'>
                <CardBody className='auth-content p-5 h-100 text-white '>
                  <div className='w-100'>
                    <div className='text-center mb-4'>
                      <h3> Admin Login</h3>
                      {/* <p className="text-white-70">Sign in to TMS</p> */}
                    </div>
                    <Form className='auth-form'>
                      <div className='mb-3'>
                        <label htmlFor='usernameInput' className='form-label'>
                          Username / Email Address
                        </label>

                        <Input
                          type='text'
                          className='form-control'
                          id='username'
                          placeholder='Enter your username'
                          value={userName}
                          onChange={e => setUserName(e.target.value)}
                          required
                        />
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='passwordInput' className='form-label'>
                          Password
                        </label>

                        <InputGroup>
                          <Form.Control
                            type={showPassword ? 'text' : 'password'}
                            className='form-control'
                            id='passwordInput'
                            placeholder='Enter your password'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                            style={{
                              height: 'auto'
                            }}
                          />
                          <InputGroup.Append
                            style={{ cursor: 'pointer', borderRadius: 0 }}
                            onClick={togglePasswordVisibility}
                          >
                            <InputGroup.Text
                              style={{
                                height: '97%',
                                marginTop: 0.5,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0
                              }}
                            >
                              {showPassword ? (
                                <EyeFill
                                  style={{
                                    height: '100%'
                                  }}
                                />
                              ) : (
                                <EyeSlashFill
                                  style={{
                                    height: '100%'
                                  }}
                                />
                              )}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                      <div className='mb-4'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='flexCheckDefault'
                            checked={rememberMeRedux}
                            onClick={e => handleRememberMe(e)}
                          />
                          <Link
                            to='/resetpassword'
                            className='float-end text-white'
                          >
                            Forgot Password?
                          </Link>
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckDefault'
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className='text-center'>
                        <button
                          disabled={userName && password ? false : true}
                          type='submit'
                          className='btn btn-white btn-hover w-100'
                          onClick={e => handleSubmit(e)}
                        >
                          <div>
                            {isLoading ? (
                              <span style={{ display: 'inline-block' }}>
                                Loading ...
                              </span>
                            ) : (
                              <span style={{ display: 'inline-block' }}>
                                Login
                              </span>
                            )}
                          </div>
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
