// tms staging URL
export const base_url = 'https://api.tms.dynamicwerx.com/';

// beta staging URL
// export const base_url = 'https://beta.api.dynamicwerx.com/';

//shiptechpro URL
// export const base_url = 'https://api.tms.shiptechpro.com/';

export const currentuserid = localStorage.getItem('UserId');

// image url for tms
export const base_logo_url = 'https://tms.dynamicwerx.com/';

// image url for beta
// export const base_logo_url = 'https://beta.tms.dynamicwerx.com/';

// image url for shiptechpro
// export const base_logo_url = 'https://tms.shiptechpro.com/';

export const REACT_APP_GOOGLE_MAPS_KEY =
  'AIzaSyCmIKvQQM2CXckz6quBIGJHebOihJ8nS28';
