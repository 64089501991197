import { useState, React } from 'react';
import {
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  StepButton,
  Switch
} from '@mui/material';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import { DispatchShipment, PickupShipment } from '../../apis/Admin';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './Carriers.css';
import Paper from '@mui/material/Paper';
import { Autocomplete, Button, TextField } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { useHistory } from 'react-router-dom';
import { CardContent, Radio, FormControlLabel } from '@mui/material';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import {
  AdminGetCarriers,
  AgencyAdminGetProfile,
  CreateOrUpdateAgency,
  CreateOrUpdateCarrier,
  UploadAgencyLogo
} from '../../apis/Admin';
import { ErrorToast, successToast } from '../../components/Toasts';
import UploadIcon from '@mui/icons-material/Upload';
import { ToastContainer } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function CreateCarrier(props) {
  const EditedData = props?.location?.state;
  const history = useHistory();
  console.log(EditedData, 'EditedData');

  const [shipmentFailed, setShipmentFailed] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [activeTabLabel, setActiveTabLabel] = useState('Detail');
  // agency states
  const options = ['Active', 'In-Active'];
  const [agencyActiveValue, setAgencyActiveValue] = useState(
    EditedData?.active === false ? options[1] : options[0]
  );

  const carrierTypes = [
    { type: 'TL', subType: 'Dry Wan', title: 'Dry Wan' },
    { type: 'TL', subType: 'Reefer', title: 'Reefer' },
    { type: 'TL', subType: 'Flat Bed', title: 'Flat Bed' },
    { type: 'TL', subType: 'Sprinter', title: 'Sprinter' },
    { type: 'TL', subType: 'Box Truck', title: 'Box Truck' },
    { type: 'TL', subType: 'Rail / Intermodal', title: 'Rail / Intermodal' },
    { type: 'Parcel', subType: 'Parcel', title: 'Parcel' }
  ];
  const [carrierType, setCarrierType] = useState(
    carrierTypes.find(items => items.title === EditedData?.cSubType)
      ? carrierTypes.find(items => items.title === EditedData?.cSubType)
      : null
  );
  const [agencyStatus, setAgencyStatus] = useState(true);
  const [carrierName, setCarrierName] = useState(
    EditedData?.name ? EditedData?.name : ''
  );
  const [address1, setAddress1] = useState(
    EditedData?.streetAddress1 ? EditedData?.streetAddress1 : ''
  );
  const [address2, setAddress2] = useState(
    EditedData?.streetAddress2 ? EditedData?.streetAddress2 : ''
  );
  const [zip, setZip] = useState(
    EditedData?.postalCode ? EditedData?.postalCode : ''
  );
  const [city, setCity] = useState(EditedData?.city ? EditedData?.city : '');
  const [state, setState] = useState(
    EditedData?.state ? EditedData?.state : ''
  );
  const [country, setCountry] = useState(
    EditedData?.country ? EditedData?.country : ''
  );
  const [createAgencyLoader, setCreateAgencyLoader] = useState(false);
  const [agencyImage, setAgencyImage] = useState(
    EditedData?.icon ? `https://tms.dynamicwerx.com/${EditedData?.icon}` : null
  );
  const [agencyApiUploadedImage, setAgencyApiUploadedImage] = useState(
    EditedData?.icon ? EditedData?.icon : ''
  );

  // validations
  const [validationErrors, setValidationErrors] = useState({});
  const [isValidCity, setIsValidCity] = useState(false);
  const [isValidCountry, setIsValidCountry] = useState(false);
  const [isValidState, setIsValidState] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  // Carriers state
  const [carriersLoader, setCarriersLoader] = useState(false);
  const [phoneNumberValidError, setPhoneNumberValidError] = useState(false);

  const [carriers, setCarriers] = useState([]);
  const [allCarriersChecked, setAllCarriersChecked] = useState(false);

  const [selectedCarriersIdArray, setSelectedCarriersIdArray] = useState([]);
  // admin States
  const [firstName, setFirstName] = useState(
    EditedData?.contactFirstName ? EditedData?.contactFirstName : ''
  );
  const [lastName, setLastName] = useState(
    EditedData?.contactLastName ? EditedData?.contactLastName : ''
  );
  const [email, setEmail] = useState(
    EditedData?.contactEmail ? EditedData?.contactEmail : ''
  );
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(
    EditedData?.contactPhone ? EditedData?.contactPhone : ''
  );
  const [designation, setDesignation] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [adminActiveValue, setAdminActiveValue] = useState(options[0]);
  const [adminStatus, setAdminStatus] = useState(true);
  const [adminImage, setAdminImage] = useState(null);

  const handleAllActiveCarriers = event => {
    setAllCarriersChecked(event.target.checked);
    for (let i = 0; i < carriers.length; i++) {
      // const element = array[i];
      const tempIDs = [...selectedCarriersIdArray];
      tempIDs[i].assigned = event.target.checked;
      setSelectedCarriersIdArray(tempIDs);
      setCarriers(prevArray => {
        const newArray = [...prevArray];
        newArray[i].active = event.target.checked;
        return newArray;
      });
    }
  };

  const handleRadioChange = event => {
    setSelectedValue(event.target.value);
  };
  const handleActiveAllCarriersSwitch = TempCarrierIDs => {
    console.log(TempCarrierIDs, 'TempCarrierIDs');
    const StatusArray = [];
    for (let i = 0; i < TempCarrierIDs.length; i++) {
      StatusArray.push(
        TempCarrierIDs[i].assigned !== undefined
          ? TempCarrierIDs[i].assigned
          : TempCarrierIDs[i].active
      );
    }
    const allTrue = StatusArray.every(value => value === true);
    console.log(StatusArray, 'StatusArray');
    if (allTrue) {
      setAllCarriersChecked(true);
    } else {
      setAllCarriersChecked(false);
    }
  };
  const CheckSelectedCarriers = (CarriersArray, TempCarrierIDs) => {
    console.log(TempCarrierIDs, EditedData, 'TempCarrierIDs');
    if (EditedData?.carriers) {
      const PropsCarrierArray = EditedData?.carriers;
      const temp = [...CarriersArray];
      const tempIDs = [...TempCarrierIDs];
      // for (let i = 0; i < PropsCarrierArray.length; i++) {
      //   const CarrierName = PropsCarrierArray[i].name;
      //   const CarrierID = PropsCarrierArray[i].id;
      //   tempIDs[i].id === CarrierID
      //     ? (tempIDs[i].assigned = true)
      //     : (tempIDs[i].assigned = false);
      // }

      for (let i = 0; i < CarriersArray.length; i++) {
        const staticId = CarriersArray[i].id;
        if (
          PropsCarrierArray.some(
            element => element.id === staticId && element.active
          )
        ) {
          temp[i].active = true;
          tempIDs[i].assigned = !tempIDs[i].assigned;
        }
      }

      handleActiveAllCarriersSwitch(TempCarrierIDs);
      setSelectedCarriersIdArray(tempIDs);
      setCarriers(temp);
    }
  };

  const handleSuccessCarriers = ApiRes => {
    setCarriersLoader(false);
    const CarriersArray = ApiRes?.data?.data;
    const TempCarrierIDs = [];
    for (let i = 0; i < CarriersArray.length; i++) {
      CarriersArray[i].active = false;
      TempCarrierIDs.push({ carrierId: CarriersArray[i].id, assigned: false });
    }
    setCarriers(CarriersArray);
    setSelectedCarriersIdArray(TempCarrierIDs);
    EditedData && CheckSelectedCarriers(CarriersArray, TempCarrierIDs);
  };

  const handleSuccessCreateAgency = ApiRes => {
    setCreateAgencyLoader(false);
    successToast(ApiRes?.data?.reasonPhrase);
    setTimeout(() => {
      history.push('/manage-agencies');
    }, 4000);
  };
  const handdleExistedUserValidation = () => {
    const errors = {};
    errors['emailError'] = 'User exist with this email try another one';
    setValidationErrors(errors);
  };

  const handleCreateUpdateAgencyReq = () => {
    setCreateAgencyLoader(true);

    const CreateOrUpdateAgencyObj = {
      id: EditedData?.id ? EditedData?.id : 0,
      name: carrierName,
      logo: agencyApiUploadedImage,
      url: '',
      active: agencyStatus,
      address: address1,
      address2: address2,
      zipCode: zip,
      city: city,
      state: state,
      country: country,
      subscriptioPlanId: 1,
      carriers: selectedCarriersIdArray,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phoneNumber
    };
    // console.log(CreateOrUpdateAgencyObj, 'CreateOrUpdateAgencyObj');
    CreateOrUpdateAgency(CreateOrUpdateAgencyObj)
      .then(res => res.status === 200 && handleSuccessCreateAgency(res))
      .catch(err => {
        ErrorToast(err?.message);
        setCreateAgencyLoader(false);
      });
  };

  const ValidatUserEmail = () => {
    AgencyAdminGetProfile(email)
      .then(res => res.status === 200 && handdleExistedUserValidation())
      .catch(err => handleCreateUpdateAgencyReq());
  };

  const handleSuccessGetAgencyDetails = ApiRes => {
    const AgencyAdminDetails = ApiRes?.data?.data?.userInfo;
    console.log(AgencyAdminDetails, 'AgencyAdminDetails');
    setFirstName(AgencyAdminDetails.firstName);
    setLastName(AgencyAdminDetails.lastName);
    setEmail(AgencyAdminDetails.email);
    setPhoneNumber(AgencyAdminDetails.phone);
  };
  const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';

  const lastLetter = lastName ? lastName.charAt(0).toUpperCase() : '';

  const imageStyle = {
    marginTop: 50,
    marginLeft: 20,
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333'
  };
  const handleNext = index => {
    let errors = {};
    const regex = /^[a-zA-Z]+$/;
    if (index === 0) {
      if (!carrierName) {
        errors['carrierNameError'] = 'Enter Carrier Name';
      } else if (!carrierType) {
        errors['carrierType'] = 'Select Carrier Type';
      } else if (!zip) {
        errors['zipError'] = 'Enter Zip';
      } else if (!city) {
        errors['cityError'] = 'Enter City';
      } else if (isValidCity) {
        errors['cityError'] = 'Enter Valid City';
      } else if (!state) {
        errors['stateError'] = 'Enter State or Province';
      } else if (isValidState) {
        errors['stateError'] = 'Enter Valid State or Province';
      } else if (!country) {
        errors['countryError'] = 'Enter Country';
      } else if (isValidCountry) {
        errors['countryError'] = 'Enter Valid Country';
      } else setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    setValidationErrors(errors);

    if (index === 1) {
      if (!firstName) {
        errors['firstNameError'] = 'Enter First Name';
      } else if (!lastName) {
        errors['lastNameError'] = 'Enter Last Name';
      }
      // else if (!designation) {
      //   errors['designation'] = 'Enter Designation';
      // }
      else if (!email) {
        errors['emailError'] = 'Enter Email';
      } else if (!isValidEmail) {
        errors['emailError'] = 'Enter Valid Email';
      } else if (!phoneNumber) {
        errors['phoneNumberError'] = 'Enter Phone Number';
      } else if (phoneNumberValidError) {
        errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      } else {
        // EditedData ? handleCreateUpdateAgencyReq() : ValidatUserEmail();
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
    if (index === 2) {
      setCarriersLoader(true);
      const createUpdateCarrierObj = {
        agencyId: 0,
        id: EditedData?.id ? EditedData?.id : 0,
        name: carrierName ? carrierName : '',
        icon: agencyApiUploadedImage ? agencyApiUploadedImage : '',
        url: EditedData?.url ? EditedData?.url : '',
        cType: carrierType.type ? carrierType.type : '',
        cSubType: carrierType.subType ? carrierType.subType : '',
        active: agencyStatus,
        firstName: firstName ? firstName : '',
        lastName: lastName ? lastName : '',
        email: email ? email : '',
        phone: phoneNumber ? phoneNumber : '',
        streetAddress1: address1 ? address1 : '',
        streetAddress2: address2 ? address2 : '',
        postalCode: zip ? zip : '',
        city: city ? city : '',
        country: country ? country : '',
        state: state ? state : '',
        locationIds: [],
        designation: designation,
        cSubTypes: [carrierType.subType]
      };
      CreateOrUpdateCarrier(createUpdateCarrierObj)
        .then(res => {
          setCarriersLoader(false);
          successToast(
            EditedData
              ? 'Carrier Updated Successfully!'
              : 'Carrier Created Successfully!'
          );
          setTimeout(() => {
            history.push('/carriers');
          }, 2500);
        })
        .catch(err => {
          ErrorToast(err?.message);
          setCarriersLoader(false);
          console.log(err, 'CreateOrUpdateCarrierErr');
        });
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBackShipment = () => {
    setShipmentFailed(false);
    handleReset();
  };
  const handleStep = (step, label) => () => {
    setActiveStep(step);
    setActiveTabLabel(label);
  };

  const CreateAgencySteps = ['Detail', 'Contact Info', 'Review'];
  const MyRadioCard = ({ label, value, checked, onChange }) => {
    return (
      <Card
        sx={
          label === 'Professional Package'
            ? { margin: '30 0 0 0', height: 70 }
            : {
                mt: 3,
                height: 70
              }
        }
      >
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Grid sx={{ display: 'flex' }}>
            <SubscriptionsIcon sx={{ fontSize: 40, color: 'grey' }} />
            <div className='vl' />
            <Grid sx={{ ml: 4 }}>
              <Typography variant='h6'>{label}</Typography>
              <Typography variant='caption'>More Information</Typography>
            </Grid>
          </Grid>
          <FormControlLabel
            // label={label}
            value={value}
            control={<Radio />}
            checked={checked}
            onChange={onChange}
          ></FormControlLabel>
        </CardContent>
      </Card>
    );
  };

  const handleCarriersSwitchOnchange = (event, SelectedApiCarrier, index) => {
    setCarriers(prevArray => {
      const newArray = [...prevArray];
      newArray[index].active = !newArray[index].active;
      handleActiveAllCarriersSwitch(newArray);
      return newArray;
    });
    console.log(carriers, 'handleSwitchToggle ');

    for (let i = 0; i < selectedCarriersIdArray.length; i++) {
      const carrierID = selectedCarriersIdArray[i].carrierId;
      const carrierStatus = selectedCarriersIdArray[i].assigned;
      console.log(carrierID, carrierStatus, 'carrierID');
      if (carrierID === SelectedApiCarrier?.id && carrierStatus) {
        selectedCarriersIdArray[i].assigned = false;
      }

      if (carrierID === SelectedApiCarrier?.id && !carrierStatus) {
        selectedCarriersIdArray[i].assigned = true;
      }
    }
    setSelectedCarriersIdArray(selectedCarriersIdArray);
  };
  console.log(EditedData, 'selectedCarriersIdArray');

  const CarriersCard = ({ items, index }) => {
    return (
      <Grid
        sx={
          items?.active
            ? {
                outline: '1px solid #ebebeb',
                boxShadow: '0px 0px 5px 1px',
                mt: 2,
                borderRadius: '4px',
                backgroundColor: '#ebebeb',
                // outline: '1px solid #b1b1b1',
                display: 'flex',
                justifyContent: 'space-between',
                width: '40vw'
              }
            : {
                // outline: '1px solid #1976d2',
                // boxShadow: '0px 1px 3px 1px',
                mt: 2,
                borderRadius: '4px',
                outline: '1px solid #b1b1b1',
                display: 'flex',
                justifyContent: 'space-between',
                width: '40vw'
              }
        }
      >
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ width: 70 }}
            src={`https://tms.dynamicwerx.com/carrier_logos/${items.icon}`}
            alt='AdminProfileImage'
          />{' '}
          <Grid>
            <Typography sx={{ ml: 2 }} variant='h6'>
              {items.name}
            </Typography>
            <Typography sx={{ ml: 2 }} variant='caption'>
              {items.cType}
            </Typography>
          </Grid>
        </Grid>

        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={items?.active}
            onChange={event =>
              handleCarriersSwitchOnchange(event, items, index)
            }
          />
        </Grid>
      </Grid>
    );
  };
  const handleNextButton = () => {};

  const handleAgencyImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const imageType = /image.*/;
    if (!file.type.match(imageType)) {
      ErrorToast('Only image files are allowed');
    }
    if (file.type.match(imageType)) {
      reader.onloadend = () => {
        setAgencyImage(reader.result);
      };
      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append('file', file);
      var formdata = new FormData();
      formdata.append('imageFile', file);
      UploadAgencyLogo(formData)
        .then(
          res =>
            res.status === 200 && setAgencyApiUploadedImage(res?.data?.data)
        )
        .catch(err => ErrorToast(err?.message));
    }
  };

  const handleAdminImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAdminImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleStepperContent = (index, label) => {
    if (index === 0) {
      const handleCarrierTypeChnage = (event, newValue) => {
        console.log(newValue, 'newValue');
        setValidationErrors({
          ...validationErrors,
          carrierType: null
        });
        setCarrierType(newValue);
      };

      const handleAgencyStatusChange = (event, newValue) => {
        setAgencyActiveValue(newValue);
        if (newValue === 'Active') {
          setAgencyStatus(true);
        } else {
          setAgencyStatus(false);
        }
      };

      const handleStateProvinceChange = (e, fieldName) => {
        if (fieldName === 'city') {
          const inputValue = e;

          setCity(inputValue);
          const regex = /^[a-zA-Z\s]+$/;
          let errors = {};
          if (regex.test(inputValue)) {
            setIsValidCity(false);
            setValidationErrors({
              ...validationErrors,
              cityError: null
            });
          } else {
            setIsValidCity(true);
            errors['cityError'] = 'Enter Valid City';
            setValidationErrors(errors);
          }
        } else if (fieldName === 'state') {
          const inputValue = e;
          setState(inputValue);
          const regex = /^[a-zA-Z\s]+$/;
          let errors = {};
          if (regex.test(inputValue)) {
            setIsValidState(false);
            setValidationErrors({
              ...validationErrors,
              stateError: null
            });
          } else {
            setIsValidState(true);
            errors['stateError'] = 'Enter Valid State or Province';
            setValidationErrors(errors);
          }
        } else {
          const inputValue = e;
          setCountry(inputValue);
          const regex = /^[a-zA-Z\s]+$/;
          let errors = {};
          if (regex.test(inputValue)) {
            setIsValidCountry(false);
            setValidationErrors({
              ...validationErrors,
              countryError: null
            });
          } else {
            setIsValidCountry(true);
            errors['countryError'] = 'Enter Valid Country';
            setValidationErrors(errors);
          }
        }
      };

      const handleCityStateCountryChange = (event, name) => {
        const value = event.target.value;

        // Check if the input contains only letters
        if (/^[a-zA-Z]+$/.test(value) || value === '') {
        }
      };
      const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
      const checkedIcon = <CheckBoxIcon fontSize='small' />;
      return (
        <Grid sx={{ height: '53vh' }}>
          <Grid sx={{ backgroundColor: '#e9e9e9', borderRadius: 2, mt: 3 }}>
            <Grid sx={{ height: 120 }}>
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleAgencyImageUpload}
                  style={{ display: 'none' }}
                  id='image-upload'
                />
                <label htmlFor='image-upload'>
                  {agencyImage ? (
                    <img
                      className='Admin_Img'
                      src={agencyImage}
                      alt=''
                      width='200'
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                        cursor: 'pointer'
                      }}
                    />
                  ) : (
                    <span
                      role='img'
                      aria-label='upload icon'
                      className='Admin_Img'
                      style={{
                        display: 'inline-block',
                        borderRadius: '50%',
                        backgroundColor: '#e5e5e5',
                        textAlign: 'center',
                        lineHeight: '120px',
                        cursor: 'pointer'
                      }}
                    >
                      <UploadIcon />
                      Upload
                    </span>
                  )}
                </label>
              </div>
            </Grid>
          </Grid>

          <Grid sx={{ mt: 6 }}>
            <TextField
              variant='standard'
              FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
              error={Boolean(validationErrors['carrierNameError'])}
              helperText={validationErrors['carrierNameError']}
              onChange={e => {
                setCarrierName(e.target.value);
                setValidationErrors({
                  ...validationErrors,
                  carrierNameError: null
                });
              }}
              value={carrierName}
              required
              // InputLabelProps={{ style: { fontSize: 13 } }}
              inputProps={{ style: { fontSize: 13 } }}
              sx={{ width: '42vw' }}
              size='small'
              label='Carrier Name'
              type='text'
            />
            <Grid
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <Autocomplete
                sx={{ width: '20vw', mt: 0 }}
                size='small'
                value={carrierType}
                options={carrierTypes}
                onChange={(event, newValue) =>
                  handleCarrierTypeChnage(event, newValue)
                }
                groupBy={option => option.type}
                getOptionLabel={option => option.title}
                renderOption={(props, option) => (
                  <li style={{ fontSize: 13 }} {...props}>
                    {option.title}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    required
                    error={Boolean(validationErrors['carrierType'])}
                    helperText={validationErrors['carrierType']}
                    label='Carrier Type'
                    variant='standard'
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 13 }
                    }}
                  />
                )}
              />

              <Autocomplete
                sx={{ width: '20vw', mt: 0 }}
                size='small'
                value={agencyActiveValue}
                onChange={(event, newValue) =>
                  handleAgencyStatusChange(event, newValue)
                }
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    {option}
                  </Box>
                )}
                options={options}
                renderInput={params => (
                  <TextField
                    label='Status'
                    variant='standard'
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 13 }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                variant='standard'
                onChange={e => setAddress1(e.target.value)}
                value={address1}
                fullWidth
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                size='small'
                label='Address Line 1'
                type='text'
                sx={{ width: '20vw' }}
              />
              <TextField
                sx={{ width: '20vw' }}
                variant='standard'
                onChange={e => setAddress2(e.target.value)}
                value={address2}
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                fullWidth
                size='small'
                label='Address Line 2'
                type='text'
              />
            </Grid>
            <Grid
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['zipError'])}
                helperText={validationErrors['zipError']}
                onChange={e => {
                  setZip(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    zipError: null
                  });
                }}
                value={zip}
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                sx={{ width: '20vw' }}
                size='small'
                label='Zip'
                type='number'
              />
              <TextField
                variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['cityError'])}
                helperText={validationErrors['cityError']}
                onChange={e => {
                  handleStateProvinceChange(e.target.value, 'city');
                }}
                value={city}
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                sx={{ width: '20vw' }}
                size='small'
                label='City'
                type='text'
              />
            </Grid>
            <Grid
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['stateError'])}
                helperText={validationErrors['stateError']}
                onChange={e => {
                  handleStateProvinceChange(e.target.value, 'state');
                }}
                value={state}
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                sx={{ width: '20vw', mr: 0 }}
                size='small'
                label='State / Province'
                type='text'
              />
              <TextField
                variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['countryError'])}
                helperText={validationErrors['countryError']}
                onChange={e => {
                  handleStateProvinceChange(e.target.value, 'country');
                }}
                value={country}
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                sx={{ width: '20vw' }}
                size='small'
                label='Country'
                type='text'
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    if (index === 1) {
      const handleAdminStatusChange = (event, newValue) => {
        setAdminActiveValue(newValue);
        if (newValue === 'Active') {
          setAdminStatus(true);
        } else {
          setAdminStatus(false);
        }
      };

      const handlePhoneNumberPaste = event => {
        // Prevent the default paste behavior
        event.preventDefault();

        // Get the pasted text from clipboard
        const pastedText = event.clipboardData.getData('text/plain');

        // Remove non-numeric characters from pasted text
        const cleanedText = pastedText.replace(/\D/g, '');

        // Apply formatting logic
        const formattedValue = cleanedText.replace(
          /^(\d{3})(\d{3})(\d{4})$/,
          '($1) $2-$3'
        );
        setPhoneNumber(formattedValue);

        // Perform validation on the formatted value
        const errors = {};
        const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
        const isValidPhoneNumber = phoneRegex.test(formattedValue);

        if (isValidPhoneNumber) {
          setPhoneNumberValidError(false);
          setValidationErrors({ ...validationErrors, phoneNumberError: null });
        } else {
          setPhoneNumberValidError(true);
          errors['phoneNumberError'] =
            'Enter Valid Phone Number (XXX) XXX-XXXX';
          setValidationErrors(errors);
        }
      };

      const handlePhoneNumberChange = event => {
        const inputValue = event.target.value;
        // Remove non-numeric characters and retain formatting
        const formattedValue = inputValue
          .replace(/\D/g, '')
          .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
        setPhoneNumber(formattedValue);

        // Perform validation on the formatted value
        const errors = {};
        const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
        const isValidPhoneNumber = phoneRegex.test(formattedValue);

        if (isValidPhoneNumber) {
          setPhoneNumberValidError(false);
          setValidationErrors({ ...validationErrors, phoneNumberError: null });
        } else {
          setPhoneNumberValidError(true);
          errors['phoneNumberError'] =
            'Enter Valid Phone Number (XXX) XXX-XXXX';
          setValidationErrors(errors);
        }
      };

      const validatePhoneNumber = inputValue => {
        // Regular expression to match the USA phone number format (e.g., (123) 456-7890 or 123-456-7890)
        const phoneRegex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

        const isValidPhoneNumber = phoneRegex.test(inputValue);
        if (isValidPhoneNumber) {
          setValidationErrors({ ...validationErrors, phoneNumberError: null });
        }
        setIsValid(isValidPhoneNumber);
      };
      const handleEmailValidation = email => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const errors = {};
        setEmail(email);

        if (emailRegex.test(email)) {
          setIsValidEmail(true);
          setValidationErrors({
            ...validationErrors,
            emailError: null
          });
        } else {
          setIsValidEmail(false);
          errors['emailError'] = 'Enter Valid Email';
        }
        setValidationErrors(errors);
      };
      if (createAgencyLoader) {
        return (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '55vh',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </Grid>
        );
      } else
        return (
          <Grid sx={{ height: '52vh' }}>
            <Grid sx={{ backgroundColor: '#e9e9e9', borderRadius: 2, mt: 3 }}>
              <Grid sx={{ height: 120 }}>
                <div>
                  <input
                    // type='file'
                    // accept='image/*'
                    // onChange={handleAdminImageUpload}
                    style={{ display: 'none' }}
                    id='image-upload'
                  />
                  <label htmlFor='image-upload'>
                    <img
                      src={`data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">${encodeURIComponent(
                        firstLetter + lastLetter
                      )}</text></svg>`}
                      // src={imageUrl}
                      alt={firstLetter}
                      style={imageStyle}
                    />
                  </label>
                </div>
              </Grid>
            </Grid>

            <Grid sx={{ mt: 7 }}>
              <Grid
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <TextField
                  disabled={EditedData?.adminName ? true : false}
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['firstNameError'])}
                  helperText={validationErrors['firstNameError']}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z]*$/.test(inputValue)) {
                      setFirstName(inputValue);
                      setValidationErrors({
                        ...validationErrors,
                        firstNameError: null
                      });
                    }
                  }}
                  value={firstName}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='First Name'
                  type='text'
                />
                <TextField
                  disabled={EditedData?.adminName ? true : false}
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['lastNameError'])}
                  helperText={validationErrors['lastNameError']}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z]*$/.test(inputValue)) {
                      setLastName(inputValue);
                      setValidationErrors({
                        ...validationErrors,
                        lastNameError: null
                      });
                    }
                  }}
                  value={lastName}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='Last Name'
                  type='text'
                />
              </Grid>
              <Grid
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <TextField
                  disabled={EditedData?.adminName ? true : false}
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['designation'])}
                  helperText={validationErrors['designation']}
                  onChange={e => {
                    setDesignation(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      designation: null
                    });
                  }}
                  value={designation}
                  // required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw', mr: 0 }}
                  size='small'
                  label='Designation'
                  //   type='email'
                />
                <TextField
                  disabled={EditedData?.adminName ? true : false}
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['emailError'])}
                  helperText={validationErrors['emailError']}
                  onChange={e => handleEmailValidation(e.target.value)}
                  value={email}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw', mr: 0 }}
                  size='small'
                  label='Email Address'
                  type='email'
                />
              </Grid>
              <Grid
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <TextField
                  disabled={EditedData?.adminName ? true : false}
                  variant='standard'
                  FormHelperTextProps={{
                    style: {
                      margin: ' 5 30 0 0',
                      display: 'flex',
                      flexWrap: 'nowrap'
                    }
                  }}
                  error={Boolean(validationErrors['phoneNumberError'])}
                  helperText={validationErrors['phoneNumberError']}
                  onChange={handlePhoneNumberChange}
                  onPaste={handlePhoneNumberPaste}
                  value={phoneNumber}
                  required
                  sx={{ width: '20vw' }}
                  size='small'
                  label='Phone Number'
                  type='text'
                  inputProps={{
                    maxLength: 14, // Changed maxLength to accommodate formatted phone numbers
                    style: { fontSize: 13 } // Set the maximum length to 10 characters
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        );
    }
    if (index === 2) {
      return (
        <Grid sx={{ minHeight: '55vh' }}>
          {carriersLoader ? (
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '55vh',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid sx={{ pt: '2vh' }}>
              <Grid>
                <h5 style={{ fontSize: 16 }}>
                  <u>Detail</u>
                </h5>
                <Row>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Carrier Name</span>
                    <br /> {carrierName}
                  </Col>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Carrier Type</span> <br />{' '}
                    {carrierType.type}
                  </Col>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Carrier Status</span> <br />{' '}
                    {agencyActiveValue}
                  </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Address 1</span> <br />{' '}
                    {address1}
                  </Col>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Address 2</span> <br />{' '}
                    {address2}
                  </Col>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Zip</span> <br /> {zip}
                  </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>City</span> <br /> {city}
                  </Col>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>State/Province</span> <br />{' '}
                    {state}
                  </Col>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Country</span> <br />{' '}
                    {country}
                  </Col>
                </Row>
              </Grid>
              <Grid sx={{ mt: 2 }}>
                <h5 style={{ fontSize: 16 }}>
                  <u>Contact Info</u>
                </h5>
                <Row>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>First Name</span>
                    <br /> {firstName}
                  </Col>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Last Name</span> <br />{' '}
                    {lastName}
                  </Col>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Designation</span> <br />{' '}
                    {designation}
                  </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Phone</span> <br />{' '}
                    {phoneNumber}
                  </Col>
                  <Col lg={4} xl={4}>
                    <span className='Preview_Info'>Email</span>
                    <br /> {email}
                  </Col>
                </Row>
              </Grid>
            </Grid>
          )}
        </Grid>
      );
    }
  };
  return (
    <Box
      sx={{
        p: 2,
        minHeight: '55vh',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <ToastContainer
        position='top-center'
        autoClose={3800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Paper className='Grid_Item'>
        <Box sx={{ width: '42vw' }}>
          <Stepper
            // className='PickupStepperParent'
            activeStep={activeStep}
            alternativeLabel
            // nonLinear
          >
            {CreateAgencySteps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    color='inherit'
                    onClick={handleStep(index, label)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {handleStepperContent(activeStep, activeTabLabel)}
          <div className={'NextBtnDiv2'}>
            {activeStep !== 0 && (
              <Button
                size='small'
                onClick={shipmentFailed ? handleBackShipment : handleBack}
                // className='BtnColor'
                variant='outlined'
              >
                <ChevronLeftIcon sx={{ mr: 0 }} className='PrevBtnIcon' />{' '}
                Previous
              </Button>
            )}

            <Button
              size='small'
              sx={{ ml: 2 }}
              onClick={() => handleNext(activeStep)}
              className='BtnColor'
              variant='contained'
            >
              {' '}
              {activeStep === CreateAgencySteps.length - 1
                ? EditedData
                  ? 'Update'
                  : 'Create'
                : 'Continue'}
              <ChevronRightIcon sx={{ ml: 0 }} className='NextBtnIcon' />
            </Button>
          </div>
        </Box>
      </Paper>
    </Box>
  );
}
