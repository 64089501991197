import React from 'react';

function Subscriptions() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '90%' }}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <h1>Subscriptions</h1>
      </div>
    </div>
  );
}

export default Subscriptions;
