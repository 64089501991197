import React from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { AdminForgot } from '../../apis/Admin';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { useState } from 'react';
import { successToast, ErrorToast } from '../../components/Toasts';
import { ToastContainer, toast } from 'react-toastify';
import './Common.css';

const ResetPassword = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [usernameOrEmail, setUsernameOrEmail] = useState('');

  const handleSuccessResetPass = res => {
    setIsLoading(false);
    successToast(res.data.reasonPhrase);
    setTimeout(() => {
      history.push('/signin');
    }, 1500);
  };

  const handleErrorResetPass = err => {
    setIsLoading(false);
    ErrorToast(err.response.data.reasonPhrase);
  };
  const handleForgotPass = e => {
    setIsLoading(true);
    e.preventDefault();
    AdminForgot(usernameOrEmail)
      .then(res => res.status === 200 && handleSuccessResetPass(res))
      .catch(err => handleErrorResetPass(err, 'AagencyForgot'));
  };
  return (
    <React.Fragment>
      <ToastContainer
        position='top-center'
        autoClose={4800}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <MetaTags>
          <title>Reset Password | TMS Admin</title>
        </MetaTags>
        <section className='bg-auth' style={{ backgroundColor: '#EEF3F9' }}>
          <Container style={{ display: 'flex', justifyContent: 'center' }}>
            <Col xl={6} lg={6}>
              <Card className='auth-box box_shadow'>
                <CardBody className='auth-content p-5 h-100 text-white'>
                  <div className='text-center mb-4'></div>
                  <Form
                    style={{ alignSelf: 'center' }}
                    className='auth-form text-white'
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        marginBottom: 30
                      }}
                    >
                      {' '}
                      <h3>Forgot your password?</h3>
                      <h7>
                        Enter your email and we'll send you a reset links.
                      </h7>
                    </div>

                    <div className='mb-4'>
                      <Input
                        required
                        value={usernameOrEmail}
                        type='email'
                        className='form-control'
                        id='email'
                        placeholder='Enter username or email'
                        onChange={e => setUsernameOrEmail(e.target.value)}
                      />
                    </div>
                    <div className='mt-3'>
                      <button
                        disabled={usernameOrEmail ? false : true}
                        onClick={e => handleForgotPass(e)}
                        type='submit'
                        className='btn btn-white w-100'
                      >
                        {isLoading ? 'Loading ...' : 'Send reset link'}
                      </button>
                    </div>
                  </Form>
                  <div className='mt-5 text-center text-white-50'>
                    <p>
                      Remembered It ?{' '}
                      <Link
                        to='/signin'
                        className='fw-medium text-white text-decoration-underline'
                      >
                        {' '}
                        Go to Login{' '}
                      </Link>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
