import React from 'react';

function Reports() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '90%' }}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <h1>Manage Users</h1>
      </div>
    </div>
  );
}

export default Reports;
