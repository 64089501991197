import React, { Suspense } from 'react';
import NavBar from '../CommonLayout/NavBar';
import SignIn from '../../pages/ExtraPages/SignIn';
import { useEffect } from 'react';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setEmailRedux,
  setPasswordRedux,
  setUserToken
} from '../../Redux/UserSlice';
const Layout = props => {
  var layoutMode = document.body.getAttribute('data-layout-mode');

  const history = useHistory();
  const [state, setState] = useState('Active');
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const UserId = useSelector(state => state.userDetails.token);
  // const rememberMe = useSelector(state => state.userDetails.rememberMe);
  // const tokenExpiry = localStorage.getItem('AdminLoginId');

  // const tokenExpiryTime = new Date(tokenExpiry);
  // const currentTime = new Date();
  // setTimeout(() => {
  //   if (tokenExpiryTime - currentTime <= 0) {
  //     window.location.href = '/Admin-locked-screen';
  //     localStorage.clear();
  //     !rememberMe && dispatch(setPasswordRedux(''));
  //     !rememberMe && dispatch(setEmailRedux(''));
  //     dispatch(setUserToken(''));
  //   }
  // }, tokenExpiryTime - currentTime);

  const onIdle = () => {
    setState('Idle');
    if (UserId) {
      // alert('locked');
      history.push('/Admin-locked-screen');
    }
  };

  const onActive = () => {
    setState('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    // timeout: 6000,
    timeout: 3600000,
    throttle: 500
  });

  const toggleMode = () => {
    if (layoutMode === 'dark') {
      document.body.setAttribute('data-layout-mode', 'light');
    } else {
      document.body.setAttribute('data-layout-mode', 'dark');
    }
  };

  const token = localStorage.getItem('AdminAuthToken');
  const tokenTime = localStorage.getItem('AdminLoginId');

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (!token) {
        history.push('/signin'); // Redirect to signin page if token is not present
        return;
      }

      // const decodedToken = decodeToken(token);
      const expirationTime = new Date(tokenTime).getTime();

      if (Date.now() >= expirationTime) {
        history.push('/signin'); // Token is expired, redirect to signin page
        return;
      }

      // Schedule the next token expiration check after a certain interval
      const checkInterval = 30000; // Check every 1 minute (adjust as needed)
      setTimeout(checkTokenExpiration, checkInterval);
    };

    // Initial token expiration check
    checkTokenExpiration();
  }, []);

  return (
    <React.Fragment>
      <Suspense>
        <div
          style={
            layoutMode === 'dark'
              ? { backgroundColor: '#303841', height: '100vh' }
              : { backgroundColor: '#EDF2F9', height: '100vh' }
          }
        >
          {UserId !== '' && <NavBar toggleMode={toggleMode} />}

          {UserId !== '' ? props.children : <SignIn />}
        </div>
      </Suspense>
    </React.Fragment>
  );
};

export default Layout;
