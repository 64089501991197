import MaterialTable, { MTableBodyRow } from 'material-table';
import { useState, React, useRef, useEffect } from 'react';

import ViewColumnOutlined from '@material-ui/icons/ViewColumn';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ClearSearchIcon from '@material-ui/icons/Clear';
// import './print.css';
import { Link, useHistory } from 'react-router-dom';
import // GetCarriers,
'../../../apis/Admin';
import { useDispatch } from 'react-redux';
import { setShipmentDetails } from '../../../Redux/ViewShipmentsSlice';
import { ErrorToast, successToast } from '../../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddIcon from '@mui/icons-material/Add';
import './ShipmentsTable.css';
import { useReactToPrint } from 'react-to-print';
import Papa from 'papaparse';
import { useSelector } from 'react-redux';
import { base_logo_url, base_url } from '../../../apis/constants';
import {
  setCarrierApiEndPoints,
  setCarrierApiNames
} from '../../../Redux/CarrierApiNameSlice';

import {
  Box,
  Chip,
  CircularProgress,
  createFilterOptions,
  TextField,
  Button,
  Grid,
  Avatar,
  AvatarGroup
} from '@mui/material';
// import TextField from '@material-ui/core/TextField';

import { createStyles, makeStyles } from '@material-ui/core';
// import { Button } from 'reactstrap';
import { GetAgencies } from '../../../apis/Admin';
import { render } from 'react-dom';
// import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';

export function BasicTreeData({ PreviouProps }) {
  const styles = makeStyles(() =>
    createStyles({
      row: {
        '& button': {
          display: 'none'
        },
        '&:hover': {
          '& button': {
            display: 'block'
          }
        }
        // backgroundColor: 'white'
      }
    })
  );
  const getRowStyle = rowData => {
    // Apply your conditions here
    if (onclickSelectedRow === rowData.tableData.id) {
      return classes.customRowStyle; // Apply custom style
    }

    return classes.rowStyle; // Apply default style
  };

  const [columnFilters, setColumnFilters] = useState({});
  const [shipmentsLoader, setShipmentsLoader] = useState(false);

  const [agenciesDataArray, setAgenciesDataArray] = useState([]);

  // Load the column filters from storage on component mount
  const hadnleSuccessGetAgencies = ApiRes => {
    setShipmentsLoader(false);
    const ApiResAgencies = ApiRes?.data?.data;
    const tempArr = [];
    for (let index = 0; index < ApiResAgencies.length; index++) {
      const Item = ApiResAgencies[index];
      const Zip = ApiResAgencies[index].zipCode;
      const City = ApiResAgencies[index].city;
      const State = ApiResAgencies[index].state;
      const Country = ApiResAgencies[index].country;
      const AdminFirstName = ApiResAgencies[index].adminInfo.firstName;
      const AdminLastName = ApiResAgencies[index].adminInfo.lastName;

      const updatedArray = {
        ...Item,
        completeAddress: City + ' ' + State + ' ' + Zip + ' ' + Country,
        adminName: AdminFirstName + ' ' + AdminLastName
      };
      tempArr.push(updatedArray);
    }
    const filteredAgencies = tempArr.slice().reverse();
    setAgenciesDataArray(filteredAgencies);
  };

  useEffect(() => {
    setShipmentsLoader(true);
    GetAgencies()
      .then(res => res.status === 200 && hadnleSuccessGetAgencies(res))
      .catch(err => {
        setShipmentsLoader(false);
        ErrorToast(err?.message);
      });
  }, []);

  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const dispatch = useDispatch();

  const [viewShipmentsData, setViewShipmentsData] = useState([]);

  const handleGetSynchDataRecords = () => {
    // GetShipments()
    //   .then(res => res.status === 200 && handleSuccessShipDetails(res))
    //   .catch(err => setShipmentsLoader(false));
  };

  useEffect(() => {
    // GetShipments()
    //   .then(res => res.status === 200 && handleSuccessShipDetails(res))
    //   .catch(err => setShipmentsLoader(false));
    // GetCarriers(AgencyID)
    //   .then(res => res.status === 200 && handleGetCarrierNames(res.data.data))
    //   .catch(err => console.log('Error here...', err));
  }, []);

  const history = useHistory();

  const tableRef = useRef();

  const [onclickSelectedRow, setOnclickSelectedRow] = useState('');
  const [addiTopBar, setShowAddiTopBar] = useState(false);

  const handleSelectedRow = selectedRow => {
    setOnclickSelectedRow(selectedRow.tableData.id);
    if (selectedRow) {
      setShowAddiTopBar(true);
    } else if (onclickSelectedRow === selectedRow.tableData.id) {
      setOnclickSelectedRow(null);
      setShowAddiTopBar(false);
    }
  };
  !onclickSelectedRow === null &&
    setTimeout(() => {
      setOnclickSelectedRow(null);
    }, 5000);
  const ActiveAgency = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""'
      }
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0
      }
    }
  }));
  const InActiveAgency = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: 'rgb(193, 193, 193)',
      color: 'rgb(193, 193, 193)',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
    }
  }));
  const ConfiguredCarriers = carriers => {
    const ArrayofCarriers = carriers?.carriers;
    const ActiveAgencyCarriers = ArrayofCarriers.filter(items => items.active);
    console.log(ActiveAgencyCarriers.length > 0, 'ActiveAgencyCarriers.length');

    return (
      <div style={{ display: 'flex' }}>
        <AvatarGroup sx={{}} spacing='small' max={5}>
          {ActiveAgencyCarriers.map((items, index) => (
            <Avatar
              sx={{
                bgcolor: 'ButtonFace',
                objectFit: 'contain'
              }}
              imgProps={{
                style: {
                  height: 55
                }
              }}
              src={base_logo_url + items.icon}
            />
          ))}
        </AvatarGroup>
      </div>
    );
  };
  const columns = [
    {
      title: 'Agency Name',
      field: 'company',
      render: list => {
        // if (list.logo.includes('Logos')) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {list.active === true ? (
              <ActiveAgency
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant='dot'
              >
                <Avatar
                  className='Agency_Profile_Img'
                  src={base_logo_url + list.logo}
                  alt=''
                />
              </ActiveAgency>
            ) : (
              <InActiveAgency
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant='dot'
              >
                <Avatar
                  className='Agency_Profile_Img'
                  src={base_logo_url + list.logo}
                  alt=''
                />
              </InActiveAgency>
            )}
            <span style={{ marginLeft: 15 }}>{list.company}</span>
          </div>
        );
        // }
      }
    },

    // { title: 'Name', field: 'company' },
    {
      title: 'Address',
      field: 'address1',
      render: rowData => (
        <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
          {rowData.address1} <br />
          {rowData.address2}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.address1.toLowerCase().includes(term.toLowerCase()) ||
        rowData.address2.toLowerCase().includes(term.toLowerCase())
    },
    {
      title: 'City / State / Zip / Country',
      field: 'completeAddress',
      render: rowData => (
        <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
          {rowData.completeAddress}
        </div>
      )
    },
    {
      title: 'Agency Admin',
      field: 'adminName',
      render: rowData => (
        <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
          {rowData.adminName}
        </div>
      )
    },
    {
      title: 'Admin Email',
      field: 'adminLoginId',
      render: list => (
        <a
          style={{ color: 'rgba(0, 0, 0, 0.87)' }}
          href={`mailto: ${list.adminLoginId}`}
        >
          {' '}
          {list.adminLoginId}{' '}
        </a>
      )
    },
    {
      title: 'Phone',
      field: 'adminInfo.phone',
      render: list => (
        <a
          style={{ color: 'rgba(0, 0, 0, 0.87)', whiteSpace: 'nowrap' }}
          href={`tel: +1${list.adminInfo.phone}`}
        >
          {' '}
          {list.adminInfo.phone}{' '}
        </a>
      )
    },

    // {
    //   title: 'Modules',
    //   field: 'modules'
    // },
    {
      title: 'Subscription',
      field: 'subscriptionPlanId',
      render: list => {
        if (list.subscriptionPlanId === 1) {
          return 'Basic Package';
        }
      }
    },
    {
      title: 'Carriers',
      field: 'carriers',
      render: list => {
        return <ConfiguredCarriers carriers={list.carriers} />;
      }
    },
    {
      title: 'Status',
      field: 'active',
      render: list => {
        if (list.active === true) {
          return <span style={{ color: 'green' }}>ACTIVE</span>;
        } else {
          return 'IN-ACTIVE';
        }
      }
    },
    {
      title: 'Actions',
      field: '',
      render: list => {
        return (
          <button
            onClick={() => history.push('create-agency', list)}
            type='button'
            class='btn btn-success btn-sm'
          >
            View / Update
          </button>
        );
      }
    }
  ];

  const rando = max => Math.floor(Math.random() * max);
  const words = ['Paper', 'Rock', 'Scissors'];
  const rawData = [];

  for (let i = 0; i < 100; i++)
    rawData.push({ id: rando(300), name: words[i % words.length] });

  const handleRowClicked = (selectedRow, togglePanel) => {
    handleSelectedRow(selectedRow);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current?.childNodes[3]?.childNodes[1]
  });

  const fileInputRef = useRef(null);

  // tabs state
  const handleFilteredTableData = () => {
    if (value === 0) {
      const FilteredShipments = viewShipmentsData.filter(
        items =>
          items.shipmentStatus === 'Saved' || items.shipmentStatus === 'UNBOOK'
      );
      return FilteredShipments;
    } else if (value === 1) {
      const FilteredShipments = viewShipmentsData.filter(
        items =>
          items.shipmentStatus === 'Booked' ||
          items.shipmentStatus.toUpperCase() === 'HOLD'
      );
      return FilteredShipments;
    } else if (value === 2) {
      const FilteredShipments = viewShipmentsData.filter(
        items => items.shipmentStatus === 'InTransit'
      );
      return FilteredShipments;
    } else {
      const FilteredShipments = viewShipmentsData.filter(
        items => items.shipmentStatus === 'Delivered'
      );
      return FilteredShipments;
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const [value, setValue] = useState(0);

  useEffect(() => {
    handleFilteredTableData();
  }, [value]);

  const [filterValue, setFilterValue] = useState('');

  console.log(filterValue, 'filterValue');
  const classes = styles();
  const handleCreateAgency = () => {
    history.push('/create-agency');
  };
  return (
    <div ref={componentRef}>
      <ToastContainer
        position='top-center'
        autoClose={5800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/icon?family=Material+Icons'
      />
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 1 }}>
        <button
          onClick={() => handleCreateAgency()}
          type='button'
          class='btn btn-primary btn-sm'
        >
          Create
        </button>
      </Box>
      <MaterialTable
        className={classes.row}
        title='Agencies'
        id='ifmcontentstoprint'
        tableRef={tableRef}
        icons={{
          Export: () => <ExitToAppIcon style={{ color: '#6BA3E9 ' }} />,
          ViewColumn: () => (
            <ViewColumnOutlined style={{ color: '#6BA3E9', marginRight: 0 }} />
          ),
          Search: () => (
            <SearchIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
          ),
          ResetSearch: () => (
            <ClearSearchIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
          ),
          Filter: () => (
            <FilterAltIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
          ),

          Check: () => (
            <ClearSearchIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
          )
          // PrintIcon: (props, ref) => <MUIPrintIcon {...props} ref={ref} />
        }}
        onRowClick={(evt, selectedRow, togglePanel) =>
          handleRowClicked(selectedRow, togglePanel)
        }
        // actions={[
        //   {
        //     icon: () => (
        //       <div>
        //         <UploadFileIcon
        //           onClick={() => fileInputRef.current.click()}
        //           style={{ color: '#6BA3E9 ' }}
        //         />
        //         <input
        //           ref={fileInputRef}
        //           type='file'
        //           style={{ display: 'none' }}
        //           onChange={handleFileUpload}
        //         ></input>
        //       </div>
        //     ),
        //     tooltip: 'Import CSV',
        //     isFreeAction: true
        //   },
        //   {
        //     icon: () => <MUIPrintIcon style={{ color: '#6BA3E9 ' }} />,
        //     tooltip: 'Print',
        //     isFreeAction: true,
        //     // onClick: () => printDiv('printpage')
        //     onClick: () => handlePrint()
        //   },
        //   {
        //     icon: () => <SynchIcon style={{ color: '#6BA3E9 ' }} />,
        //     tooltip: 'Refresh',
        //     isFreeAction: true,
        //     onClick: () => window.location.reload()
        //   },

        //   rowData => handleActionbuttons(rowData)
        // ]}
        // components={{
        //   Row: props => {
        //     return (
        //       <MTableBodyRow
        //         className={classes.row}
        //         {...props}
        //         onMouseEnter={e => handleRowHover(e, props)}
        //         onMouseLeave={e => handleRowHoverLeave(e, props)}
        //       />
        //     );
        //   }
        // }}
        localization={{
          toolbar: {
            showColumnsAriaLabel: 'Show Columns',
            exportTitle: 'Export',
            exportAriaLabel: 'Export',
            exportName: 'Export as CSV',
            exportCSVName: 'Export as CSV',
            exportPDFName: 'Export as PDF'
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}'
          },
          header: {
            actions: null
          },
          body: {
            emptyDataSourceMessage: shipmentsLoader ? (
              <CircularProgress />
            ) : (
              'No Records Found'
            ),
            filterRow: {
              filterTooltip: 'Filter'
            }
          }
        }}
        options={{
          filterCellStyle: '',
          search: true,
          paging: true,
          maxBodyHeight: '80vh',
          pageSize: 10,
          columnsButton: true,
          filtering: true,
          headerStyle: {
            fontSize: 12,
            fontWeight: 700,
            backgroundColor: '#EDF2F9',
            whiteSpace: 'nowrap'
            // textAlign: 'center'
          },
          rowStyle: rowData => ({
            fontSize: 12,
            textAlign: 'center',
            backgroundColor:
              //   rowData.tableData.id === hoveringOver
              //     ? '#ebf2ff'
              // :
              onclickSelectedRow === rowData.tableData.id ? '#d0e0ff' : '#FFF'
          })
          // rowStyle: rowData => getRowStyle(rowData),
          // exportAllData: true,
          // exportButton: true
        }}
        // detailPanel={[
        //   rowData => ({
        //     visible: rowData => {
        //       return true;
        //     },
        //     disabled: false,
        //     icon: () => (
        //       <VisibilityIcon
        //         style={{ color: '#6BA3E9' }}
        //       />
        //     ),
        //     tooltip: 'View Shipment Details',
        //     openIcon: () => (
        //       <VisibilityIcon
        //         style={{ color: '#6BA3E9' }}
        //       />
        //     ),
        //     render: () => (
        //       <div>
        //         <BasicTabs
        //           TableTitle={TableTitle()}
        //           viewShipmentsDetailedData={
        //             handleFilteredTableData()[rowData.tableData.id]
        //           }
        //         />
        //       </div>
        //     )
        //   })
        // ]}
        data={agenciesDataArray}
        columns={columns}
      ></MaterialTable>
    </div>
  );
}
