import React from 'react';
import MetaTags from 'react-meta-tags';
// import ExpandAbleTable from './ExpandAbleTable';
import { Typography } from '@mui/material';
import { Box } from '@material-ui/core';
// import ControlPinnedColumns, { BasicTreeData, TestTable } from "./TestTable";
const Layout2 = props => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Admin TMS </title>
      </MetaTags>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90%'
        }}
      >
        <Typography variant='h3'>Admin Dashboard </Typography>
      </Box>
    </React.Fragment>
  );
};
export default Layout2;
