import { createSlice } from '@reduxjs/toolkit';

export const GetServicesSwitchSlice = createSlice({
  name: 'GetServicesSwitchSlice',
  initialState: {
    PickUpCheckedRedux: false,
    DeliveryCheckedRedux: false
  },
  reducers: {
    setPickUpCheckedRedux: (state, action) => {
      state.PickUpCheckedRedux = action.payload;
    },
    setDeliveryCheckedRedux: (state, action) => {
      state.DeliveryCheckedRedux = action.payload;
    }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const { setPickUpCheckedRedux, setDeliveryCheckedRedux } =
  GetServicesSwitchSlice.actions;
