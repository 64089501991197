import MaterialTable, { MTableBodyRow } from 'material-table';
import { useState, React, useRef, useEffect } from 'react';

import ViewColumnOutlined from '@material-ui/icons/ViewColumn';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ClearSearchIcon from '@material-ui/icons/Clear';
// import './print.css';
import { Link, useHistory } from 'react-router-dom';
import // GetCarriers,
'../../apis/Admin';
import { useDispatch } from 'react-redux';
import { setShipmentDetails } from '../../Redux/ViewShipmentsSlice';
import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import './Carriers.css';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';

import { AvatarGroup, Badge, Box, Chip, CircularProgress } from '@mui/material';
// import TextField from '@material-ui/core/TextField';

import { Avatar, createStyles, makeStyles } from '@material-ui/core';
// import { Button } from 'reactstrap';
import { AdminGetCarriers, GetAgencies } from '../../apis/Admin';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { base_logo_url } from '../../apis/constants';

function Carriers({ PreviouProps }) {
  const styles = makeStyles(() =>
    createStyles({
      row: {
        '& button': {
          display: 'none'
        },
        '&:hover': {
          '& button': {
            display: 'block'
          }
        }
        // backgroundColor: 'white'
      }
    })
  );
  const getRowStyle = rowData => {
    // Apply your conditions here
    if (onclickSelectedRow === rowData.tableData.id) {
      return classes.customRowStyle; // Apply custom style
    }

    return classes.rowStyle; // Apply default style
  };

  const [columnFilters, setColumnFilters] = useState({});
  const [shipmentsLoader, setShipmentsLoader] = useState(true);

  const [agenciesDataArray, setAgenciesDataArray] = useState([]);
  const [AllCarriers, setAllCArriers] = useState([]);
  const [LTLCarriers, setLTLCarriers] = useState([]);
  const [TLCarriers, setTLCarriers] = useState([]);
  const [TankerCarriers, setTankerCarriers] = useState([]);

  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const dispatch = useDispatch();

  const [viewShipmentsData, setViewShipmentsData] = useState([]);

  const handleSuccessShipDetails = ApiRes => {
    setShipmentsLoader(false);
    console.log(ApiRes, 'ApiRes');
    const allCarriers = ApiRes?.data?.data.reverse();
    const LTL = allCarriers.filter(items => items.cType === 'LTL');
    const TL = allCarriers.filter(items => items.cType === 'TL');
    const ParcelCarriers = allCarriers.filter(
      items => items.cType === 'Parcel'
    );
    setLTLCarriers(LTL);
    setTLCarriers(TL);
    setTankerCarriers(ParcelCarriers);
    setAgenciesDataArray(allCarriers);
    setAllCArriers(allCarriers);
  };

  const GetCarrierRequest = value => {
    AdminGetCarriers(value)
      .then(res => res.status === 200 && handleSuccessShipDetails(res))
      .catch(err => setShipmentsLoader(false));
  };
  useEffect(() => {
    GetCarrierRequest(3);
  }, []);

  const history = useHistory();

  const tableRef = useRef();

  const [onclickSelectedRow, setOnclickSelectedRow] = useState('');
  const [addiTopBar, setShowAddiTopBar] = useState(false);

  const handleSelectedRow = selectedRow => {
    setOnclickSelectedRow(selectedRow.tableData.id);
    if (selectedRow) {
      setShowAddiTopBar(true);
    } else if (onclickSelectedRow === selectedRow.tableData.id) {
      setOnclickSelectedRow(null);
      setShowAddiTopBar(false);
    }
  };
  !onclickSelectedRow === null &&
    setTimeout(() => {
      setOnclickSelectedRow(null);
    }, 5000);

  const columns = [
    {
      title: 'Logo',
      field: 'icon',
      render: list => (
        <div style={{ display: 'flex' }}>
          {/* <AvatarGroup sx={{}} spacing='small'> */}
          <Avatar
            sx={{
              bgcolor: 'ButtonFace',
              objectFit: 'contain'
            }}
            imgProps={{
              style: {
                height: 55
              }
            }}
            src={base_logo_url + list.icon}
          />
          {/* </AvatarGroup> */}
        </div>
      )
    },

    {
      title: 'Carrier Name',

      field: 'name'
    },
    {
      title: 'Type',
      field: 'cType'
    },
    {
      title: 'SubType',
      field: 'cSubType'
    },
    {
      title: 'Actions',
      field: '',
      render: list => {
        if (list.cType !== 'LTL') {
          return (
            <button
              onClick={() => history.push('create-carrier', list)}
              type='button'
              class='btn btn-success btn-sm'
            >
              View / Update
            </button>
          );
        }
      }
    }
  ];

  const rando = max => Math.floor(Math.random() * max);
  const words = ['Paper', 'Rock', 'Scissors'];
  const rawData = [];

  for (let i = 0; i < 100; i++)
    rawData.push({ id: rando(300), name: words[i % words.length] });

  const handleRowClicked = (selectedRow, togglePanel) => {
    handleSelectedRow(selectedRow);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current?.childNodes[3]?.childNodes[1]
  });

  const classes = styles();
  const handleCreateAgency = () => {
    history.push('/create-carrier');
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        style={{ paddingTop: 5 }}
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Typography>{children}</Typography>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setAgenciesDataArray(AllCarriers);
    }
    if (newValue === 1) {
      setAgenciesDataArray(LTLCarriers);
    }
    if (newValue === 2) {
      setAgenciesDataArray(TLCarriers);
    }
    if (newValue === 3) {
      setAgenciesDataArray(TankerCarriers);
    }
  };
  return (
    <div style={{ padding: 10 }} ref={componentRef}>
      <ToastContainer
        position='top-center'
        autoClose={5800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/icon?family=Material+Icons'
      />
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 1 }}>
        <button
          onClick={() => handleCreateAgency()}
          type='button'
          class='btn btn-primary btn-sm'
        >
          Create
        </button>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor: '#fff'
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              sx={{ fontWeight: 'bold' }}
              label=<div className='Tabs_Badge_Title'>
                <Badge
                  color='primary'
                  sx={{ padding: '0 15 0 0' }}
                  badgeContent={
                    AllCarriers.length === 0 ? '0' : AllCarriers.length
                  }
                />
                <div>All</div>
              </div>
              {...a11yProps(0)}
            />
            <Tab
              sx={{ fontWeight: 'bold' }}
              label=<div className='Tabs_Badge_Title'>
                <Badge
                  color='secondary'
                  sx={{ padding: '0 15 0 0' }}
                  badgeContent={
                    LTLCarriers.length === 0 ? '0' : LTLCarriers.length
                  }
                />
                <div>LTL</div>
              </div>
              {...a11yProps(1)}
            />
            <Tab
              sx={{ fontWeight: 'bold' }}
              label=<div className='Tabs_Badge_Title'>
                <Badge
                  color='warning'
                  sx={{ padding: '0 15 0 0' }}
                  badgeContent={
                    TLCarriers.length === 0 ? '0' : TLCarriers.length
                  }
                />
                <div>TL</div>
              </div>
              {...a11yProps(2)}
            />
            <Tab
              sx={{ fontWeight: 'bold', minWidth: 100 }}
              label=<div className='Tabs_Badge_Title'>
                <Badge
                  color='success'
                  sx={{ padding: '0 15 0 0' }}
                  badgeContent={
                    TankerCarriers.length === 0 ? '0' : TankerCarriers.length
                  }
                />
                <div>Parcel</div>
              </div>
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={value}>
          <MaterialTable
            className={classes.row}
            title={
              value === 0
                ? 'All Carriers'
                : value === 1
                ? 'LTL Carriers'
                : value === 2
                ? 'TL Carriers'
                : 'Tanker Carriers'
            }
            id='ifmcontentstoprint'
            tableRef={tableRef}
            icons={{
              Export: () => <ExitToAppIcon style={{ color: '#6BA3E9 ' }} />,
              ViewColumn: () => (
                <ViewColumnOutlined
                  style={{ color: '#6BA3E9', marginRight: 0 }}
                />
              ),
              Search: () => (
                <SearchIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
              ),
              ResetSearch: () => (
                <ClearSearchIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
              ),
              Filter: () => (
                <FilterAltIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
              ),

              Check: () => (
                <ClearSearchIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
              )
              // PrintIcon: (props, ref) => <MUIPrintIcon {...props} ref={ref} />
            }}
            onRowClick={(evt, selectedRow, togglePanel) =>
              handleRowClicked(selectedRow, togglePanel)
            }
            localization={{
              toolbar: {
                showColumnsAriaLabel: 'Show Columns',
                exportTitle: 'Export',
                exportAriaLabel: 'Export',
                exportName: 'Export as CSV',
                exportCSVName: 'Export as CSV',
                exportPDFName: 'Export as PDF'
              },
              pagination: {
                labelDisplayedRows: '{from}-{to} of {count}'
              },
              header: {
                actions: null
              },
              body: {
                emptyDataSourceMessage: shipmentsLoader ? (
                  <CircularProgress />
                ) : (
                  'No Records Found'
                ),
                filterRow: {
                  filterTooltip: 'Filter'
                }
              }
            }}
            options={{
              filterCellStyle: '',
              search: true,
              paging: true,
              maxBodyHeight: '70vh',
              pageSize: 10,
              columnsButton: true,
              filtering: true,
              headerStyle: {
                fontSize: 12,
                fontWeight: 700,
                backgroundColor: '#EDF2F9',
                whiteSpace: 'nowrap'
                // textAlign: 'center'
              },
              rowStyle: rowData => ({
                fontSize: 12,
                textAlign: 'center',
                backgroundColor:
                  //   rowData.tableData.id === hoveringOver
                  //     ? '#ebf2ff'
                  // :
                  onclickSelectedRow === rowData.tableData.id
                    ? '#d0e0ff'
                    : '#FFF'
              })
            }}
            data={agenciesDataArray}
            columns={columns}
          ></MaterialTable>
        </CustomTabPanel>
      </Box>
    </div>
  );
}

export default Carriers;
