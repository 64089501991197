import { createSlice } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
const initialState = {
  CarrierApiNames: [],
  CarrierApiEndPoints: []
};
export const CarrierApiNames = createSlice({
  name: 'CarrierApis',
  initialState,
  reducers: {
    setCarrierApiNames: (state, action) => {
      console.log(state, action, 'setCarrierApiNames, action');
      return action.payload;
    }
    // setCarrierApiEndPoints: (state, action) => {
    //   console.log(state, action, 'setCarrierApiEndPoints, action');
    //   return action.payload;
    // }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const { setCarrierApiNames, setCarrierApiEndPoints } =
  CarrierApiNames.actions;
