import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  UserDetailsObj: {}
};

export const userSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetailsObj: (state, action) => {
      state.UserDetailsObj = action.payload;
    }
    // setMailRedux: (state, action) => {
    //   state.name = action.payload;
    // },
    // setisLoggedRedux: (state, action) => {
    //   state.name = action.payload;
    // }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const userLoginDetailsSlice = createSlice({
  name: 'userLogin',
  initialState: {
    rememberMe: false,
    password: '',
    email: '',
    token: '',
    agencyLoginID: ''
  },
  reducers: {
    setPasswordRedux: (state, action) => {
      state.password = action.payload;
    },
    setRememberMeRedux: (state, action) => {
      state.rememberMe = action.payload;
    },
    setEmailRedux: (state, action) => {
      state.email = action.payload;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    setLoginAgencyID: (state, action) => {
      state.agencyLoginID = action.payload;
    }
  }
});

// export const userEducation = createSlice({
//   name:"userEducation"
// })

export const { setUserDetailsObj } = userSlice.actions;
export const {
  setPasswordRedux,
  setEmailRedux,
  setRememberMeRedux,
  setUserToken,
  setLoginAgencyID
} = userLoginDetailsSlice.actions;
