import { createSlice } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

export const newAddedPersistedRows = createSlice({
  name: 'newAddedPersistedRows',
  initialState: {
    classRows: []
  },
  reducers: {
    setClassRows: (state, action) => {
      const newItem = action.payload;
      // console.log(state, action, 'state, actions');
      // if (newItem.length > 0) {
      //   state.classRows = [...state, action.payload];
      // } else
      return action.payload;
    }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const { setClassRows } = newAddedPersistedRows.actions;
