import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { AdminUpadateForgotPassword } from '../../apis/Admin';

import { Alert, AlertTitle, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserDetailsObj } from '../../Redux/UserSlice';
import { ErrorToast, successToast } from '../../components/Toasts';
import { InputGroup } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

const UpdatePassword = props => {
  const ForgotPassKey = props?.location?.search.split('&')[1].split('=')[1];
  const LoginEmail = props?.location?.search.split('&')[0].split('=')[1];
  const history = useHistory();
  // console.log(LoginEmail, ForgotPassKey, 'ay ley');
  const selectorData = useSelector(setUserDetailsObj);
  const [data, setData] = useState(selectorData);
  // const selector = useSelector();p
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfrimPassword] = useState();
  const [updatePassConfirmAlert, setUpdatePassConfirmAlert] = useState(false);
  const [validNewPassErr, setValidNewPassErr] = useState(false);
  const [validConfirmPassErr, setValidConfirmPassErr] = useState(false);
  const [passwordDontMatch, setPasswordDontMatch] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
    console.log(showConfirmPassword, 'showConfirmPassword');
  };

  useEffect(() => {
    setData(selectorData);
  }, [selectorData]);
  console.log(data, 'datadata');

  const handleSuccessUpdatePass = apiRes => {
    successToast('Pass Updated Successfully! please Login Now');
    setTimeout(() => {
      history.push('/signin');
    }, 1500);
  };
  const handlePassChange = (e, pass) => {
    // const value = e;
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    const mediumRegex = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
    );
    // console.log(value, 'valuevalue');

    if (pass === 'New Password') {
      setNewPassword(e);
      if (mediumRegex.test(e)) {
        setValidNewPassErr(false);
      } else setValidNewPassErr(true);
      // setNewPassword('');
    }
    if (pass === 'Confirm Password') {
      setConfrimPassword(e);
      if (mediumRegex.test(e)) {
        setValidConfirmPassErr(false);
      } else setValidConfirmPassErr(true);
    }
    // setConfrimPassword('');
  };
  const handleConfirmUpdatePass = e => {
    if (e === 'yes') {
      AdminUpadateForgotPassword(confirmPassword, ForgotPassKey, LoginEmail)
        .then(res => res.status === 200 && handleSuccessUpdatePass(res))
        .catch(err => ErrorToast(err?.message));
    }
    setUpdatePassConfirmAlert(false);
    setNewPassword('');
    setConfrimPassword('');
  };
  const handleUpdatePass = e => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordDontMatch(true);
    } else if (newPassword === confirmPassword) {
      setPasswordDontMatch(false);
      setUpdatePassConfirmAlert(true);
    }
  };
  return (
    <React.Fragment>
      <div className='main-content'>
        <div className='page-content'>
          <MetaTags>
            <title>Reset Password | TMS Shipping</title>
          </MetaTags>
          <section className='bg-auth'>
            <Container>
              <Row className='justify-content-center'>
                <Col xl={6} lg={6}>
                  {updatePassConfirmAlert && (
                    <Alert
                      style={{
                        margin: ' -100 0 10 85',
                        textAlign: 'center',
                        width: '70%'
                      }}
                      severity='info'
                    >
                      <AlertTitle>Update Password Confirmation</AlertTitle>
                      <span style={{ alignContent: 'center' }}>
                        Do you agree to <strong>reset your password?</strong>
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          margin: '10 0 0 0',
                          justifyContent: 'space-evenly'
                        }}
                      >
                        <Button
                          onClick={() => handleConfirmUpdatePass('yes')}
                          color='success'
                          variant='contained'
                          size='small'
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={() => handleConfirmUpdatePass('no')}
                          color='error'
                          variant='contained'
                          size='small'
                        >
                          No
                        </Button>
                      </div>
                    </Alert>
                  )}
                  <Card className='auth-box'>
                    <CardBody className='auth-content p-5 h-100 text-white'>
                      {/* <div className='text-center mb-4'></div> */}

                      <Form className='auth-form text-white'>
                        <h3>Reset new password</h3>

                        <div className='mb-4'>
                          <InputGroup>
                            <Form.Control
                              type={showPassword ? 'text' : 'password'}
                              className='form-control'
                              placeholder='Enter your password'
                              value={newPassword}
                              id='newPassword'
                              onChange={e =>
                                handlePassChange(e.target.value, 'New Password')
                              }
                              required
                              style={{
                                height: 'auto'
                              }}
                            />
                            <InputGroup.Append
                              style={{ cursor: 'pointer' }}
                              onClick={togglePasswordVisibility}
                            >
                              <InputGroup.Text style={{ height: '100%' }}>
                                {showPassword ? (
                                  <EyeFill
                                    style={{
                                      height: '100%'
                                    }}
                                  />
                                ) : (
                                  <EyeSlashFill
                                    style={{
                                      height: '100%'
                                    }}
                                  />
                                )}
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                          {validNewPassErr && (
                            <span style={{ color: 'red', fontSize: 12 }}>
                              Enter a strong password
                            </span>
                          )}
                        </div>
                        <div className='mb-4'>
                          <InputGroup>
                            <Form.Control
                              type={showConfirmPassword ? 'text' : 'password'}
                              className='form-control'
                              placeholder='Enter your password'
                              value={confirmPassword}
                              id='confirmPassword'
                              onChange={e =>
                                handlePassChange(
                                  e.target.value,
                                  'Confirm Password'
                                )
                              }
                              required
                              style={{
                                height: 'auto'
                              }}
                            />
                            <InputGroup.Append
                              style={{ cursor: 'pointer' }}
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              <InputGroup.Text style={{ height: '100%' }}>
                                {showConfirmPassword ? (
                                  <EyeFill
                                    style={{
                                      height: '100%'
                                    }}
                                  />
                                ) : (
                                  <EyeSlashFill
                                    style={{
                                      height: '100%'
                                    }}
                                  />
                                )}
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                          {validConfirmPassErr && (
                            <span style={{ color: 'red', fontSize: 12 }}>
                              Enter a strong password
                            </span>
                          )}
                        </div>
                        <div className='mt-3'>
                          {passwordDontMatch && (
                            <span
                              style={{
                                color: 'red',
                                fontSize: 12,
                                padding: '1 1 1 1'
                              }}
                            >
                              Password did not matches!
                            </span>
                          )}
                          <button
                            disabled={
                              newPassword &&
                              confirmPassword &&
                              !validNewPassErr &&
                              !validConfirmPassErr
                                ? false
                                : true
                            }
                            onClick={e => handleUpdatePass(e)}
                            type='submit'
                            className='btn btn-white w-100'
                          >
                            Set Password
                          </button>
                        </div>
                      </Form>
                      <div className='mt-2 text-center text-white-50'>
                        <p>
                          <Link
                            to='/signin'
                            className='fw-medium text-white text-decoration-underline'
                          >
                            {' '}
                            Go to Login{' '}
                          </Link>
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdatePassword;
