import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { AdminLogin, AdminGetProfile } from '../../apis/Admin';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import {
  setEmailRedux,
  setLoginAgencyID,
  setPasswordRedux,
  setUserToken
} from '../../Redux/UserSlice';
import { useDispatch } from 'react-redux';
import { InputGroup } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { Button, Paper } from '@mui/material';

const LockScreenComponent = () => {
  const dispatch = useDispatch();
  const rememberMeRedux = useSelector(state => state.userDetails.rememberMe);
  const rememberMePassword = useSelector(state => state.userDetails.password);
  const [newPassword, setNewPassword] = useState();
  const userDetails = useSelector(state => state.userDetails);
  const [isLoading, setIsLoading] = useState(false);

  const [userDetailsArr, setUserDetailsArr] = useState([]);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const history = useHistory();
  const GetSuccessUserDetails = apiRes => {
    setUserDetailsArr(apiRes.data);
  };

  const handleLoggedInSuccess = () => {
    successToast('User authenticated successfully.');
    setTimeout(() => {
      history.push('/dashboard');
    }, 1000);
  };
  const successResponse = succRes => {
    if (succRes.statusCode === 200) {
      dispatch(setPasswordRedux(password));
      // dispatch(setEmailRedux(userDetails.email));
      dispatch(setUserToken(succRes.token));
      dispatch(setLoginAgencyID(succRes.agencyId));
      localStorage.setItem('AdminLoginId', succRes.expiry);
      localStorage.setItem('AdminAuthToken', succRes.token);
      setIsLoading(false);
      handleLoggedInSuccess();
    }
  };
  const handleLogin = e => {
    setIsLoading(true);
    e.preventDefault();
    AdminLogin(userDetails.email, password)
      .then(res => res.data.statusCode === 200 && successResponse(res.data))
      .catch(err => {
        console.log('AagencyLoginErr', err);
        setIsLoading(false);
        ErrorToast(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    AdminGetProfile(userDetails.email)
      .then(
        res => res.data.statusCode === 200 && GetSuccessUserDetails(res.data)
      )
      .catch(err => console.log(err, 'GetAagencyUserDetails Err--'));
  }, []);

  useEffect(() => {
    if (rememberMeRedux && !password) {
      setPassword(rememberMePassword);
    }
  }, [rememberMeRedux]);

  const firstLetter = userDetailsArr.firstName
    ? userDetailsArr.firstName.charAt(0).toUpperCase()
    : '';

  const lastLetter = userDetailsArr.lastName
    ? userDetailsArr.lastName.charAt(0).toUpperCase()
    : '';

  const imageStyle = {
    // marginTop: 50,
    // marginLeft: 50,
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333'
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>LockScreen | TMS Shipping</title>
      </MetaTags>
      <ToastContainer
        position='top-center'
        autoClose={4800}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80%'
          // width: '100%'
        }}
      >
        <Paper style={{ borderRadius: 10 }}>
          <Card style={{ borderRadius: 10, padding: '30 30 10 30' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  src={`data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">${encodeURIComponent(
                    firstLetter + lastLetter
                  )}</text></svg>`}
                  // src={imageUrl}
                  alt={firstLetter}
                  style={imageStyle}
                />
                <h5 class='mt-3 mb-0'>
                  Hi!{' '}
                  {userDetailsArr?.firstName &&
                    userDetailsArr?.firstName + ' ' + userDetailsArr?.lastName}
                </h5>
                <small>Enter your password to access the admin.</small>
              </div>
            </div>
            <CardBody>
              <Form style={{ marginTop: 10 }}>
                <Row style={{}}>
                  <Col lg={8}>
                    {/* <Input
                      value={newPassword}
                      type='password'
                      className='form-control'
                      id='newPassword'
                      placeholder='Enter Your Password'
                      onChange={e => setNewPassword(e.target.value)}
                    /> */}
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        className='form-control'
                        id='newPassword'
                        placeholder='Enter your password'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        style={{ height: 'auto' }}
                      />
                      <InputGroup.Append
                        style={{ cursor: 'pointer' }}
                        onClick={togglePasswordVisibility}
                      >
                        <InputGroup.Text style={{ height: '100%' }}>
                          {showPassword ? (
                            <EyeFill style={{ height: '100%' }} />
                          ) : (
                            <EyeSlashFill style={{ height: '100%' }} />
                          )}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                  <Col lg={4} style={{ alignSelf: 'center' }}>
                    <Button
                      type='submit'
                      disabled={password ? false : true}
                      style={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap'
                      }}
                      color='primary'
                      variant='contained'
                      onClick={e => handleLogin(e)}
                      // type='submit'
                    >
                      {isLoading ? 'Loading ...' : 'Login'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default LockScreenComponent;
