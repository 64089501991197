import { createSlice } from '@reduxjs/toolkit';

export const quickQuoteServicesSlice = createSlice({
  name: 'quickQuoteServicesSlice',
  initialState: {
    QuickQuotePickUpCheckedRedux: false,
    QuickQuoteDeliveryCheckedRedux: false
  },
  reducers: {
    setQuickQuotePickUpCheckedRedux: (state, action) => {
      state.QuickQuotePickUpCheckedRedux = action.payload;
    },
    setQuickQuoteDeliveryCheckedRedux: (state, action) => {
      state.QuickQuoteDeliveryCheckedRedux = action.payload;
    }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const {
  setQuickQuotePickUpCheckedRedux,
  setQuickQuoteDeliveryCheckedRedux
} = quickQuoteServicesSlice.actions;
